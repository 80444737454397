import __Base from '@components/bridge/bridge/modal/ModalRoot';
import React from 'react';
import PropTypes from 'prop-types';
class Play extends __Base {
  static defaultPropsFactory = () => null;
  static defaultTitle = () => null;
  static domain = 'video';
  static getDerivedStateFromProps = (nextProps, prevState) => {
    return {
      // mirroring, see https://stackoverflow.com/a/50080417/6503789
      ...(_.get(nextProps, 'video.id') !== prevState._videoId && {
        _videoId: _.get(nextProps, 'video.id') || null
      })
    };
  };
  static loading = () => modal.MODAL_LOADING_ALWAYS;
  static type = 'play';
  constructor(props) {
    super(props);
    this.state = {}; // see getDerivedStateFromProps
  }
  handleLoad = () => modal.beLoading(false);
  render = () => {
    const {
      video
    } = this.props;
    const ratio = Math.max(1, parseFloat(_.get(video, 'ratio') || 1));
    const maxHeight = (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight) * 0.6;
    const maxWidth = (window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth) * 0.7;
    let width;
    let height;

    // responsive
    if (maxHeight * ratio > maxWidth) {
      width = maxWidth;
      height = width / ratio;
    } else {
      height = maxHeight;
      width = height * ratio;
    }
    return <div className='-content' style={{
      width: `${width}px`,
      height: `${height}px`
    }}>
                {modal.open ? <iframe src={`${playerLink(video.shortId)}?loop=1&autoplay=0`} frameBorder='0' height='100%' width='100%' onLoad={this.handleLoad} allowFullScreen webkitallowfullscreen='true' mozallowfullscreen='true' msallowfullscreen='true' allow='vr; gyroscope; accelerometer; xr;' /> : null}
            </div>;
  };
}
Play.propTypes = {
  // required
  video: PropTypes.object.isRequired
};
export default Play;