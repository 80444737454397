import __Base from '@components/Base';
import clsx from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
class ImageFile extends __Base {
  static getDerivedStateFromProps = (nextProps, prevState) => {
    return {
      // mirroring, see https://stackoverflow.com/a/50080417/6503789
      ...(nextProps.src !== prevState._src && {
        _src: nextProps.src
      }),
      // derivation
      ...(nextProps.src !== prevState._src && nextProps.src && {
        loading: true
      })
    };
  };
  constructor(props) {
    super(props);
    this.state = {};
  }
  handleLoad = ev => {
    this.updateState({
      loading: false
    });
  };
  render = () => {
    const {
      backgroundSize,
      customAttributes,
      src
    } = this.props;
    return <div data-attr='root' {...get(customAttributes, 'root', {})} className={clsx('image', get(customAttributes, 'root.className'), this.state.loading && '/loading')} style={{
      ...get(customAttributes, 'root.style', {}),
      ...(this.state.loading ? {} : !src ? {
        backgroundColor: 'lightgrey'
      } : {
        backgroundImage: `url(${cors(src, 'css')})`,
        backgroundSize
      })
    }}>
                {src !== null && <img alt='' ref={obj => this.img = obj} className='hidden' src={cors(src)} onLoad={this.handleLoad} />}
            </div>;
  };
}
ImageFile.defaultProps = {
  backgroundSize: 'cover',
  customAttributes: {},
  src: null
};
ImageFile.propTypes = {
  // required
  // optional
  backgroundSize: PropTypes.oneOf(['contain', 'cover']),
  customAttributes: PropTypes.object,
  src: PropTypes.string
};
export default ImageFile;