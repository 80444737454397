import React, { useEffect } from 'react';
import type { AppProps } from 'next/app';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css';
// eslint-disable-next-line import/no-unresolved
import 'swiper/css/navigation';
import 'simplebar-react/dist/simplebar.min.css';
import 'react-toastify/dist/ReactToastify.min.css';
import '@styles/style.scss';
import Router, { useRouter } from 'next/router';
import NProgress from 'nprogress';
import zenscroll from 'zenscroll';
import '@utils/debug';
import '@utils/cookiesManager';
import '@utils/dom';
import '@utils/fileManagement';
import '@utils/gWatch';
import '@utils/getDefaultLanguage';
import '@utils/getScript';
import '@utils/miscellaneous';
import '@utils/transChoice';
import 'moment/locale/fr';
import useCustomTranslation from '@hooks/useCustomTranslation';
import { SWRConfig } from 'swr';
import ErrorBoundary from '@layout/ErrorBoundary';
import createEmotionCache from '@utils/createEmotionCache';
import { CacheProvider } from '@emotion/react';
import ThemeProvider from '@theme/index';
import { ToastContainer } from 'react-toastify';
import i18n from '../../i18n';
process.on('SIGTERM', () => {
  console.log('Received SIGTERM: ', 'cleaning up');
  process.exit(0);
});
process.on('SIGINT', () => {
  console.log('Received SIGINT: ', 'cleaning up');
  process.exit(0);
});
interface Props {
  Component: AppProps['Component'];
  emotionCache: any;
  pageProps: any;
}

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();
function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache
}: Props) {
  const router = useRouter();
  const {
    tr
  } = useCustomTranslation();
  useEffect(() => {
    i18n.defaultLang = localStorage.getItem('defaultLang') || 'fr';
    window.tr = tr;
    window.zenscroll = zenscroll;
    NProgress.configure({
      easing: 'ease',
      speed: 500,
      showSpinner: false
    });
  }, []);
  useEffect(() => {
    const handleRouteStart = () => {
      NProgress.start();
    };
    const handleRouteDone = () => {
      NProgress.done();
    };
    Router.events.on('routeChangeStart', handleRouteStart);
    Router.events.on('routeChangeComplete', handleRouteDone);
    Router.events.on('routeChangeError', handleRouteDone);
    return () => {
      // Make sure to remove the event handler on unmount!
      Router.events.off('routeChangeStart', handleRouteStart);
      Router.events.off('routeChangeComplete', handleRouteDone);
      Router.events.off('routeChangeError', handleRouteDone);
    };
  }, [router]);
  return <CacheProvider value={emotionCache}>
            <ThemeProvider>
                <SWRConfig value={{
        revalidateOnMount: true,
        revalidateOnFocus: false,
        revalidateIfStale: false,
        // Retry the request when it's throw a error
        shouldRetryOnError: true,
        errorRetryCount: 3
      }}>
                    <ErrorBoundary router={router}>
                        <Component {...pageProps} />
                        <ToastContainer limit={3} style={{
            width: '340px'
          }} />
                    </ErrorBoundary>
                </SWRConfig>
            </ThemeProvider>
        </CacheProvider>;
}
export default MyApp;