function queryWhitelabel() {
    return `{
            api {
                name
            }
            bridgeWhitelabel: whitelabel {
                domain {
                    name
                }
                colorScheme {
                    first
                    second
                }
                metaBag {
                    title
                    description
                    thumbnail {
                        src (absolute: true)
                    }
                }
                logo {
                    src
                }
            }
         }`;
}

const queryWhitleLabelMock = () => 'Whitelabel';

const queryWhitelabelApi = process.env.NEXT_PUBLIC_MOCKS === 'true' ? queryWhitleLabelMock : queryWhitelabel;

export default queryWhitelabelApi;
