import __Base from '@components/Base';
import uniqid from '@utils/lodash/uniqid';
import clsx from 'clsx';
import { throttle, each, get, isFunction, isString } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
class TooltipBox extends __Base {
  constructor(props) {
    super(props);
    this.PRINT = `data-print-${uniqid()}`; // clickOut management system

    this.state = {
      open: false
    };
    this.handleMouseOverThrottle = throttle(this.handleMouseOver, 100);
  }
  componentDidMount = () => {
    this.printOnContent();
    document.addEventListener('mouseover', this.handleMouseOverThrottle);
  };
  componentDidUpdate = () => this.printOnContent();
  componentWillUnmount = () => document.removeEventListener('mouseover', this.handleMouseOverThrottle);
  handleMouseOver = ev => {
    if (ev && ev.target) {
      const open = ev && ev.target && ev.target.hasAttribute && ev.target.hasAttribute(this.PRINT);
      if (this.state.open !== open) {
        this.updateState({
          open
        });
      } // we avoid mouveleave since it is relatively buggy on some browsers
    }
  };
  printOnContent = () => {
    each(this.contentShell.getElementsByTagName('*'), element => element.setAttribute(this.PRINT, ''));
    this.contentShell.setAttribute(this.PRINT, '');
  };
  render = () => {
    const {
      caption,
      customAttributes,
      children,
      style
    } = this.props;
    const {
      shortId = '',
      ...rootCustomAttributesWithoutShortId
    } = customAttributes?.root || {};
    return <div ref={obj => this.root = obj} data-attr='root' {...rootCustomAttributesWithoutShortId} className={clsx('tooltipBox', get(customAttributes, 'root.className'))}>
                <div ref={obj => this.contentShell = obj} className='-shell' data-test-id={`cta${get(customAttributes, 'root.className', '')}-${shortId}`}>
                    {children}
                </div>
                {(probe.desktop && caption && this.state.open) === true && <div className='-tooltip' style={isFunction(style) ? style(this.root) : style}>
                        {isString(caption) ? <div dangerouslySetInnerHTML={{
          __html: caption
        }} /> : caption}
                    </div>}
            </div>;
  };
}
TooltipBox.defaultProps = {
  caption: null,
  customAttributes: {},
  style: {
    left: '100%',
    bottom: '0'
  }
};
TooltipBox.propTypes = {
  // optional
  caption: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.node]),
  customAttributes: PropTypes.object,
  style: PropTypes.oneOfType([PropTypes.object, PropTypes.func])
};
export default TooltipBox;