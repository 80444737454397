import _ from 'lodash';

if (typeof window !== 'undefined') {
    window.transChoice = (ans, count) => {
        if (!ans) return null;

        /* Parsing the answer string to get the intervals and the sentences. */
        const cases = _.map(_.split(ans, '|'), c => {
            c = c.replace(/^{(\d+)}/, '[$1,$1]'); // homogenize: transform {X} into [X,X]
            /* Splitting the string into two parts, the first part is the min and max values, the second part is the rest
        of the string. */
            const betweenMark = `%${_.uniqueId()}%`;
            const exp = _.split(c.replace(/^(([\[\]])(-Inf|\d+),(\d+|Inf)([\[\]]))(\s+)/, `$1${betweenMark}`), betweenMark);
            const minStr = _.get(_.split(exp[0], ','), '0');
            const maxStr = _.get(_.split(exp[0], ','), '1') || minStr;
            return {
                interval: {
                    min: {
                        included: minStr.indexOf('[') !== -1,
                        value: minStr === ']-Inf' ? -Infinity : parseInt(minStr.replace(/\D+/g, ''))
                    },
                    max: {
                        included: maxStr.indexOf(']') !== -1,
                        value: maxStr === 'Inf[' ? Infinity : parseInt(maxStr.replace(/\D+/g, ''))
                    }
                },
                sentence: exp[1]
            };
        });

        for (const c of cases) {
            let countIsInInterval = true;

            // min
            if (c.interval.min.included) {
                if (count < c.interval.min.value) countIsInInterval = false;
            } else if (count <= c.interval.min.value) countIsInInterval = false;
            // max
            if (c.interval.max.included) {
                if (count > c.interval.max.value) countIsInInterval = false;
            } else if (count >= c.interval.max.value) countIsInInterval = false;

            if (countIsInInterval) {
                ans = (c.sentence || '').replace(`{{count}}`, count);
                break;
            }
        }

        return ans;
    };
}
