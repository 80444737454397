const COLORS = ['yellow', 'red'];

export default function SvgIcon(theme) {
    const rootStyle = ownerState => {
        const defaultStyle = {
            boxShadow: 'unset !important'
        };

        const colorStyle = COLORS.map(color => ({
            ...(ownerState.color === color &&
                color === 'yellow' && {
                    color: '#FFAB00'
                }),
            ...(ownerState.color === color &&
                color === 'red' && {
                    color: '#f33c13'
                })
        }));

        return [defaultStyle, ...colorStyle];
    };

    return {
        MuiSvgIcon: {
            styleOverrides: {
                root: ({ ownerState }) => rootStyle(ownerState)
            }
        }
    };
}
