export default function Tooltip(theme) {
    return {
        MuiTooltip: {
            styleOverrides: {
                tooltip: {
                    padding: '0.7rem',
                    overflow: 'hidden'
                }
            }
        }
    };
}
