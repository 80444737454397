import _ from 'lodash';

if (typeof window !== 'undefined') {
    /* It's a function that returns the call stack. */
    window.backtrace = () => new Error().stack.replace(/^Error/, 'Call');

    /* It's a function that logs to the console with a clone of the arguments. */
    window.cl = (...args) => console.log(...args);

    /* It's a function that logs to the console with a clone of the arguments. */
    window.ccl = (...args) => {
        _.each(args, (arg, idx) => {
            if (_.isObject(arg)) args[idx] = _.cloneDeep(arg);
        });
        console.log(...args);
    };

    /* It's a function that logs to the console with a prefix and a count. */
    window.rcl = (prefix = 'rcl') => {
        if (window.rclDebug === undefined) window.rclDebug = { total: 0 };
        if (window.rclDebug[prefix] === undefined) window.rclDebug[prefix] = 0;
        console.log(`${prefix}: ${++window.rclDebug[prefix]} ; total: ${++window.rclDebug.total}`);
    };

    /* It's a function that checks if a function is bound. */
    window.isBound = func => !func.hasOwnProperty('prototype');

    // window.logError = async (data, appuser) => {
    //     const errorKey = `frontendError:${JSON.stringify(data)}`;
    //
    //     if (data?.message?.includes('loopScroll is not defined')) return;
    //     if (data?.message?.includes("Can't find variable: loopScroll")) return;
    //
    //     const impersonated = !!cookiesManager.getCookie(
    //         process.env.NEXT_PUBLIC_APP_ENV === 'prod'
    //             ? 'IMPERSONATION_XSRF_TOKEN'
    //             : `IMPERSONATION_XSRF_TOKEN_${process.env.NEXT_PUBLIC_APP_ENV}`
    //     );
    //
    //     const body = {
    //         route: window.location.href,
    //         page: window.location.pathname,
    //         device: navigator.userAgent.toLowerCase(),
    //         appuserId: appuser?.id,
    //         appuserNames: appuser?.profile?.names?.both,
    //         impersonate: impersonated,
    //         message: data.message,
    //         stack: data.stack,
    //         crash: data.crash === true ? '💥' : false,
    //         commitID: process.env.NEXT_PUBLIC_CONFIG_BUILD_ID
    //     };
    //
    //     if (data.crash) {
    //         if (!window[errorKey]) {
    //             // If this error hasn't been treated yet
    //             window[errorKey] = true;
    //             const baseOptions = {
    //                 method: 'POST',
    //                 url: `${process.env.NEXT_PUBLIC_API_URL}write/report-frontend-bug`,
    //                 data: body,
    //                 timeout: 0, // no timeout
    //                 headers: {
    //                     'Content-Type': 'application/json',
    //                     'Cache-Control': 'no-cache', // avoid browser caching, since some would otherwise use JSON results when user plays with history (combined with nc query parameter, just in case)
    //                     'X-Requested-With': 'XMLHttpRequest',
    //                     'X-Requested-From': window.location.href,
    //                     'X-App-Id': window.location.href.includes('back')
    //                         ? process.env.NEXT_PUBLIC_API_APP_DANIM_BACK_OFFICE_KEY
    //                         : process.env.NEXT_PUBLIC_API_APP_DANIM_FRONT_OFFICE_KEY
    //                 },
    //                 withCredentials: true
    //             };
    //             if (process.env.NEXT_PUBLIC_APP_ENV === 'prod' || process.env.NEXT_PUBLIC_APP_ENV === 'stage') await axios(baseOptions);
    //         }
    //     }
    // };

    window.onerror = async (message, url, line, col, error) => {
        if (!error) return;

        const body = {
            message,
            stack: typeof error.stack !== 'undefined' ? error.stack : null,
            crash: false,
            router: null
        };

        // await logError(body);
    };
}
