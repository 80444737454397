import moment from 'moment/moment';
import parseInterval from '@utils/lodash/parseInterval';
import isInterval from '@utils/lodash/isInterval';
import isString from 'lodash/isString';
import isInteger from 'lodash/isInteger';
import isObject from 'lodash/isObject';

/**
 * @param interval
 * @param startAt May be a moment date object, a string, or a timestamp, or event NULL (means 'now')
 * @returns {moment.Moment}
 */
export default function computeDateFromInterval(interval, startAt = null, stringify = false) {
    let date;
    if (!isInterval(interval)) {
        date = interval;

        if (isString) date = moment(date);
        if (isInteger(date)) date = moment.unix(date);
    } else {
        if (isObject(startAt)) startAt = startAt.utc().format();
        if (isInteger(startAt)) startAt = moment.unix(startAt).utc().format();

        date = moment(startAt || Date.now()).add(parseInterval(interval, 'timestamp'), 'seconds');
    }

    if (date && stringify) date = date.local().locale('fr');

    return date;
}
