import _ from 'lodash';
import { checkRefreshTokenImpersonate } from '@services/http/client';
import BaseUser from './user';

export default class AppUser extends BaseUser {
    howManyNewNotificationsForAllWorkspaces() {
        return (_.get(this, 'profile.newNotifications.edges') || []).length;
    }

    howManyNewNotificationsForWorkspace(workspaceId) {
        if (!workspaceId) return 0;
        let count = 0;
        const notifications = _.get(this, 'profile.newNotifications.edges') || [];
        if (!notifications.length) return 0;
        notifications.forEach(notification => {
            const notificationWorkspaceId = _.get(notification, 'node.workspaceId') || _.get(notification, 'node.workspace.id');
            if (workspaceId === notificationWorkspaceId) count++;
        });
        return count;
    }

    activated(takeImpersonationIntoAccount = true) {
        const isImpersonated = checkRefreshTokenImpersonate();
        return !!(_.get(this, 'account.activated') || (takeImpersonationIntoAccount && isImpersonated));
    }

    canInstallNewWorkspace() {
        return !!(
            _.get(this, 'organization.plan.traits.maxWorkspacesCount.value') === null ||
            _.get(this, 'organization.workspaceIds.length') < _.get(this, 'organization.plan.traits.maxWorkspacesCount.value')
        );
    }

    // eslint-disable-next-line class-methods-use-this
    isImpersonated() {
        return checkRefreshTokenImpersonate();
    }

    adjustedStorageLimit() {
        let ans = _.get(this, 'organization.plan.traits.storageLimit.hasBeenChanged.only')
            ? _.get(this, 'organization.plan.adjustedStorageLimit')
            : _.get(this, 'profile.adjustedStorageLimit');

        return ans !== null ? parseFloat(ans) : null;
    }

    usedStorage() {
        return parseFloat(
            _.get(this, 'organization.plan.traits.storageLimit.hasBeenChanged.only')
                ? _.get(this, 'organization.plan.usedStorage')
                : _.get(this, 'profile.usedStorage')
        );
    }

    canRegisterOrganizationMember() {
        const isExternalBilling = !!_.get(this, 'organization.plan.externalBilling');
        const maxLicensesInOrganization = isExternalBilling
            ? _.get(this, 'organization.plan.traits.maxLicensedWithPeriodicCloudyMembersCount.value') || 0
            : _.get(this, 'organization.plan.deals.0.licensePackSize') || 0;
        const totalMembersCount = _.get(this, 'organization.members.totalCount') || 0;

        return isExternalBilling ? totalMembersCount < maxLicensesInOrganization : totalMembersCount < maxLicensesInOrganization;
    }

    canRegisterWorkspaceMember(workspaceId) {
        const isExternalBilling = !!_.get(this, 'organization.plan.externalBilling');
        const maxMembersPerWorkspace = _.get(this, 'organization.plan.traits.maxMembersPerWorkspaceCount.value') || Infinity;

        const totalWorkspaceMembersCount =
            _.get(this, `organization.workspaces.edges`)?.find(wp => wp?.node?.id === workspaceId)?.node?.badgedUserIds.length || 0;

        return this.canRegisterOrganizationMember() && (isExternalBilling ? totalWorkspaceMembersCount < maxMembersPerWorkspace : true);
    }
}
