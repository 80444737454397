import __Base from '@components/Base';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import React from 'react';
import { concat, differenceBy, each, filter, find, forOwn, get, includes, indexOf, isFunction, last, map, merge } from 'lodash';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBell, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { Collapse, Dropdown, DropdownMenu, DropdownToggle, Nav, Navbar } from 'reactstrap';
import NotificationWaiter from '@components/office/front-office/user/NotificationWaiter';
import Notification from '@components/office/front-office/user/Notification';
import Link from 'next/link';
import { apiRequest, axios, logout } from '@services/http/client';
import getBackOfficeMenuConfig from '@components/bridge/bridge/menu/BackOfficeMenuConfig';
import getFrontOfficeMenuConfig from '@components/bridge/bridge/menu/FrontOfficeMenuConfig';
import WorkspaceSelector from '@components/bridge/bridge/menu/WorkspaceSelector';
import HelperCenterButton from '@components/bridge/bridge/menu/HelperCenterButton';
import Workspace from '@utils/Workspace';
import BetaTag from '../BetaTag';
import TooltipBox from '../TooltipBox';
import Logo from '../Logo';
class Menu extends __Base {
  backOfficeTable = getBackOfficeMenuConfig();
  frontOfficeTable = () => {
    const {
      appuser,
      workspace,
      isIframe
    } = this.props;
    return getFrontOfficeMenuConfig(isIframe, appuser, workspace);
  };
  constructor(props) {
    super(props);
    this.automaticallySelectWorkspaceIfUserOnlyHasOne();
    this.NOTIFICATIONS_BUNCH_SIZE = 4;
    this.channel = window.BroadcastChannel ? new BroadcastChannel('Notifications') : null;
    this.state = {
      workspace: props.workspace,
      notifications: {
        edges: [],
        loading: false
      },
      dropdown: false,
      page: null
    };
  }
  automaticallySelectWorkspaceIfUserOnlyHasOne = () => {
    const {
      appuser
    } = this.props;
    if (appuser.workspaceIds?.length === 1) {
      Workspace.setSelectedWorkspaceId(appuser.workspaceIds[0]);
    }
  };
  componentDidMount = () => {
    window.menu = this;
    if (document.getElementById('notifications')) document.getElementById('notifications').addEventListener('scroll', this.considerNewNotificationsScrolling);
  };
  componentDidUpdate = prevProps => {
    if (get(this.props, 'workspace.id') !== get(prevProps, 'workspace.id')) {
      this.updateState(draft => {
        draft.notifications.edges = [];
      });
    }
  };
  componentWillUnmount = () => {
    if (document.getElementById('notifications')) document.getElementById('notifications').removeEventListener('scroll', this.considerNewNotificationsScrolling);
  };
  considerNewNotificationsScrolling = () => {
    const newNotificationsCount = this.newNotificationsCount();
    const remainingNotificationsCount = newNotificationsCount || (this.props.workspace ? this.props.appuser.profile.workspaceNotifications.count || 0 : this.props.appuser.profile.notifications.count - this.state.notifications.edges.length) || 0;
    const hasNextPage = remainingNotificationsCount > 0;
    const box = document.getElementById('notifications');
    if (this.state.notifications.edges.length && !this.state.notifications.loading && (hasNextPage || !this.state.notifications.edges.length) && box.offsetHeight + box.scrollTop >= box.scrollHeight - 160 // bottom of box is reached
    ) this.loadNotifications();
  };
  handleEvent = ev => {
    updateRoot(draft => {
      // noinspection FallThroughInSwitchStatementJS
      switch (ev.detail.name) {
        case 'event.user.notifications_were_removed_from_user':
          draft.appuser = draft.appuser.decrement('profile.notifications.count');
          if (this.props.workspace) draft.appuser = draft.appuser.decrement('profile.workspaceNotifications.count');
        // no break
        case 'event.user.user_notifications_were_marked_as_being_read':
          const {
            notificationIds
          } = ev.detail.payload;
          draft.appuser = draft.appuser.set('profile.newNotifications.edges', includes([null, 'ALL'], notificationIds) ? [] : filter(get(draft.appuser, 'profile.newNotifications.edges') || [], edge => !includes(notificationIds, get(edge, 'node.id'))));
          draft.appuser = draft.appuser.set('profile.newNotifications.count', (get(draft.appuser, 'profile.newNotifications.edges') || []).length);
          break;
        case 'event.user.user_was_notified':
          const {
            id
          } = ev.detail.payload.notification;
          const {
            workspaceId
          } = ev.detail.payload.notification;
          const _workspace = ev.detail.payload.notification.workspace;
          if (draft.appuser) {
            draft.appuser = draft.appuser.increment('profile.notifications.count');
            if (this.props.workspace) draft.appuser = draft.appuser.increment('profile.workspaceNotifications.count');
            if (!find(get(draft.appuser, 'profile.newNotifications.edges') || [], edge => get(edge, 'node.id') === id)) {
              draft.appuser = draft.appuser.push('profile.newNotifications.edges', {
                node: {
                  id,
                  workspaceId,
                  workspace: _workspace
                }
              });
              draft.appuser = draft.appuser.increment('profile.newNotifications.count');
            }
          }
          break;
      }
    });
    this.updateState(draft => {
      switch (ev.detail.name) {
        case 'event.user.user_notifications_were_marked_as_being_read':
          const now = moment.utc().format(); // incorrect but will do
          const {
            notificationIds
          } = ev.detail.payload;
          draft.notifications.edges = map(draft.notifications.edges, edge => {
            if (includes([null, 'ALL'], notificationIds) || includes(notificationIds, get(edge, 'node.id'))) {
              edge.node.readAt = now;
              edge.node.new = false;
            }
            return edge;
          });
          break;
        case 'event.user.notifications_were_removed_from_user':
          const ids = ev.detail.payload.notificationIds;
          draft.notifications.edges = filter(draft.notifications.edges, edge => !includes(ids, get(edge, 'node.id')));
          break;
      }
    });
    switch (ev.detail.name) {
      case 'event.user.user_was_notified':
        this.updateState(draft => {
          const {
            payload
          } = ev.detail;
          const {
            workspace
          } = payload;
          const {
            id
          } = payload.notification;
          const {
            subject
          } = payload.notification;
          const {
            data
          } = payload.notification;
          const workspaceId = payload.notification.workspaceId || null;
          const contextWorkspaceId = get(workspace, 'id') || null;

          // add it only if there already is a loaded list, or if dropdown is open
          if ((draft.notifications.edges.length || draft.dropdown === 'notifications') && !find(draft.notifications.edges, edge => get(edge, 'node.id') === id) && (!contextWorkspaceId || contextWorkspaceId === workspaceId)) draft.notifications.edges = concat([{
            node: {
              id,
              createdAt: moment.utc().format(),
              workspaceId,
              new: true,
              subject,
              data,
              type: constants.user.notification.subject.list[subject].type,
              workspace
            },
            cursor: payload.notification.cursor
          }], draft.notifications.edges);
        }, () => {
          if (this.state.dropdown === 'notifications') this.markNotificationsAsBeingRead();
        });
        break;
    }
  };
  handleNotificationsToggle = ev => {
    this.updateState(draft => {
      draft.page = draft.page ? null : 'notifications';
      draft.dropdown = draft.dropdown === 'notifications' ? false : 'notifications';
    }, () => {
      if (this.markNotificationsAsBeingRead() || !this.state.notifications?.edges?.length) this.loadNotifications();
    });
  };
  handleHelpCenterToggle = () => {
    this.updateState(draft => {
      draft.page = draft.page ? null : 'help';
      draft.dropdown = draft.dropdown === 'help' ? false : 'help';
    });
  };
  handleHelpCenterProductTourClick = ev => {
    if (ev) ev.preventDefault() & ev.stopPropagation();
    const {
      appuser,
      router
    } = this.props;
    if (window.Intercom) {
      if (!appuser.isUnderTrial() && !appuser.isUnderExternalBilling() && !get(appuser, 'account.license')) {
        // Redirect to home if no license, modal for licenses will appear.
        router.push('/').catch(console.error);
      } else {
        // Start tour on homepage, specific to unique ID.
        router.push('/').then(() => Intercom('startTour', 388582));
      }
    } else {
      addFlash({
        message: tr('front.menu.flash.cookies_required.message'),
        goText: tr('front.menu.flash.cookies_required.go_text'),
        goCallback: () => {
          if (window._hsp) _hsp.push(['showBanner']);else console.log('Emulation: cookies opened...');
        }
      });
    }
  };
  handleWorkspacesToggle = () => {
    this.updateState(draft => {
      draft.page = draft.page ? null : 'workspaces';
      draft.dropdown = draft.dropdown === 'workspaces' ? false : 'workspaces';
    });
  };

  /**
   * Includes marking them as being read afterwards
   */
  loadNotifications = () => {
    if (this.loadingNotifications) return; // avoid duplicate loading
    if (this.props.workspace && get(this.props.appuser, 'profile.workspaceNotifications.count') === 0 || !this.props.workspace && get(this.props.appuser, 'profile.notifications.count') === 0) return;
    this.loadingNotifications = true;
    this.updateState(draft => {
      draft.notifications.loading = true;
    }, async () => {
      const query = `
                {
                    user (id: "${this.props.appuser.id}") {
                        id
                        profile {
                            notifications (
                                ${this.props.workspace ? `inWorkspace: "${this.props.workspace.id}"` : ''} 
                                sort: "createdAt:desc" 
                                first: ${Math.min(get(this.props.appuser.profile[this.props.workspace ? 'workspaceNotifications' : 'notifications'], 'count', 0), Math.max(this.NOTIFICATIONS_BUNCH_SIZE, this.newNotificationsCount() || 0))} 
                                ${this.state.notifications.edges.length ? `after: "${last(this.state.notifications.edges).cursor}"` : ''}
                            ) {
                                edges {
                                    node {
                                        id
                                        subject
                                        data
                                        readAt
                                        createdAt
                                        type
                                        new
                                        workspace {
                                            id
                                            name
                                        }
                                    }
                                    cursor
                                }
                            }
                        }
                    }
                }
            `;
      await apiRequest(query).then(data => {
        const {
          user
        } = data.data;
        this.updateState(draft => {
          draft.notifications.edges = concat(draft.notifications.edges, differenceBy(get(user, 'profile.notifications.edges') || [], draft.notifications.edges, edge => get(edge, 'node.id')));
          draft.notifications.loading = false;
        });
      }).catch(() => {
        this.updateState(draft => {
          draft.notifications.loading = false;
        });
        addFlash({
          type: 'warning',
          message: tr('front.menu.ajax.load_notifications.error')
        });
      }).finally(() => {
        this.loadingNotifications = false;
        this.markNotificationsAsBeingRead();
      });
    });
  };

  /**
   * Returns true if some notifications has been just read
   */
  markNotificationsAsBeingRead = async () => {
    const {
      appuser
    } = this.props;
    const now = moment.utc().format(); // incorrect but will do

    const notificationIds = [];
    each(this.state.notifications.edges, edge => {
      if (edge.node.new) notificationIds.push(get(edge, 'node.id'));
    });
    if (notificationIds.length) {
      const options = {
        data: {
          workspaceId: get(this.props.workspace, 'id') || 'ANY'
        },
        headers: {
          'X-Async': true
        }
      };
      await axios(merge(options, {
        url: !window.origin.includes('danim.com') ? `${window.origin}/api/write/me/mark-notifications-as-being-read` : `${process.env.NEXT_PUBLIC_API_URL}write/me/mark-notifications-as-being-read`,
        method: 'post'
      }), {
        then: () => {
          this.updateState(draft => {
            draft.notifications.edges = map(draft.notifications.edges, edge => {
              if (edge.node.new) {
                edge.node.readAt = now;
                edge.node.new = false;
              }
              return edge;
            });
          });
        }
      });
      updateRoot(draft => {
        draft.appuser = draft.appuser.set('profile.newNotifications.edges', []);
        draft.appuser = draft.appuser.set('profile.newNotifications.count', 0);
      });
      return true;
    }
    return false;
  };

  /**
   * @param exceptMode (if true, this will give the number of new notifications except context workspace ones)
   * @returns {*}
   */
  newNotificationsCount = (exceptMode = false) => this.props.workspace ? exceptMode ? (get(this.props.appuser, 'profile.newNotifications.edges') || []).length - this.props.appuser.howManyNewNotificationsForWorkspace(this.props.workspace.id) : this.props.appuser.howManyNewNotificationsForWorkspace(this.props.workspace.id) : (get(this.props.appuser, 'profile.newNotifications.edges') || []).length;
  switchToPage = key => {
    this.updateState(draft => {
      draft.page = key;
    });
  };
  render = () => {
    const {
      onToggle,
      open,
      appuser,
      whitelabel,
      workspace,
      router,
      isIframe
    } = this.props;
    if (indexOf(['/connect', '/connect/activate', '/connect/password/request', '/connect/password/reset'], router.route) !== -1) return null;
    const menuItemTooltipPositionStyle = {
      right: '0',
      top: 'calc(100% + 1rem)'
    };
    const submenuItemTooltipPositionStyle = {
      left: 'calc(100% + 1rem)',
      top: '50%',
      transform: 'translateY(-50%)'
    };
    const newNotificationsCount = appuser ? this.newNotificationsCount() : null;
    const isWorkspaceSelectorVisible = () => {
      return !router.route.includes('/back') && !router.route.includes('/try') && appuser?.activated() && get(appuser, 'organization.workspaces.edges.length') > 1 && (appuser.isOrganizationDirector() || get(appuser, 'workspaces.edges.length') > 1) && (!probe.mobile || includes([false, 'workspaces'], this.state.dropdown)) === true;
    };
    const isHelperCenterVisible = () => {
      return !router.route.includes('/back') && !router.route.includes('/try') && appuser && !isIframe && (!probe.mobile || includes([false, 'help'], this.state.dropdown)) === true;
    };
    const isNotificationCenterVisible = () => {
      return !router.route.includes('/back') && !router.route.includes('/try') && appuser && !isIframe && (!probe.mobile || includes([false, 'notifications'], this.state.dropdown)) === true;
    };
    return <Navbar className={clsx('menu', open && '/open', `/office/`, `/office/${router.route.includes('/back') ? 'backOffice' : 'frontOffice'}`, !!get(appuser, '_impersonator') && '/impersonated', this.state.page && `/page/${this.state.page}`)} data-test-id={`menu-${router.route.includes('/back') ? 'backOffice' : 'frontOffice'}`} expand='md'>
                <Link href='/'>
                    <a className='-logo'>
                        <Logo whitelabel={whitelabel} router={router} appuser={appuser} />
                    </a>
                </Link>
                {(appuser && open && this.state.page !== null) === true && <div className='-back' onClick={() => this.switchToPage(null)}>
                        <div />
                        <div />
                        <div />
                    </div>}
                {!!appuser && <div className='-toggler' onClick={onToggle}>
                        <div />
                        <div />
                        <div />
                    </div>}
                <Collapse isOpen={open} navbar>
                    <Nav className='ml-auto' navbar>
                        {isWorkspaceSelectorVisible() && <WorkspaceSelector appuser={appuser} workspace={workspace} isOpen={this.state.dropdown === 'workspaces'} handleWorkspacesToggle={this.handleWorkspacesToggle} newNotificationsCount={this.newNotificationsCount} />}
                        {router.route.includes('/back') && !router.route.includes('/try') && Object.keys(this.backOfficeTable).map(key => {
            const item = this.backOfficeTable[key];
            return <React.Fragment key={key}>
                                        <TooltipBox caption={item.caption} style={menuItemTooltipPositionStyle} customAttributes={{
                root: {
                  className: clsx('-item /level1', item.className)
                }
              }}>
                                            <Link href={item.route}>
                                                <a className={clsx('-inner', router.route === item.route && '/active')}>
                                                    <FontAwesomeIcon icon={item.icon} width={16} />
                                                    <span className='-caption' dangerouslySetInnerHTML={{
                      __html: item.caption
                    }} />
                                                </a>
                                            </Link>
                                        </TooltipBox>
                                    </React.Fragment>;
          })}
                        {(appuser?.activated() && !router.route.includes('/back') && !router.route.includes('/try')) === true && Object.keys(this.frontOfficeTable()).map(key => {
            const item = this.frontOfficeTable()[key];
            if (!item.if()) return null;
            let oneActiveChild = false;
            forOwn(item.children || {}, child => {
              // Petit hack à cause de la redirection depuis le RS Facebook
              if (router.asPath.replace('#_=_', '').split('?')[0] === child.route) oneActiveChild = true;
            });
            const icon = isFunction(item.icon) ? item.icon() : <FontAwesomeIcon icon={item.icon} width={16} />;
            const candidatesAmongChildren = filter(Object.values(get(item, 'children') || {}), c => c.if());
            const route = get(item, 'route') || get(candidatesAmongChildren, '0.route') || null;
            return <div data-test-id={key} key={key} className={clsx('-item /level1', isFunction(item.className) ? item.className() : item.className, (oneActiveChild || !item.children && router.asPath === route) && '/active')}>
                                        <TooltipBox customAttributes={{
                root: {
                  className: '-inner'
                }
              }} style={menuItemTooltipPositionStyle} caption={item.caption}>
                                            {!probe.mobile || !candidatesAmongChildren.length ? <Link href={item.link || route}>
                                                    <a onClick={!probe.mobile || !candidatesAmongChildren.length ? probe.mobile ? () => onToggle() : null : () => this.switchToPage(key)}>
                                                        {icon}
                                                        {!!item.beta && <BetaTag />}
                                                        <span className='-caption' dangerouslySetInnerHTML={{
                      __html: item.caption
                    }} />
                                                    </a>
                                                </Link> : <a onClick={!probe.mobile || !candidatesAmongChildren.length ? probe.mobile ? () => onToggle() : null : () => this.switchToPage(key)}>
                                                    {icon}
                                                    {!!item.beta && <BetaTag />}
                                                    <span className='-caption' dangerouslySetInnerHTML={{
                    __html: item.caption
                  }} />
                                                </a>}
                                        </TooltipBox>

                                        {(this.state.page === key || !probe.mobile && candidatesAmongChildren.length > 1 && oneActiveChild) === true && <div className='-submenu'>
                                                {Object.keys(get(item, 'children') || {}).map(childKey => {
                  const child = item.children[childKey];
                  const childIcon = isFunction(child.icon) ? child.icon() : <FontAwesomeIcon icon={child.icon} width={16} />;
                  const childRoute = get(child, 'route') || null;
                  if (!child.if()) return null;
                  return <TooltipBox key={`${key}/${childKey}`} caption={child.caption} style={submenuItemTooltipPositionStyle} customAttributes={{
                    root: {
                      className: clsx('-item /level2', isFunction(child.className) ? child.className() : child.className, router.route === childRoute && '/active')
                    }
                  }}>
                                                            <Link href={child.link || childRoute}>
                                                                <a className='-inner' onClick={!probe.mobile || !candidatesAmongChildren.length ? () => null : () => onToggle()}>
                                                                    {childIcon}
                                                                    {!!child.beta && <BetaTag />}
                                                                    <span>{child.caption}</span>
                                                                </a>
                                                            </Link>
                                                        </TooltipBox>;
                })}
                                            </div>}
                                    </div>;
          })}
                        {isNotificationCenterVisible() && <Dropdown nav inNavbar isOpen={this.state.dropdown === 'notifications'} toggle={this.handleNotificationsToggle}>
                                <DropdownToggle className={clsx('-item', '/level1', '/notifications', newNotificationsCount && '/dring')}>
                                    <TooltipBox caption={tr('front.menu.items.office.front_office.user.notifications.caption')} style={menuItemTooltipPositionStyle}>
                                        <span className='-inner'>
                                            <FontAwesomeIcon icon={faBell} width={16} />
                                            <span className='-caption'>
                                                {tr('front.menu.items.office.front_office.user.notifications.caption')}
                                            </span>
                                            {newNotificationsCount > 0 && <span className='-counter'>{newNotificationsCount}</span>}
                                        </span>
                                    </TooltipBox>
                                </DropdownToggle>
                                <DropdownMenu id='notifications' right className='-menu'>
                                    {this.state.notifications?.edges?.map(edge => <Notification key={get(edge, 'node.id')} notification={edge.node} />)}
                                    {this.state.notifications.loading === true && [...Array(Math.min((appuser.profile?.[workspace ? 'workspaceNotifications' : 'notifications']?.count || 0) - this.state.notifications.edges.length, Math.max(this.NOTIFICATIONS_BUNCH_SIZE, newNotificationsCount))).keys()]?.map(n => <NotificationWaiter key={n} />)}
                                    {this.state.notifications?.edges?.length === 0 && this.state.notifications.loading === false && <div className='-noItems'>
                                            {tr('front.menu.items.office.front_office.user.notifications.no_items')}
                                        </div>}
                                </DropdownMenu>
                            </Dropdown>}
                        {isHelperCenterVisible() && <HelperCenterButton isOpen={this.state.dropdown === 'help'} handleHelpCenterToggle={this.handleHelpCenterToggle} handleHelpCenterProductTourClick={this.handleHelpCenterProductTourClick} appuser={appuser} />}
                        {(appuser && !router.route.includes('/try')) === true && <TooltipBox customAttributes={{
            root: {
              className: '-item /level1 /logout'
            }
          }} style={menuItemTooltipPositionStyle} caption={tr('front.menu.items.office.connect.user.logout')}>
                                <Link href='/'>
                                    <a className='-inner' onClick={() => logout()}>
                                        <FontAwesomeIcon icon={faPowerOff} width={16} />
                                        <span>{tr('front.menu.items.office.connect.user.logout')}</span>
                                    </a>
                                </Link>
                            </TooltipBox>}
                    </Nav>
                </Collapse>
            </Navbar>;
  };
}
Menu.defaultProps = {
  open: false,
  onToggle: () => null,
  workspace: null
};
Menu.propTypes = {
  // optional
  open: PropTypes.bool,
  onToggle: PropTypes.func,
  workspace: PropTypes.object
};
export default Menu;