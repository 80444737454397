function queryAppUser(id: string, workspaceId?: string) {
    return id
        ? `query queryAppUser {
            bridgeUser:user (id: "${id}") {
                    id
                    registeredAt
                    registererId
                    organizationId
                    organization {
                        id
                        motherId
                        libraryIds
                        sponsorId
                        currency
                        name
                        advancedMode
                        company
                        explodeAt
                        memberIds
                        taxable
                        hasKnownAtLeastOneDoneVideo
                        botIds
                        thirdCustomerId
                        currentlyHasAtLeastOneOpenInvoice
                        currentlyHasAtLeastOneProcessingPaymentIntent
                        locale
                        bots {
                            edges { 
                                node { 
                                    id
                                    hosted
                                    authKey
                                    materials { serverId }
                                } 
                            } 
                        }
                        licenses {
                            id
                            cloudy
                            periodic
                        }
                        directors (first: 0) { totalCount }
                        members (first: 0) { totalCount }
                        videoIds
                        whitelabel {
                            logo {
                                src
                            }
                            name
                            domain {
                                name
                                securityCert
                                securityKey
                            }
                            colorScheme {
                                first
                                second
                            }
                            metaBag {
                                title
                                description
                                thumbnail {
                                    src (absolute: true)
                                }
                            }
                        }
                        workspaceIds
                        workspaces (first: -1 sort: "name") { 
                            totalCount
                            edges { 
                                node {
                                    id
                                    name 
                                    logo { src } 
                                    badgedUserIds
                                    generationVariables { 
                                        signature
                                        location
                                        logo { 
                                            src
                                        }
                                        background {
                                            src
                                        }
                                        cover { 
                                            src
                                        }
                                        smallLogo { 
                                            src
                                        }
                                        baselineLogo { 
                                            src
                                        }
                                        whiteLogo { 
                                            src
                                        }
                                        whiteSmallLogo {
                                            src
                                        }
                                        whiteBaselineLogo {
                                            src
                                        }
                                        firstFont {
                                            family
                                            style
                                            postscriptName
                                            file {
                                                id
                                                createdAt
                                                src
                                                originalName
                                                mimeType
                                                size
                                                meta
                                            }
                                        }
                                        secondFont {
                                            family
                                            style
                                            postscriptName
                                            file {
                                                id
                                                createdAt
                                                src
                                                originalName
                                                mimeType
                                                size
                                                meta
                                            }
                                        }
                                        thirdFont {
                                            family
                                            style
                                            postscriptName
                                            file {
                                                id
                                                createdAt
                                                src
                                                originalName
                                                mimeType
                                                size
                                                meta
                                            }
                                        }
                                        fancyFont {
                                            family
                                            style
                                            postscriptName
                                            file {
                                                id
                                                createdAt
                                                src
                                                originalName
                                                mimeType
                                                size
                                                meta
                                            }
                                        }
                                        mainColor
                                        secondaryColor
                                        extraColor
                                        mainGradientColor
                                        secondaryGradientColor
                                        readableExtraColor
                                        canMainColorBeDerivated
                                        lightColor
                                        darkColor
                                        shapesRound
                                        textBoxStrokeWidth
                                        mediaBoxStrokeWidth
                                        shapesOpacity
                                        shouldThereBeDropShadows
                                        logoScale
                                        smallLogoScale
                                        smallLogoPlacement
                                        smallLogoXPosition
                                        smallLogoYPosition
                                        shouldDesignBeSimplified
                                    }
                                } 
                            }
                        }
                        sponsorCode
                        sponsoredOrganizationsHavingPaidAtLeastOnceInThePast (first: -1) {
                            totalCount
                            edges {
                                node {
                                    referent {
                                        email
                                    }
                                }
                            }
                        }
                        plan {
                            adjustedStorageLimit
                            credits
                            externalBilling
                            gaugeCapacity
                            usedStorage
                            usedPeriodicCloudyLicensesCount
                            offeredMonthLicensePacks {
                                forFree
                                thanksToSponsorship
                            }
                            deals  {
                                coupon {
                                    amount
                                    percentage
                                }
                                licensePackSize
                                monthsCount
                                monthlyPaid
                                thirdProductId
                                thirdPriceId
                                thirdSubscriptionId
                                thirdSubscriptionStartAt
                                thirdSubscriptionEndAt
                                thirdSubscriptionBillingPeriodEndAt
                                thirdSubscriptionLastSuccessfulPaymentAt
                                pause {
                                    startAt
                                    monthsCount 
                                    endAt
                                    nextThirdInvoiceAt
                                }
                            }
                            traits {
                                havePartiallyBeenChanged {
                                    only
                                    bySomeone
                                    byBackOfficeAdmin
                                }
                                storageLimit {
                                    value
                                    hasBeenChanged {
                                        only
                                        bySomeone
                                        byBackOfficeAdmin
                                    }
                                }
                                maxLicensedWithPeriodicCloudyMembersCount { value, dealPeak }
                                maxLicensedWithPeriodicLocalMembersCount { value, dealPeak }
                                maxLicensedWithLifetimeLocalMembersCount { value, dealPeak }
                                maxMembersPerWorkspaceCount { value }
                                maxWorkspacesCount { value }
                                generationDailyRateLimit { value }
                                playerVideosOpenToPublic { value }
                                allowedApiAppVideoGenerations { value }
                                visibleCreditsGauge { value }
                            }
                            trialPeriod {
                                startAt
                                endAt
                            }
                        }
                        referent {
                            id
                            email
                            names {
                                firstname
                                lastname
                            }
                            phone
                        }
                        location {
                            address
                            details
                            country
                        }
                        taxNumber {
                            subType
                            value
                        }
                    }
                    workspaceIds
                    workspaces (first: -1 sort: "name") { 
                        totalCount
                        edges { 
                            node { 
                                id
                                name 
                                logo { src }
                                generationVariables { 
                                    signature
                                    location
                                    logo { 
                                        src
                                    }
                                    cover { 
                                        src
                                    }
                                    background {
                                        src
                                    }
                                    smallLogo { 
                                        src
                                    }
                                    baselineLogo { 
                                        src
                                    }
                                    whiteLogo { 
                                        src
                                    }
                                    whiteSmallLogo {
                                        src
                                    }
                                    whiteBaselineLogo {
                                        src
                                    }
                                    firstFont {
                                        family
                                        style
                                        postscriptName
                                        file {
                                            id
                                            createdAt
                                            src
                                            originalName
                                            mimeType
                                            size
                                            meta
                                        }
                                    }
                                    secondFont {
                                        family
                                        style
                                        postscriptName
                                        file {
                                            id
                                            createdAt
                                            src
                                            originalName
                                            mimeType
                                            size
                                            meta
                                        }
                                    }
                                    thirdFont {
                                        family
                                        style
                                        postscriptName
                                        file {
                                            id
                                            createdAt
                                            src
                                            originalName
                                            mimeType
                                            size
                                            meta
                                        }
                                    }
                                    fancyFont {
                                        family
                                        style
                                        postscriptName
                                        file {
                                            id
                                            createdAt
                                            src
                                            originalName
                                            mimeType
                                            size
                                            meta
                                        }
                                    }
                                    mainColor
                                    secondaryColor
                                    extraColor
                                    mainGradientColor
                                    secondaryGradientColor
                                    readableExtraColor
                                    canMainColorBeDerivated
                                    lightColor
                                    darkColor
                                    shapesRound
                                    textBoxStrokeWidth
                                    mediaBoxStrokeWidth
                                    shapesOpacity
                                    shouldThereBeDropShadows
                                    logoScale
                                    smallLogoScale
                                    smallLogoPlacement
                                    smallLogoXPosition
                                    smallLogoYPosition
                                    shouldDesignBeSimplified
                                }
                            } 
                        } 
                    }
                    account {
                        chatIdHash
                        email
                        phone
                        roles
                        locale
                        activated
                        lastActivityAt
                        lastLoginAt
                        lastTermsAgreementAt
                        lastActivationPoke {
                            authorId
                            at
                        }
                        locked {
                            authorId
                            at
                        }
                        scopes
                        license {
                            id
                            cloudy
                            periodic
                        }
                    }
                    billing {
                        location {
                            address
                        }
                        taxNumber {
                            subType
                            value
                        }
                    }
                    profile {
                        adjustedStorageLimit
                        usedStorage
                        portrait {
                            src (absolute: false)
                        }
                        names {
                            firstname
                            lastname
                            both
                        }
                        organizationBadges {
                            workspaceId
                            workspace {
                                id
                                name
                                badgedUserIds
                            }
                            badge
                        }
                        fontStore (case: SNAKE)
                        newNotifications: notifications (sort: "createdAt:desc" new: true first: -1) {
                            count
                            edges {
                                node {
                                    id
                                    workspaceId
                                }
                            }
                        }
                        ${workspaceId ? `workspaceNotifications: notifications (inWorkspace: "${workspaceId}") {count}` : ''}
                        notifications {
                            count
                        }
                    }
                    favouriteThirdMusicIds
                    settings {
                        hasAtLeastOneInterest
                        interests
                    }
                    canI {
                        getUiModuleTo {
                            generateVideoViaAiCompletion
                            startHomeStagingImageGeneration
                            startImageToVideoGeneration { 
                                itself
                                detectMyIntentToProceed 
                                _remainingGenerationsForThisMonth
                                _generationsLimitPerMonth
                            }                
                        }
                    }
                }
                bridgeApiApp:apiApp (id: "${id}") {
                    id
                    organizationId
                    organization {
                        id
                        motherId
                        libraryIds
                        sponsorId
                        currency
                        name
                        advancedMode
                        company
                        explodeAt
                        memberIds
                        taxable
                        hasKnownAtLeastOneDoneVideo
                        botIds
                        thirdCustomerId
                        currentlyHasAtLeastOneOpenInvoice
                        currentlyHasAtLeastOneProcessingPaymentIntent
                        bots {
                            edges { 
                                node { 
                                    id
                                    hosted
                                    authKey
                                    materials { serverId }
                                } 
                            } 
                        }
                        licenses {
                            id
                            cloudy
                            periodic
                        }
                        directors (first: 0) { totalCount }
                        members (first: 0) { totalCount }
                        videoIds
                        whitelabel {
                            logo {
                                src
                            }
                            name
                            domain {
                                name
                                securityCert
                                securityKey
                            }
                            colorScheme {
                                first
                                second
                            }
                            metaBag {
                                title
                                description
                                thumbnail {
                                    src (absolute: true)
                                }
                            }
                        }
                        workspaceIds
                        workspaces (first: -1 sort: "name") { 
                            totalCount
                            edges { 
                                node {
                                    id
                                    name 
                                    logo { src } 
                                    badgedUserIds
                                    generationVariables { 
                                        signature
                                        location
                                        logo { 
                                            src
                                        }
                                        background {
                                            src
                                        }
                                        cover { 
                                            src
                                        }
                                        smallLogo { 
                                            src
                                        }
                                        baselineLogo { 
                                            src
                                        }
                                        whiteLogo { 
                                            src
                                        }
                                        whiteSmallLogo {
                                            src
                                        }
                                        whiteBaselineLogo {
                                            src
                                        }
                                        firstFont {
                                            family
                                            style
                                            postscriptName
                                            file {
                                                id
                                                createdAt
                                                src
                                                originalName
                                                mimeType
                                                size
                                                meta
                                            }
                                        }
                                        secondFont {
                                            family
                                            style
                                            postscriptName
                                            file {
                                                id
                                                createdAt
                                                src
                                                originalName
                                                mimeType
                                                size
                                                meta
                                            }
                                        }
                                        thirdFont {
                                            family
                                            style
                                            postscriptName
                                            file {
                                                id
                                                createdAt
                                                src
                                                originalName
                                                mimeType
                                                size
                                                meta
                                            }
                                        }
                                        fancyFont {
                                            family
                                            style
                                            postscriptName
                                            file {
                                                id
                                                createdAt
                                                src
                                                originalName
                                                mimeType
                                                size
                                                meta
                                            }
                                        }
                                        mainColor
                                        secondaryColor
                                        extraColor
                                        mainGradientColor
                                        secondaryGradientColor
                                        readableExtraColor
                                        canMainColorBeDerivated
                                        lightColor
                                        darkColor
                                        shapesRound
                                        textBoxStrokeWidth
                                        mediaBoxStrokeWidth
                                        shapesOpacity
                                        shouldThereBeDropShadows
                                        logoScale
                                        smallLogoScale
                                        smallLogoPlacement
                                        smallLogoXPosition
                                        smallLogoYPosition
                                        shouldDesignBeSimplified
                                    }
                                } 
                            }
                        }
                        sponsorCode
                        sponsoredOrganizationsHavingPaidAtLeastOnceInThePast (first: -1) {
                            totalCount
                            edges {
                                node {
                                    referent {
                                        email
                                    }
                                }
                            }
                        }
                        plan {
                            adjustedStorageLimit
                            credits
                            externalBilling
                            gaugeCapacity
                            usedStorage
                            usedPeriodicCloudyLicensesCount
                            offeredMonthLicensePacks {
                                forFree
                                thanksToSponsorship
                            }
                            deals  {
                                coupon {
                                    amount
                                    percentage
                                }
                                licensePackSize
                                monthsCount
                                monthlyPaid
                                thirdProductId
                                thirdPriceId
                                thirdSubscriptionId
                                thirdSubscriptionStartAt
                                thirdSubscriptionEndAt
                                thirdSubscriptionBillingPeriodEndAt
                                thirdSubscriptionLastSuccessfulPaymentAt
                                pause {
                                    startAt
                                    monthsCount 
                                    endAt
                                    nextThirdInvoiceAt
                                }
                            }
                            traits {
                                havePartiallyBeenChanged {
                                    only
                                    bySomeone
                                    byBackOfficeAdmin
                                }
                                storageLimit {
                                    value
                                    hasBeenChanged {
                                        only
                                        bySomeone
                                        byBackOfficeAdmin
                                    }
                                }
                                maxLicensedWithPeriodicCloudyMembersCount { value, dealPeak }
                                maxLicensedWithPeriodicLocalMembersCount { value, dealPeak }
                                maxLicensedWithLifetimeLocalMembersCount { value, dealPeak }
                                maxMembersPerWorkspaceCount { value }
                                maxWorkspacesCount { value }
                                generationDailyRateLimit { value }
                                playerVideosOpenToPublic { value }
                                allowedApiAppVideoGenerations { value }
                                visibleCreditsGauge { value }
                            }
                            trialPeriod {
                                startAt
                                endAt
                            }
                        }
                        referent {
                            id
                            email
                            names {
                                firstname
                                lastname
                            }
                            phone
                        }
                        location {
                            address
                            details
                            country
                        }
                        taxNumber {
                            subType
                            value
                        }
                    }
                    workspaceIds
                    workspaces (first: -1 sort: "name") { 
                        totalCount
                        edges { 
                            node { 
                                id
                                name 
                                logo { src }
                                generationVariables { 
                                    signature
                                    location
                                    logo { 
                                        src
                                    }
                                    cover { 
                                        src
                                    }
                                    background {
                                        src
                                    }
                                    smallLogo { 
                                        src
                                    }
                                    baselineLogo { 
                                        src
                                    }
                                    whiteLogo { 
                                        src
                                    }
                                    whiteSmallLogo {
                                        src
                                    }
                                    whiteBaselineLogo {
                                        src
                                    }
                                    firstFont {
                                        family
                                        style
                                        postscriptName
                                        file {
                                            id
                                            createdAt
                                            src
                                            originalName
                                            mimeType
                                            size
                                            meta
                                        }
                                    }
                                    secondFont {
                                        family
                                        style
                                        postscriptName
                                        file {
                                            id
                                            createdAt
                                            src
                                            originalName
                                            mimeType
                                            size
                                            meta
                                        }
                                    }
                                    thirdFont {
                                        family
                                        style
                                        postscriptName
                                        file {
                                            id
                                            createdAt
                                            src
                                            originalName
                                            mimeType
                                            size
                                            meta
                                        }
                                    }
                                    fancyFont {
                                        family
                                        style
                                        postscriptName
                                        file {
                                            id
                                            createdAt
                                            src
                                            originalName
                                            mimeType
                                            size
                                            meta
                                        }
                                    }
                                    mainColor
                                    secondaryColor
                                    extraColor
                                    mainGradientColor
                                    secondaryGradientColor
                                    readableExtraColor
                                    canMainColorBeDerivated
                                    lightColor
                                    darkColor
                                    shapesRound
                                    textBoxStrokeWidth
                                    mediaBoxStrokeWidth
                                    shapesOpacity
                                    shouldThereBeDropShadows
                                    logoScale
                                    smallLogoScale
                                    smallLogoPlacement
                                    smallLogoXPosition
                                    smallLogoYPosition
                                    shouldDesignBeSimplified
                                }
                            } 
                        } 
                    }
                }
            }
        `
        : null;
}

const queryAppUserMock = () => 'AppUser';

const queryAppUserApi = process.env.NEXT_PUBLIC_MOCKS === 'true' ? queryAppUserMock : queryAppUser;

export default queryAppUserApi;
