import { faCode, faPaintbrush, faShare, faUser, faUserGear, faUsersGear, faThLarge } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';
import { faCreditCard } from '@fortawesome/free-regular-svg-icons';
type MenuConfigItem = {
  caption: string;
  route?: string;
  link?: string;
  className?: () => string;
  icon: any;
  if: () => boolean;
  children?: MenuConfig;
};
type MenuConfig = {
  [key: string]: MenuConfigItem;
};
const getManageSubMenuConfig = (isIframe, appuser, workspace): MenuConfig => {
  if (!appuser) return {};
  const isDirector = appuser.isOrganizationDirector();
  const isWorkspaceManager = workspace && appuser.isOrganizationManagerOfWorkspace(workspace?.id);
  const isUnderExternalBilling = appuser.isUnderExternalBilling();
  return {
    share: {
      route: '/organization/social',
      icon: faShare,
      caption: tr('front.menu.items.office.front_office.organization.social'),
      if: () => !isIframe && (isDirector || isWorkspaceManager)
    },
    variables: {
      route: '/organization/variables',
      icon: faPaintbrush,
      caption: tr('front.menu.items.office.front_office.organization.variables'),
      if: () => !isIframe
    },
    allUsers: {
      route: '/organization/team',
      icon: faUser,
      caption: tr('front.menu.items.office.front_office.organization.team'),
      if: () => !isIframe && isDirector
    },
    manage: {
      route: workspace ? `/workspace/${get(workspace, 'id')}/manage` : '',
      icon: faUsersGear,
      caption: tr('front.menu.items.office.front_office.workspace.manage', {
        name: get(workspace, 'name')
      }),
      if: () => !isIframe && !isDirector && isWorkspaceManager
    },
    workspaces: {
      route: '/organization/workspaces',
      icon: faThLarge,
      caption: tr('front.menu.items.office.front_office.organization.workspaces'),
      if: () => !isIframe && isDirector
    },
    apiApp: {
      route: '/organization/api',
      icon: faCode,
      caption: tr('front.menu.items.office.front_office.organization.api_app'),
      if: () => !isIframe && isDirector
    },
    billing: {
      route: '/organization/billing',
      icon: faCreditCard,
      caption: tr('front.menu.items.office.front_office.organization.billing'),
      if: () => !isIframe && isDirector && !isUnderExternalBilling
    }
  };
};
export default getManageSubMenuConfig;