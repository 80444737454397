import React from 'react';
import { faBook, faCloudDownloadAlt, faCog, faFilm, faStar, faUser } from '@fortawesome/free-solid-svg-icons';
import { get } from 'lodash';
import clsx from 'clsx';
import ImageFile from '@components/bridge/bridge/ImageFile';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import getManageSubMenuConfig from '@components/bridge/bridge/menu/ManageSubMenuConfig';
import { getAbsoluteUrl } from '@utils/filePath';
type MenuConfigItem = {
  caption: string;
  route?: string;
  link?: string;
  className?: () => string;
  icon: any;
  if: () => boolean;
  children?: MenuConfig;
};
type MenuConfig = {
  [key: string]: MenuConfigItem;
};
const getFrontOfficeMenuConfig = (isIframe, appuser, workspace): MenuConfig => {
  if (!appuser) return {};
  const isDirector = appuser.isOrganizationDirector();
  const isWorkspaceManager = appuser.isOrganizationManagerOfWorkspace(workspace?.id);
  const hasAdvancedMode = get(appuser, 'organization.advancedMode');
  const manageChildrenMenu = getManageSubMenuConfig(isIframe, appuser, workspace);
  const isManageChildrenMenuVisible = Object.values(manageChildrenMenu).some(property => {
    return typeof property.if === 'function' && property.if();
  });
  return {
    home: {
      route: '/',
      icon: faStar,
      caption: tr('front.menu.items.office.front_office.home'),
      className: () => clsx('/home'),
      if: () => isDirector || isWorkspaceManager
    },
    videoLib: {
      route: '/video/library',
      icon: faFilm,
      caption: tr('front.menu.items.office.front_office.video.library'),
      className: () => clsx('/videoLibrary'),
      if: () => isDirector || isWorkspaceManager
    },
    profile: {
      route: `/user/${appuser.id}`,
      icon: () => get(appuser, 'profile.portrait.src') ? <ImageFile backgroundSize='cover' src={getAbsoluteUrl(get(appuser, 'profile.portrait.src'), false)} /> : <FontAwesomeIcon icon={faUser} width={16} />,
      caption: tr('front.menu.items.office.front_office.user.profile'),
      if: () => !isIframe
    },
    manage: {
      icon: faCog,
      caption: tr(`front.menu.items.office.front_office.${workspace && !isDirector && appuser.isOrganizationManagerOfWorkspace(workspace.id) ? 'workspace' : 'organization'}.manage`, {
        name: workspace ? workspace.name || get(appuser, 'profile.names.both') || 'Anonymous' : get(appuser, 'organization.name')
      }),
      if: () => isManageChildrenMenuVisible,
      children: manageChildrenMenu
    },
    docs: {
      link: `https://docs.danim.com/`,
      icon: faBook,
      caption: tr('front.menu.items.office.front_office.user.docs'),
      if: () => !isIframe && hasAdvancedMode && isDirector
    },
    dl: {
      route: '/creators',
      icon: faCloudDownloadAlt,
      caption: tr('front.menu.items.office.front_office.user.download_creator'),
      if: () => !isIframe && hasAdvancedMode && isDirector
    }
  };
};
export default getFrontOfficeMenuConfig;