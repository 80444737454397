import __Base from '@components/Base';
import React from 'react';
import Error from '@components/bridge/bridge/Error';
import * as Sentry from '@sentry/nextjs';
import Root from './Root';
class ErrorBoundary extends __Base {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }
  static getDerivedStateFromError = error => {
    return {
      hasError: true
    };
  };
  componentDidCatch = error => {
    Sentry.captureException(error);
  };
  render = () => {
    return this.state.hasError ? <Error {...this.props} /> : <Root {...this.props} />;
  };
}
export default ErrorBoundary;