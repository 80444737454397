import { GlobalStyles as MUIGlobalStyles } from '@mui/material';
import React from 'react';
export default function GlobalStyles() {
  const inputGlobalStyles = <MUIGlobalStyles styles={{
    // Fix size problem with nextJS
    '#__next': {
      width: '100%',
      height: '100%'
    },
    '.absolute': {
      '> *': {
        position: 'absolute !important'
      },
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      transition: 'all 0.2s ease-in-out'
    }
  }} />;
  return inputGlobalStyles;
}