import __Base from '@components/Base';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { withRouter } from 'next/router';
class Loader extends __Base {
  render = () => {
    const {
      fetching
    } = this.props;
    return <div className={clsx('loader', fetching && '/fetching')} />;
  };
}
Loader.defaultProps = {
  fetching: 0
};
Loader.propTypes = {
  fetching: PropTypes.number
};
export default withRouter(Loader);