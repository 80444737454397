import _ from 'lodash';
import _axios from 'axios';
import _axiosRetry from 'axios-retry';
import mergeDeep from '@utils/lodash/mergeDeep';
import { serialize } from 'object-to-formdata';
import Router from 'next/router';
import regexQuote from '@utils/lodash/regexQuote';
import * as Sentry from '@sentry/nextjs';
import { isEnvProd } from '@utils/getEnv';
import Workspace from '@utils/Workspace';

const _axiosRetryStatusWhitelist = [
    // This whitelist is used for the retry here, but also elsewhere
    // Network Error
    0,
    null,
    undefined,
    // Bad Gateway
    502,
    504
];

_axiosRetry(_axios, {
    retries: 12,
    retryDelay: _axiosRetry.exponentialDelay,
    retryCondition: err => _.includes(_axiosRetryStatusWhitelist, _.get(err, 'response.status')),
    onRetry: (count, error) => {
        console.log(`Retrying ${count} XHR...`, error); // Do not tell that we use axios here
    }
});

// When token is invalid and a refresh has been asked, store all new requests in this queue before executing then
let tokenIsBeingRefreshed = false;
let tokenIsBeingGenerated = false;
let pendingRequests = [];

export const getXsrfToken = () => {
    const isImpersonated = checkRefreshTokenImpersonate();
    if (isImpersonated) {
        return cookiesManager.getCookie(
            isEnvProd ? 'IMPERSONATION_XSRF_TOKEN' : `IMPERSONATION_XSRF_TOKEN_${process.env.NEXT_PUBLIC_APP_ENV}`
        );
    }
    return cookiesManager.getCookie(isEnvProd ? 'XSRF_TOKEN' : `XSRF_TOKEN_${process.env.NEXT_PUBLIC_APP_ENV}`);
};

export const getToken = () => {
    const isImpersonated = checkRefreshTokenImpersonate();
    if (isImpersonated) {
        return cookiesManager.getCookie(
            isEnvProd ? 'IMPERSONATION_API_TOKEN' : `IMPERSONATION_API_TOKEN_${process.env.NEXT_PUBLIC_APP_ENV}`
        );
    }
    return cookiesManager.getCookie(isEnvProd ? 'API_TOKEN' : `API_TOKEN_${process.env.NEXT_PUBLIC_APP_ENV}`);
};

export const checkRefreshTokenImpersonate = () =>
    !!cookiesManager.getCookie(
        isEnvProd ? 'IMPERSONATION_API_REFRESH_TOKEN_TRACE' : `IMPERSONATION_API_REFRESH_TOKEN_TRACE_${process.env.NEXT_PUBLIC_APP_ENV}`
    );

export const checkRefreshTokenTrace = () =>
    !!cookiesManager.getCookie(isEnvProd ? 'API_REFRESH_TOKEN_TRACE' : `API_REFRESH_TOKEN_TRACE_${process.env.NEXT_PUBLIC_APP_ENV}`);

export const apiSwrRequest = async (data, name = null, options = null) => {
    return apiRequest(data, options).then(async res => {
        if (!res.success) {
            throw new Error(res.message);
        }
        if (res?.errors?.length > 0) {
            Sentry.captureMessage('GraphQL error', {
                contexts: {
                    extra: {
                        isImpersonated: checkRefreshTokenImpersonate(),
                        errors: JSON.stringify(res.errors?.slice(0, 4)),
                        name,
                        xsrf: getXsrfToken(),
                        token: getToken()
                    },
                    tags: {
                        api: 'GQL'
                    }
                }
            });
        }
        if (res?.warnings?.length > 0) {
            Sentry.captureMessage('GraphQL warning', {
                contexts: {
                    extra: {
                        isImpersonated: checkRefreshTokenImpersonate(),
                        errors: JSON.stringify(res?.warnings),
                        name,
                        xsrf: getXsrfToken(),
                        token: getToken()
                    },
                    tags: {
                        api: 'GQL'
                    }
                }
            });
        }
        return name !== null ? res.data?.[name] || null : res.data;
    });
};

export const apiRequest = async (data, options = null) => {
    const route = Router.pathname;
    const underWhitelabel = !window.areWeUnderDanimHost();

    const tokenUrl = Router.query.accessToken || false;

    const classicHeaders = {
        'X-XSRF': getXsrfToken(),
        'X-Requested-With': 'XMLHttpRequest',
        'X-Requested-From': window.location.href
    };

    const sdkHeaders = {
        Authorization: `Bearer ${tokenUrl}`
    };

    const baseOptions = {
        method: 'POST',
        url: underWhitelabel ? `${window.origin}/api/read` : `${process.env.NEXT_PUBLIC_API_URL}read`,
        timeout: _.get(options, 'timeout') || 0,
        headers: {
            // Authorization: impersonated ? `Bearer ${cookiesManager.getCookie('impersonatedToken')}` : null,
            'Cache-Control': 'no-cache, must-revalidate', // avoid browser caching, since some would otherwise use JSON results when user plays with history (combined with nc query parameter, just in case)
            ...(tokenUrl ? sdkHeaders : classicHeaders),
            'Content-Type': 'application/json',
            'X-App-Id': route.includes('back')
                ? process.env.NEXT_PUBLIC_API_APP_DANIM_BACK_OFFICE_KEY
                : process.env.NEXT_PUBLIC_API_APP_DANIM_FRONT_OFFICE_KEY,
            ...(options || {})
        },
        data: {
            query: data
        },
        withCredentials: true,
        underWhitelabel
    };

    return axios(baseOptions);
};

export const execApiRequest = async (request = null, tokenUrl) => {
    const route = Router.pathname;
    const { options, callbacks } = request;
    const promise = async () => {
        if (options.refreshTokenJustBefore) {
            await refreshToken(options);
        }

        const headers = {
            ...(tokenUrl ? { Authorization: `Bearer ${tokenUrl}` } : {}),
            ...(getXsrfToken() ? { 'X-XSRF': getXsrfToken() } : {})
        };

        let response = await _axios(
            mergeDeep(options, {
                headers
            })
        ).catch(error => {
            if (callbacks.catch) {
                callbacks.catch(error);
            }
        });

        // If token is invalid, refresh it and retry the request
        // If token is being refreshed, wait for it to be refreshed and retry the request
        // If token is being generated, wait for it to be generated and retry the request
        // If token is being refreshed or generated, store all new requests in this queue before executing then
        if (_.includes(['BadToken', 'AuthenticationRequired', 'InvalidXsrfToken'], _.get(response, 'data.meta.type'))) {
            const retryPromise = () =>
                _axios(
                    mergeDeep(options, {
                        headers: {
                            'X-XSRF': getXsrfToken()
                        }
                    })
                );
            if (!tokenIsBeingRefreshed) {
                await refreshToken(options);
                response = await retryPromise();
            } else {
                response = await new Promise((resolve, reject) => {
                    pendingRequests.push(() => retryPromise().then(resolve).catch(reject));
                });
            }
        }

        if (response) {
            let errorMeta = _.get(response, 'data.meta') || {};
            let errorWarnings = _.get(errorMeta, 'warnings') || {};

            if (errorWarnings?.length > 0) {
                Sentry.captureMessage('Axios API warning', {
                    contexts: {
                        extra: {
                            warnings: JSON.stringify(errorWarnings),
                            meta: errorMeta || {},
                            raw: JSON.stringify(response)
                        },
                        tags: {
                            section: 'Axios API'
                        }
                    }
                });
            }

            if (_.get(response, 'data.success') === false) {
                let errorName = _.get(response, 'data.meta.type');
                let errorMessage = _.get(response, 'data.message');
                if (_.isArray(_.get(response, 'data.data'))) {
                    _.each(Object.values(response.data.data), (data: { success: boolean }) => {
                        if (!data.success) {
                            errorName = _.get(data, 'meta.type');
                            errorMessage = _.get(data, 'message');
                            errorMeta = _.get(data, 'meta') || {};
                        }
                    });
                } // response data may be a collection (e.g. multiple commands request)

                // List of generic errors that need a logout
                if (_.includes(['SubjectNotFound'], errorName)) {
                    return logout();
                }

                const ignoreSentryErrors = ['InvalidPassword', 'EmailNotFound'];
                if (!ignoreSentryErrors.includes(errorName)) {
                    Sentry.captureMessage('Axios API error', {
                        contexts: {
                            extra: {
                                name: errorName || null,
                                message: errorMessage,
                                meta: errorMeta || {},
                                raw: JSON.stringify(response)
                            },
                            tags: {
                                section: 'Axios API'
                            }
                        }
                    });
                }

                if (callbacks.catch) {
                    callbacks.catch({
                        name: errorName,
                        message: errorMessage,
                        meta: errorMeta
                    });
                }
            } else if (callbacks.then) {
                callbacks.then(response.data);
            }
            if (callbacks.finally) callbacks.finally();

            return response.data;
        }
    };

    const xsrfToken = getXsrfToken();

    if (route.includes('/connect') && !xsrfToken) {
        if (!tokenIsBeingGenerated) {
            tokenIsBeingGenerated = true;

            const baseOptionsGenerate = {
                method: 'POST',
                url: options.underWhitelabel ? `${window.origin}/api/tokens/generate` : `${process.env.NEXT_PUBLIC_API_URL}tokens/generate`,
                headers: {
                    'Content-Type': 'multipart/form-data',
                    'X-App-Id': route?.includes('back')
                        ? process.env.NEXT_PUBLIC_API_APP_DANIM_BACK_OFFICE_KEY
                        : process.env.NEXT_PUBLIC_API_APP_DANIM_FRONT_OFFICE_KEY
                },
                data: serialize({ ttl: 5 * 60 }),
                withCredentials: true,
                maxRedirects: 10
            };

            await _axios(baseOptionsGenerate).catch(error => {
                Sentry.captureException(error, {
                    tags: {
                        section: 'tokens'
                    }
                });
            });
            tokenIsBeingGenerated = false;
        }
    }

    if (tokenIsBeingRefreshed || tokenIsBeingGenerated) {
        return new Promise((resolve, reject) => {
            pendingRequests.push(() => promise().then(resolve).catch(reject));
        });
    }

    if (!xsrfToken && !tokenUrl) {
        await refreshToken(options);
        return promise();
    }

    return promise();
};

interface AxiosCallBacks {
    then?: (data: any) => void;
    catch?: (error: any) => void;
    finally?: () => void;
}

export const axios = async (options, callbacks: AxiosCallBacks = {}, alterLoaderState = true) => {
    const route = Router.pathname;

    const tokenUrl = Router.query.accessToken || false;

    const shouldDisplayClassicHeaders = !tokenUrl;

    const classicHeaders = {
        'X-Requested-With': 'XMLHttpRequest',
        'X-Requested-From': window.location.href
    };

    // If we come from other that apiRequest
    if (!options.underWhitelabel) {
        options.underWhitelabel = !window.areWeUnderDanimHost();
    }

    const isApiRequest = !!(
        (options.url || '').match(
            new RegExp(
                regexQuote(
                    `${process.env.NEXT_PUBLIC_OFFICE_API_DNS_LEVEL_3}${process.env.NEXT_PUBLIC_DNS_LEVEL_2}${process.env.NEXT_PUBLIC_DNS_LEVEL_1}`
                )
            )
        ) ||
        (options.underWhitelabel && (options.url || '').match(new RegExp(regexQuote(`${window.origin}/api`))))
    );

    const baseOptions = {
        timeout: _.get(options, 'timeout') || 0,
        headers: {
            'Content-Type': 'application/json',
            'Cache-Control': 'no-cache', // avoid browser caching, since some would otherwise use JSON results when user plays with history (combined with nc query parameter, just in case)
            // 'X-App-Version': process.env.NEXT_PUBLIC_CONFIG_BUILD_ID,
            'X-App-Version': Math.floor(Date.now() / 1000),
            ...(shouldDisplayClassicHeaders ? classicHeaders : {}),
            'X-App-Id': route?.includes('back')
                ? process.env.NEXT_PUBLIC_API_APP_DANIM_BACK_OFFICE_KEY
                : process.env.NEXT_PUBLIC_API_APP_DANIM_FRONT_OFFICE_KEY
        },
        withCredentials: true,
        maxRedirects: 5
    };

    options = mergeDeep(baseOptions, options);
    options.url = encodeURI(options.url);

    if (isApiRequest) {
        return execApiRequest({ options, callbacks, alterLoaderState }, tokenUrl);
    }

    let response = null;
    try {
        response = await _axios(options);
        if (_.get(response, 'data.success') === false && callbacks.catch) {
            callbacks.catch({
                name: _.get(response, 'data.meta.type') || null,
                message: _.get(response, 'data.message'),
                meta: _.get(response, 'data.meta') || {}
            });
            Sentry.captureMessage('Axios HTTP error', {
                contexts: {
                    extra: {
                        name: _.get(response, 'data.meta.type') || null,
                        message: _.get(response, 'data.message'),
                        meta: _.get(response, 'data.meta') || {},
                        raw: JSON.stringify(response)
                    },
                    tags: {
                        section: 'Axios HTTP'
                    }
                }
            });
        } else {
            callbacks.then(response.data);
        }
    } catch (e) {
        if (callbacks.catch) callbacks.catch(e);
        response = _.get(e, 'response') || null;
    }
    if (callbacks.finally) callbacks.finally();

    return response;
};

export async function logout(needRedirect = true) {
    const underWhitelabel = !window.areWeUnderDanimHost();

    const logoutOptions = {
        method: 'POST',
        url: underWhitelabel ? `${window.origin}/api/tokens/forget` : `${process.env.NEXT_PUBLIC_API_URL}tokens/forget`,
        withCredentials: true
    };
    await _axios(logoutOptions)
        .then(resp => {
            if (resp?.data?.success) {
                cookiesManager.getCookie('LAST_ADMIN_ACTIVITY', true);
                cookiesManager.getCookie(isEnvProd ? 'LAST_KNOWN_APPUSER' : `LAST_KNOWN_APPUSER_${process.env.NEXT_PUBLIC_APP_ENV}`, true);
                cookiesManager.getCookie(
                    isEnvProd ? 'IMPERSONATION_XSRF_TOKEN' : `IMPERSONATION_XSRF_TOKEN_${process.env.NEXT_PUBLIC_APP_ENV}`,
                    true
                );
                cookiesManager.getCookie(isEnvProd ? 'XSRF_TOKEN' : `XSRF_TOKEN_${process.env.NEXT_PUBLIC_APP_ENV}`, true);
                Workspace.setSelectedWorkspaceId(null);
            } else {
                Sentry.captureMessage('Axios Logout error', {
                    contexts: {
                        extra: {
                            raw: JSON.stringify(resp)
                        },
                        tags: {
                            section: 'Axios HTTP'
                        }
                    }
                });
                addFlash(
                    {
                        message: "§Session can't be deleted.",
                        type: 'error'
                    },
                    2000
                );
            }

            if (needRedirect) {
                // We need to redirect on connect because the user don't have any cookies, request will fail
                if (window.location.href.includes('connect')) {
                    window.location.replace('/');
                } else {
                    window.location.replace('/connect');
                }
            }
        })
        .catch(async error => {
            Sentry.captureMessage('Axios Logout error', {
                contexts: {
                    extra: {
                        raw: JSON.stringify(error)
                    },
                    tags: {
                        section: 'Axios HTTP'
                    }
                }
            });
            addFlash(
                {
                    message: "§Session can't be deleted.",
                    type: 'error'
                },
                2000
            );
        });
}

async function refreshToken(options) {
    tokenIsBeingRefreshed = true;
    const isImpersonated = checkRefreshTokenImpersonate();
    const isCookieRefreshToken = checkRefreshTokenTrace();

    // If we are not impersonated, and we don't have a refresh token, we log out
    if (!isCookieRefreshToken && !isImpersonated) return logout();

    const refreshOptions = {
        headers: {
            'X-App-Id': window.location.href.includes('back')
                ? process.env.NEXT_PUBLIC_API_APP_DANIM_BACK_OFFICE_KEY
                : process.env.NEXT_PUBLIC_API_APP_DANIM_FRONT_OFFICE_KEY
        },
        method: 'POST',
        url: options.underWhitelabel ? `${window.origin}/api/tokens/refresh` : `${process.env.NEXT_PUBLIC_API_URL}tokens/refresh`,
        data: serialize({
            clearImpersonationCookie: !isImpersonated
        }),
        withCredentials: true
    };
    delete refreshOptions['X-XSRF'];
    let dataRefresh;

    await _axios(refreshOptions)
        .then(async res => {
            dataRefresh = res.data;

            if (!dataRefresh?.success) {
                Sentry.captureMessage('Axios Refresh error', {
                    contexts: {
                        extra: {
                            raw: JSON.stringify(dataRefresh)
                        },
                        tags: {
                            section: 'Axios refresh'
                        }
                    }
                });
            }
            if (dataRefresh.meta?.type === 'BadToken') {
                await _axios(refreshOptions).then(async res => {
                    dataRefresh = res.data;

                    if (dataRefresh.meta?.type === 'BadToken') {
                        Sentry.captureMessage('Axios Refresh error', {
                            contexts: {
                                extra: {
                                    raw: JSON.stringify(dataRefresh),
                                    second: true
                                },
                                tags: {
                                    section: 'Axios refresh'
                                }
                            }
                        });

                        await logout();
                        if (!window.location.href.includes('/connect')) {
                            return window.location.replace('/connect');
                        }
                    }
                });
            }

            if (!dataRefresh.data?.xsrf) {
                await logout();
                if (!window.location.href.includes('/connect')) {
                    return window.location.replace('/connect');
                }
            }
        })
        .catch(error => {
            Sentry.captureMessage('Axios Refresh error', {
                contexts: {
                    extra: {
                        raw: JSON.stringify(error)
                    },
                    tags: {
                        section: 'Axios Catch'
                    }
                }
            });
        })
        .finally(() => {
            tokenIsBeingRefreshed = false;
            pendingRequests.forEach(pendingRequest => pendingRequest());
            pendingRequests = [];
        });

    return dataRefresh;
}
