// eslint-disable-next-line import/prefer-default-export
export const getAbsoluteUrl = (src, bridge = false) => {
    if (!src) return src;

    src = src.replace(/^\//, ''); // e.g. /a/path.png -> a/path.png
    if (window.location.host.includes('webserver')) return `mocks/${src}`;
    return (
        (!src.match(/:/)
            ? src.match(/\.[a-zA-Z0-9]{2,}(\?.*)?$/) // extension?
                ? `https://${process.env.NEXT_PUBLIC_AWS_S3_URI_HOST}/${
                      bridge ? process.env.NEXT_PUBLIC_AWS_S3_BUCKET_BRIDGE : process.env.NEXT_PUBLIC_AWS_S3_BUCKET
                  }/`
                : `${window.location.host}/`
            : '') + src
    );
};
