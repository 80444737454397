import __Base from '@components/Base';
import React from 'react';
class NotificationWaiter extends __Base {
  render = () => {
    return <article className='user:notificationWaiter'>
                <div className='-strip' />
                <div className='-strip' />
                <div className='-strip' />
            </article>;
  };
}
export default NotificationWaiter;