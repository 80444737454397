import React, { useEffect } from 'react';
import Base from '@components/Base';
import includes from 'lodash/includes';
import throttle from 'lodash/throttle';
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/filter';
import camelCase from '@utils/lodash/camelCase';
import isString from 'lodash/isString';
import pascalCase from '@utils/lodash/pascalCase';
import sortByKey from '@utils/lodash/sortByKey';
import uniqid from '@utils/lodash/uniqid';
import queryConstantApi from '@queries/api/bridge/queryConstant';
import queryAppUserApi from '@queries/api/bridge/queryAppUser';
import queryMeApi from '@queries/api/bridge/queryMe';
import queryWhitelabelApi from '@queries/api/bridge/queryWhitelabel';
import { apiRequest, apiSwrRequest, checkRefreshTokenImpersonate, checkRefreshTokenTrace, logout } from '@services/http/client';
import clsx from 'clsx';
import qs from 'qs';
import { isBrowser, isMobile } from 'react-device-detect';
import { v4 as uuidv4 } from 'uuid';
import Factory from '@components/bridge/bridge/immutable/factory';
import dynamic from 'next/dynamic';
import useSWR, { mutate } from 'swr';
import Loader from '@components/bridge/bridge/Loader';
import fetcherMock from '@services/http/clientMock';
import * as Sentry from '@sentry/nextjs';
import Header from '@components/bridge/bridge/Header';
import ModalRoot from '@components/bridge/bridge/modal/ModalRoot';
import queryWorkspaceApi from '@queries/api/bridge/queryWorkspace';
import setInStorage from '@utils/lodash/setInStorage';
import mergeDeep from '@utils/lodash/mergeDeep';
import { Button, Stack } from '@mui/material';
import { darken, lighten } from '@mui/system/colorManipulator';
import { useMyTheme } from '@theme/index';
import { toast } from 'react-toastify';
import { getAbsoluteUrl } from '@utils/filePath';
import { isEnvProd, isEnvTest } from '@utils/getEnv';
import User from '@utils/User';
import Workspace from '@utils/Workspace';
const HeaderLayout = dynamic(() => import('@layout/HeaderLayout'), {
  ssr: false
});
const FlashBox = dynamic(() => import('@components/bridge/bridge/FlashBox'), {
  ssr: false
});
const ChatAlternative = dynamic(() => import('@components/bridge/bridge/ChatAlternative'), {
  ssr: false
});
const ImpersonatorStrip = dynamic(() => import('@components/bridge/bridge/ImpersonatorStrip'), {
  ssr: false
});
const TrialBanner = dynamic(() => import('@components/bridge/bridge/TrialBanner'), {
  ssr: false
});
const Lottie = dynamic(() => import('@components/bridge/bridge/Lottie'), {
  ssr: false
});
function withMyHook(Component) {
  return function WrappedComponent(props) {
    // Call hooks globally
    const {
      router
    } = props;
    const isServer = typeof window === 'undefined';
    const workspaceID = !isServer && !router.route.includes('connect') && Workspace.getSelectedWorkspaceId() || null;

    // Call graphQL from SWR
    const {
      data: me
    } = User.useLoadMe();
    const {
      data: whitelabel
    } = useSWR(() => queryWhitelabelApi(), data => apiSwrRequest(data, 'bridgeWhitelabel'));
    const {
      theme,
      setMyTheme
    } = useMyTheme();
    const {
      data: appuser
    } = useSWR(() => queryAppUserApi(me.id, workspaceID), async data => {
      const appuser = await apiSwrRequest(data, me?.__typename === 'User' ? 'bridgeUser' : 'bridgeApiApp');
      const workspaceIds = appuser?.organization?.workspaceIds || [];
      if (!workspaceIds.length && router.isReady && !window.sdkMode) await logout();
      return appuser;
    });
    const {
      data: workspace
    } = useSWR(() => queryWorkspaceApi(workspaceID), async data => {
      const workspace = await apiSwrRequest(data, 'bridgeWorkspace');
      if (!workspace?.id) return null;
      return workspace;
    });

    // Static constants from JSON
    const {
      data: constants
    } = useSWR(queryConstantApi, fetcherMock);
    const {
      data: constantsProd
    } = useSWR('ConstantsProd', fetcherMock);
    useEffect(() => {
      const url = router.route;
      document.getElementsByTagName('html')[0].className = 'html';
      if (url === '/') {
        document.getElementsByTagName('html')[0].classList.add(`/routed/${camelCase('frontOffice')}/front-office::home`);
      } else if (url === '/connect') {
        document.getElementsByTagName('html')[0].classList.add(`/routed/${camelCase('connect')}/connect::home`);
      } else if (url.includes('/connect')) {
        document.getElementsByTagName('html')[0].classList.add(`/routed/${camelCase('connect')}/connect${url.replace('/', '::')}`);
      } else if (url === '/back') {
        document.getElementsByTagName('html')[0].classList.add(`/routed/${camelCase('backOffice')}/back-office::home`);
      } else if (url.includes('/back')) {
        document.getElementsByTagName('html')[0].classList.add(`/routed/${camelCase('backOffice')}/back-office${url.replace('/', '::')}`);
      } else {
        document.getElementsByTagName('html')[0].classList.add(`/routed/${camelCase('frontOffice')}/front-office${url.replace('/', '::')}`);
      }
    }, [router]);
    useEffect(() => {
      if (router?.query?.accessToken) {
        mutate(queryMeApi());
      }
    }, [router.isReady]);

    // Set whitelabel color
    useEffect(() => {
      if (whitelabel === undefined) return;
      const whitelabelInner = whitelabel || appuser?.organization?.whitelabel;
      if (whitelabelInner) {
        const root = document.querySelector(':root');
        const firstColor = whitelabelInner?.colorScheme?.first || `#${process.env.NEXT_PUBLIC_COLOR_1_HEX}`;
        const firstColorConverted = window.hexToHSL(firstColor);
        const firstColorIsLight = window.isLightOrDark(firstColor);
        const secondColor = whitelabelInner?.colorScheme?.second || `#${process.env.NEXT_PUBLIC_COLOR_2_HEX}`;
        const secondColorConverted = window.hexToHSL(secondColor);
        const secondColorIsLight = window.isLightOrDark(secondColor);
        setMyTheme({
          ...theme,
          palette: {
            ...theme.palette,
            primary: {
              lighter: lighten(firstColor, 0.4),
              light: lighten(firstColor, 0.2),
              main: firstColor,
              dark: darken(firstColor, 0.2),
              darker: darken(firstColor, 0.4),
              contrastText: firstColorIsLight === 'light' ? darken(firstColor, 0.95) : lighten(firstColor, 0.95)
            },
            secondary: {
              lighter: lighten(secondColor, 0.4),
              light: lighten(secondColor, 0.2),
              main: secondColor,
              dark: darken(secondColor, 0.2),
              darker: darken(secondColor, 0.4),
              contrastText: secondColorIsLight === 'light' ? darken(secondColor, 0.95) : lighten(secondColor, 0.95)
            }
          }
        });
        if (firstColorConverted) {
          firstColor && root.style.setProperty('--color-1-light', firstColorIsLight === 'light' ? '1' : '0');
          root.style.setProperty('--color-1-h', firstColorConverted[0]);
          root.style.setProperty('--color-1-s', `${firstColorConverted[1]}%`);
          root.style.setProperty('--color-1-l', `${firstColorConverted[2]}%`);
        }
        if (secondColorConverted) {
          secondColor && root.style.setProperty('--color-2-light', secondColorIsLight === 'light' ? '1' : '0');
          root.style.setProperty('--color-2-h', secondColorConverted[0]);
          root.style.setProperty('--color-2-s', `${secondColorConverted[1]}%`);
          root.style.setProperty('--color-2-l', `${secondColorConverted[2]}%`);
        }
      }
    }, [whitelabel, appuser]);
    if (me === undefined || whitelabel === undefined || constants === undefined || constantsProd === undefined || me?.id && !appuser && !router?.asPath.includes('accessToken') || !router.isReady) {
      return <div className='innerLoader'>
                    <Loader />
                </div>;
    }
    if (!workspace && !isServer) {
      setInStorage(`WORKING_WORKSPACE_${process.env.NEXT_PUBLIC_APP_ENV}`, appuser?.organization?.workspaceIds?.length === 1 ? appuser?.organization?.workspaceIds[0] : null, window.localStorage);
    }
    const currentWorkspace = workspaceID ? appuser?.organization.workspaces.totalCount >= 1 ? appuser.organization.workspaces.edges.find(uniqueWorkspace => uniqueWorkspace.node.id === workspaceID)?.node : null : appuser?.organization?.workspaces?.edges.find(uniqueWorkspace => uniqueWorkspace.node.id === appuser?.workspaceIds?.[0])?.node;
    return <Component {...props} constants={mergeDeep(constants, isEnvProd ? constantsProd : {})} me={me} whitelabel={whitelabel} appuser={Factory.create('AppUser', appuser, false)} workspace={currentWorkspace} />;
  };
}
class Root extends Base {
  constructor(props) {
    super(props);
    this.MAX_FLASH_AGE = 30;
    this.PROBE_LIMITS = {
      lg: 1199,
      // 1200 - 1
      md: 991,
      // 992 - 1
      sm: 767,
      // 768 - 1
      xs: 543 // 543 - 1
    };
    this.state = {
      fetching: false,
      loading: true,
      visitors: {
        people: [],
        checked: false
      },
      modal: {
        // payload parameters
        component: null,
        // e.g. App.User.Modal.Content.Login
        propsFactory: state => null,
        // dynamic rendering, e.g. state => {prop1: value1, prop2: value2}
        title: state => null,
        // dynamic string
        // other parameters
        open: false,
        closable: true,
        loading: false
      },
      probe: {
        mobile: isMobile,
        desktop: isBrowser
      },
      menu: {
        open: false
      },
      mode: this.props.router.query?.mode === 'demo' ? 'demo' : false,
      sdkMode: this.props.router.query?.mode === 'sdk',
      backendCallbackUrl: this.props.router.query?.backendCallbackUrl,
      appuser: this.props.appuser,
      workspace: this.props.workspace,
      whitelabel: this.props.whitelabel,
      flashes: []
    };
    window.visitors = this.state.visitors;
    window.mode = this.state.mode;
    window.sdkMode = this.state.sdkMode;
    window.backendCallbackUrl = this.state.backendCallbackUrl;
    window.appuser = this.state.appuser;
    window.gen = this.gen;
    window.constants = this.props.constants;
    window.probe = this.state.probe;
    window.tab = uuidv4();
    window.absolute = getAbsoluteUrl;
    window.addFlash = this.addFlash;
    window.updateFlash = this.updateFlash;
    window.dismissFlash = toast.dismiss;
    window.all = this.all;
    window.asset = this.asset;
    window.cors = this.cors;
    window.img = this.img;
    window.waitForAggregate = this.waitForAggregate;
    window.rememberScrollPosition = this.rememberScrollPosition;
    window.playerLink = this.playerLink;
    window.updateRoot = this.updateState;
    window.computeValue = this.computeValue;
    window.openChat = this.openChat;
  }
  componentDidMount() {
    /* Set user for sentry */
    Sentry.configureScope(scope => {
      scope.setExtra('lastKnowAppuser', cookiesManager?.getCookie(isEnvProd ? 'LAST_KNOWN_APPUSER' : `LAST_KNOWN_APPUSER_${process.env.NEXT_PUBLIC_APP_ENV}`));
      scope.setTag('isImpersonated', checkRefreshTokenImpersonate());
      scope.setUser({
        id: _.get(this.state, 'appuser.id'),
        username: _.get(this.state, 'appuser.profile.names.both'),
        email: _.get(this.state, 'appuser.account.email')
      });
    });

    /* Setting a cookie with the name LAST_KNOWN_APPUSER_{VAR} depending on the {VAR} environment. */
    this.state.appuser && cookiesManager.setCookie(`LAST_KNOWN_APPUSER${isEnvProd ? '' : `_${process.env.NEXT_PUBLIC_APP_ENV}`}`, this.state.appuser.get('id'), 99999, this.state.whitelabel);
    window.addEventListener('resize', throttle(() => {
      this.updateState(draft => {
        const probe = {
          mobile: window.innerWidth <= this.PROBE_LIMITS.sm,
          tablet: window.innerWidth > this.PROBE_LIMITS.sm && window.innerWidth <= this.PROBE_LIMITS.md,
          desktop: window.innerWidth > this.PROBE_LIMITS.md,
          // these are quite useful for optimized pure components to update even if their props are not changed (assuming they're context-aware)
          width: window.innerWidth,
          height: window.innerHeight
        };
        if (!isEqual(probe, draft.probe)) draft.probe = probe;
      });
    }, 100));

    // Attach to window to test marketing events in other envs than prod
    window.loadHubspotModule = this.loadHubspotModule;

    // WebSocket
    if (isEnvTest || !window.location.href.includes('connect')) {
      if (window.ws && !this.props.router.route.includes('connect')) {
        window.ws.on('connect', () => {
          window.wsOn = true;
          console.log('WebSocket: %copen', 'font-weight: bold; color: limegreen;');
          if (window.ws) window.ws.on('event_bus_dispatch', data => {
            data = JSON.parse(data);
            document.dispatchEvent(new CustomEvent(data.name, {
              detail: data
            }));
          });
          if (window.ws) window.ws.on('visit', data => {
            const here = get(data, 'tab') === get(window, 'tab');
            const init = !get(this.state.visitors, 'checked');
            this.updateState(draft => {
              if (init) return;
              draft.visitors.people.push({
                user: get(data, 'user'),
                at: get(data, 'at'),
                tab: get(data, 'tab')
              });
              draft.visitors.people = sortBy(draft.visitors.people, 'at');
              draft.visitors.people = filter(get(draft, 'visitors.people') || [], visitor => get(visitor, 'user.id') !== get(this.state.appuser, 'id') || get(visitor, 'tab') !== get(window, 'tab'));
            }, () => {
              const me = get(filter(get(this, 'state.visitors.people') || [], visitor => get(visitor, 'user.id') === get(this.state.appuser, 'id') && get(visitor, 'tab') === get(window, 'tab')), '0');
              const at = here ? get(data, 'at') : get(me, 'at');
              const routing = here ? data.routing : this.props.router.asPath;
              if (window.ws && routing === this.props.router.asPath) ws.emit('stance', {
                routing,
                at,
                to: get(data, 'tab')
              }); // when Bob visits a page, he emits 'visit', and other users respond with 'stance' for Bob to know who is also on that page
            });
          });
          if (window.ws) window.ws.on('stance', data => {
            this.updateState(draft => {
              if (`${get(data, 'routing.uriSchemeAndHttpHost')}${get(data, 'routing.uriPath')}` === `${get(draft, 'routing.uriSchemeAndHttpHost')}${get(draft, 'routing.uriPath')}`) {
                draft.visitors.people.push({
                  user: get(data, 'user'),
                  at: get(data, 'at'),
                  tab: get(data, 'tab')
                });
                draft.visitors.people = sortBy(draft.visitors.people, 'at');
              }
              draft.visitors.people = filter(get(draft, 'visitors.people') || [], visitor => get(visitor, 'user.id') !== get(this.state.appuser, 'id') || get(visitor, 'tab') !== get(window, 'tab'));
            }, () => {
              // We wait a bit before telling ourselves we checked visitors, because 'stance' responses are not synchronized
              if (this.visitorsCheckDebounce) clearTimeout(this.visitorsCheckDebounce);
              this.visitorsCheckDebounce = setTimeout(() => {
                this.updateState(draft => {
                  draft.visitors.checked = true;
                });
              }, 1000);
            });
          });
          if (window.ws) window.ws.on('quit', data => {
            this.updateState(draft => {
              draft.visitors.people = filter(get(draft, 'visitors.people') || [], visitor => get(visitor, 'user.id') !== data.userId);
            });
          });
          if (window.ws) window.ws.emit('visit', {
            routing: this.props.router.asPath,
            at: Date.now()
          });
        });
      }
      if (window.ws && !this.props.router.route.includes('connect')) window.ws.on('disconnect', () => {
        window.wsOn = false;

        // Reinit some ws params
        this.updateState(draft => {
          draft.visitors.people = [];
          draft.visitors.checked = false;
        });
        console.log('WebSocket: %cclosed', 'font-weight: bold; color: red;');
      });
      if (window.ws) window.ws.on('hr_change', data => {
        data = JSON.parse(data);
        let doJs = !!data.path.match(/\.js$/);
        let doCss = !!data.path.match(/\.css$/);
        if (get(root, 'state.whitelabel')) window.location.reload();
      });
    }

    // service worker
    // Listen the "add to home screen" event and show the dialog on mobile
    window.addEventListener('beforeinstallprompt', ev => {
      if (ev) ev.preventDefault();
    });
    if (!get(this, 'state.whitelabel') && !this.props.router?.route.includes('/back')) window.swBoot();

    // Retrive scroll position
    if (this.state.mode !== 'demo') this.retrieveScrollPosition();

    // invisible move to trigger what's needed
    epsilonScroll();

    // scroll to anchor (query param: _anchor);
    let anchor = this.props.router.query._anchor;
    if (anchor) {
      anchor = document.getElementById(anchor);
      if (anchor) zenscroll.center(anchor, 0);
    }
    const isImpersonated = checkRefreshTokenImpersonate();

    // Check if the user is still connected
    // If not, redirect to the login page
    // Usefull when the user is connected on another tab
    if (!window.sdkMode) {
      this.intervalImpersonate = setInterval(async () => {
        await this.getImpersonate(isImpersonated);
      }, 5000);
    }
    const iFrameDetection = window && window.self !== window.parent;
    if (!iFrameDetection && !this.props.router.route.includes('/back') && get(this.state, 'mode') !== 'demo' && !isImpersonated && !window.sdkMode && isEnvProd) {
      // hubspot will check the browser href to detect the lang, so we prepare it...
      setTimeout(this.loadHubspotModule, 1000);
    }
  }
  componentWillUnmount() {
    if (this.intervalImpersonate) clearInterval(this.intervalImpersonate);
  }
  computeValue = (base, diff) => {
    if (!diff) return base;
    diff = diff[0] === '-' ? -1 * parseInt(diff.replace(/^-/, '')) : parseInt(diff);
    return base + diff;
  };
  gen = path => !window.origin.includes('danim.com') ? `${window.origin}/api/${path}` : `${process.env.NEXT_PUBLIC_API_URL}${path}`;
  getImpersonate = async isImpersonated => {
    const isCookieRefreshTrace = checkRefreshTokenTrace();
    if (!isCookieRefreshTrace && !isImpersonated) {
      await logout().catch(console.error);
    }
  };
  all = (promises, alterLoaderState = true) => {
    if (alterLoaderState) {}
    const promise = Promise.all(promises);
    promise.finally(() => {
      if (alterLoaderState) {}
    });
    return promise;
  };
  asset = path => `/${path}`;

  // That will solve weird historical issues related to https://stackoverflow.com/questions/50022326/amazon-s3-image-cors-issue
  // Do not mix up CSS and HTML types! The bug comes from here originally (img tags request the image
  // which is cached, and is not usable for CSS calls afterwards)
  // Also, we need to add a random parameter to avoid caching issues
  cors = (src, type = 'html') => {
    if (!src) return src;
    if (!src.match(/^blob/)) src = `${src}${src.includes('?') ? '&' : '?'}x${pascalCase(type)}Cors=e202bb64`;
    return src;
  };
  img = (path, filter = null) => {
    if (!path) return null;
    if (!filter || process.env.NEXT_PUBLIC_IMAGES_OPTIMIZATION === '0') return getAbsoluteUrl(path);
    return getAbsoluteUrl(path);
  };
  rememberScrollPosition = (href = null) => {
    window.sessionStorage[`bridge.lastScroll.${href || window.location.href}.y`] = window.scrollY;
  };
  retrieveScrollPosition = (newPos = null) => {
    const lastScroll = newPos !== null ? newPos : window.sessionStorage[`bridge.lastScroll.${window.location.href}.y`];
    if (lastScroll) window.scrollTo(0, lastScroll);
  };
  handleMenuToggle = () => {
    this.updateState(draft => {
      draft.menu.open = !draft.menu.open;
    });
  };
  loadHubspotModule = () => {
    let script = document.createElement('script');
    script.onload = () => {
      const appuser = get(this.state, 'appuser') || {};
      const userId = get(appuser, 'id') || null;
      const email = get(appuser, 'account.email') || null;
      window._hsp = window._hsp || [];
      window._hsq = window._hsq || [];
      window._hsp.push(['addPrivacyConsentListener', function (consent) {
        window.dataLayer = window.dataLayer || [];
        window.gtag = function gtag() {
          window.dataLayer.push(arguments);
        };
        if (!window.sdkMode) {
          window.dataLayer.push({
            event: 'intercom.update',
            userId,
            email,
            user_hash: _.get(appuser, 'account.chatIdHash'),
            created_at: _.get(appuser, 'registeredAt'),
            name: _.get(appuser, 'profile.names.both'),
            phone: _.get(appuser, 'account.phone'),
            company: {
              id: _.get(appuser, 'organization.id'),
              name: _.get(appuser, 'organization.name'),
              size: _.get(appuser, 'organization.members.totalCount')
            }
          });
        }
        if (consent.categories.analytics) {
          window.dataLayer.push({
            event: 'analytics_granted'
          });
        }
        if (consent.categories.advertisement) {
          window.gtag('consent', 'update', {
            ad_user_data: 'granted',
            ad_personalization: 'granted',
            ad_storage: 'granted',
            analytics_storage: 'granted'
          });
          window.dataLayer.push({
            event: 'advertisement_granted'
          });
        }
      }]);

      // now remove the script tag, we don't need them anymore
      script.remove();
    };
    script.src = '//js-eu1.hs-scripts.com/25319976.js';
    document.head.appendChild(script);
  };
  openChat = () => window.Intercom ? window.Intercom('show') : window.open(`mailto:support@danim.com?subject=${encodeURI("Demande d'assistance")}`);
  playerLink = shortId => `${process.env.NEXT_PUBLIC_DNS_DEFAULT_SCHEME}://${process.env.NEXT_PUBLIC_DNS_LEVEL_3_PLAYER}${process.env.NEXT_PUBLIC_DNS_LEVEL_2}${process.env.NEXT_PUBLIC_DNS_LEVEL_1}${process.env.NEXT_PUBLIC_DNS_DEFAULT_PORT}/${shortId}`;
  processUrl = (url, nc = true) => {
    if (!url) return url;
    const hasQueryParameters = url.match(/\?/);
    if (nc) {
      if (hasQueryParameters) url += '&';else url += '?';
      url += `_nc=${new Date().getTime()}`;
    }
    if (hasQueryParameters) {
      const split = url.split('?');
      const base = split[0];
      const queryString = qs.parse(split[1]);
      url = `${base}?${qs.stringify(sortByKey(queryString))}`;
    }
    return url;
  };
  updateFlash = (id, flash) => {
    return toast.update(id, {
      ...flash,
      autoClose: flash?.closable ?? true,
      closeButton: flash?.closable ?? true,
      closeOnClick: flash?.closable ?? true,
      draggable: flash?.closable ?? true
    });
  };

  /**
   * It adds a flash message to the state of the container
   * @param flash - the flash object to add
   * @param [ttl=4000] - The time to live of the flash message. If set to -1, the flash message will not
   * be removed automatically.
   * @returns A function that takes in three parameters: flash, ttl, and container.
   */
  addFlash = (flash, ttl = 4000) => {
    if (!flash.message) flash.message = tr(`front.flash.${flash.type === 'success' ? 'success' : 'error'}`);
    const CustomFlash = ({
      closeToast,
      callback
    }) => {
      const handleGo = e => {
        e.preventDefault();
        callback();
        closeToast();
      };
      return <Stack direction={flash.animation ? 'column' : 'row'} flexWrap='wrap' justifyContent='space-between' alignItems='center' gap={flash.animation ? '1.2rem' : '0.6rem'} sx={{
        textAlign: flash.animation ? 'center' : 'left'
      }}>
                    {flash?.animation && <Lottie className='-lottieFlash' options={{
          autoplay: true,
          animationData: flash.animation,
          renderer: 'svg',
          loop: true
        }} height={100} width={120} />}
                    <div>{flash.message ? flash.message : flash?.type === 'success' ? 'Succès' : 'Erreur'}</div>
                    {_.get(flash, 'type') ? <Button variant='contained' color='inherit' onClick={handleGo}>
                            {flash?.goText || 'Valider'}
                        </Button> : <Button variant='gradient' color='primary' onClick={handleGo}>
                            {flash?.goText || 'Valider'}
                        </Button>}
                </Stack>;
    };
    if (flash?.goCallback) {
      return toast(<CustomFlash callback={flash.goCallback} />, {
        toastId: flash?.id || uniqid(),
        autoClose: flash?.autoClose ?? ttl,
        type: flash?.type || null,
        theme: flash?.type ? 'colored' : 'light',
        closeButton: flash?.closable ?? true,
        closeOnClick: flash?.closable ?? true,
        ...(flash.progress && {
          progress: flash.progress
        }),
        draggable: flash?.closable ?? true
      });
    }
    if (flash?.promise) {
      return toast.promise(flash.promise, {
        pending: flash?.pending || 'En cours...',
        success: flash?.success || 'Succès',
        error: flash?.error || 'Erreur'
      });
    }
    return toast(flash.message ? flash.message : flash?.type === 'success' ? 'Succès' : 'Erreur', {
      toastId: flash?.id || uniqid(),
      autoClose: flash?.autoClose ?? ttl,
      type: flash?.type || 'info',
      closeButton: flash?.closable ?? true,
      closeOnClick: flash?.closable ?? true,
      ...(flash.progress && {
        progress: flash.progress
      }),
      draggable: flash?.closable ?? true
    });
  };

  /**
   * @param id E.g. bfd06d7c-d8d2-44ec-a7eb-171fe32de267, {email: 'an@ema.il'}
   * @param domain
   * @param maxTries
   * @param secDelayBetweenTries
   * @param visibly
   * @param queryAggregateBody
   * @param validator
   * @returns {Promise<void>}
   */
  waitForAggregate = async ({
    id,
    domain,
    alias = null,
    maxTries = 30,
    secDelayBetweenTries = 1,
    queryAggregateBody = '',
    validator = null,
    options = null
  }) => {
    // domain = camelCase(domain);

    if (!id) return null;
    if (validator === null) validator = aggregate => !!get(aggregate, 'id');
    let aggregateId = null;
    const aliasDomain = alias ? `${alias}: ${domain}` : domain;
    while (!aggregateId && maxTries-- > 0) {
      await apiRequest(`
                query { 
                    ${aliasDomain} (${isString(id) ? `id: "${id}"` : `${Object.keys(id)[0]}: "${Object.values(id)[0]}"`}) { 
                        id
                        ${queryAggregateBody} 
                    }
                }
            `, options).then(data => {
        const aggregate = get(data, `data.${alias || domain}`);
        if (validator(aggregate)) aggregateId = get(aggregate, 'id');
      }).catch(e => {
        Sentry.captureException(e);
      });
      await sleep(secDelayBetweenTries);
    }

    // if reached max tries and still no aggregateId, return null
    return aggregateId;
  };
  render = () => {
    const {
      children,
      router
    } = this.props;
    const {
      whitelabel,
      appuser,
      workspace
    } = this.state;
    const ContentComponent = get(this.state, 'modal.component') || null;
    return <div className='root' id='root' ref={() => {
      window.modalRoot = this.state.modal;
    }}>
                <HeaderLayout tr={tr} appuser={appuser} whitelabel={whitelabel} />
                <div className='draggable-root' />
                <ModalRoot key={this.state.modal.key} appuser={appuser} router={router} whitelabel={whitelabel} customAttributes={{
        root: {
          className: ContentComponent ? `${ContentComponent.cssPrefix ? `${ContentComponent.cssPrefix}:` : ''}${ContentComponent.domain}:modal /${ContentComponent.type}` : ''
        }
      }} title={this.state.modal.title(this.state)} open={this.state.modal.open} closable={this.state.modal.closable} loading={this.state.modal.loading} blockClickOutside={false}>
                    {ContentComponent !== null && <ContentComponent key={this.state.modal.key} {...this.state.modal.propsFactory(this.state)} />}
                </ModalRoot>
                <FlashBox flashes={this.state.flashes} onClick={this.handleFlashClick} customAttributes={{
        root: {
          className: clsx(this.state.mode ? `/mode/${this.state.mode}` : null)
        }
      }} />
                {!window.areWeUnderDanimHost() && <ChatAlternative />}
                {appuser && (appuser?.isGrantedRole('EDITOR') || checkRefreshTokenImpersonate()) && window.areWeUnderDanimHost() === true && <ImpersonatorStrip />}
                <div className={clsx('-core', sdkMode && '/sdkMode', this.state.modal.open && this.state.probe.mobile && '/hidden', this.state.mode ? `/mode/${this.state.mode}` : null)}>
                    {appuser && !includes(['/logout'], router.route) && !window.sdkMode && <Header menuOpen={this.state.menu.open} onToggle={this.handleMenuToggle} appuser={appuser} workspace={workspace} whitelabel={whitelabel} router={router} />}
                    {includes(['/organization', '/user', '/video/library', '/workspace', '/'], router.route) && !includes(['/organization/billing', 'organization/team'], router.route) && appuser?.isUnderTrial() && !appuser?.isUnderExternalBilling() && <TrialBanner appuser={appuser} />}
                    {children}
                </div>
            </div>;
  };
}
export default withMyHook(Root);