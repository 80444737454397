const isEnvLocal: boolean = process.env.NEXT_PUBLIC_APP_ENV === 'local';

const isEnvDev: boolean = process.env.NEXT_PUBLIC_APP_ENV === 'dev';

const isEnvTest: boolean = process.env.NEXT_PUBLIC_APP_ENV === 'test' || isEnvDev || isEnvLocal;

const isEnvStage: boolean = process.env.NEXT_PUBLIC_APP_ENV === 'stage';

const isEnvProd: boolean = process.env.NEXT_PUBLIC_APP_ENV === 'prod';

const isEnvProdLikeRemote: boolean = isEnvProd || isEnvDev || isEnvStage;

export { isEnvLocal, isEnvTest, isEnvStage, isEnvDev, isEnvProd, isEnvProdLikeRemote };
