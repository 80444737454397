import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import { deburr, get, map } from 'lodash';
import stringToColour from '@utils/lodash/stringToColour';
import Workspace from '@utils/Workspace';
type Props = {
  isOpen: boolean;
  workspace: any;
  appuser: any;
  handleWorkspacesToggle: () => void;
  newNotificationsCount: (boolean) => number;
};
const WorkspaceSelector = (props: Props) => {
  const {
    isOpen,
    workspace,
    appuser,
    handleWorkspacesToggle,
    newNotificationsCount
  } = props;
  const workspaces = appuser ? map(appuser.isOrganizationDirector() ? get(appuser, 'organization.workspaces.edges') || [] : get(appuser, 'workspaces.edges') || [], edge => edge.node) : [];
  const handleWorkspaceClick = async (ev = null, workspaceId = null) => {
    if (ev) ev.preventDefault() & ev.stopPropagation();
    if (workspace && workspace.id === workspaceId || !workspace && !workspaceId) return;
    window.sessionStorage.clear();
    Workspace.setSelectedWorkspaceId(workspaceId);
    window.location.reload();
  };
  return <Dropdown nav inNavbar className='-workspaceBox' isOpen={isOpen} toggle={handleWorkspacesToggle}>
            <DropdownToggle nav caret className='-item /level1 /workspaceBox -toggle' data-test-id='toggleWorkspace'>
                {workspace ? <React.Fragment>
                        <span className='-logo' style={get(workspace, 'logo.src') ? {
          backgroundPosition: 'center center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat',
          backgroundImage: `url(${cors(get(workspace, 'logo.src'), 'css')})`
        } : {
          backgroundColor: stringToColour(workspace.id, 0xb4)
        }}>
                            {!get(workspace, 'logo.src') && deburr(workspace.name || get(appuser, 'profile.names.both') || 'Anonymous').replace(/\W+/, '').substring(0, 2)}
                            {workspace !== null && newNotificationsCount(true) > 0 && <span className='-notificationsDot'>
                                    <span />
                                </span>}
                        </span>
                        {workspace.name || get(appuser, 'profile.names.both') || 'Anonymous'}
                    </React.Fragment> : <React.Fragment>
                        <span className='-perspective'>
                            <span className='-placeholder' />
                            <span className='-placeholder' />
                            <span className='-placeholder' />
                            {workspace === null && newNotificationsCount(true) > 0 && <span className='-notificationsDot'>
                                    <span />
                                </span>}
                        </span>
                        <span>{tr('front.menu.workspace_box.all')}</span>
                    </React.Fragment>}
            </DropdownToggle>
            <DropdownMenu left='true' className='-menu'>
                {!!appuser && appuser.isOrganizationDirector() === true && workspace !== null && get(appuser, 'organization.workspaces.edges.length') > 1 && <DropdownItem onClick={handleWorkspaceClick} className='-item'>
                            <div className='-shell'>
                                <span className='-perspective'>
                                    <span className='-placeholder' />
                                    <span className='-placeholder' />
                                    <span className='-placeholder' />
                                    {appuser.howManyNewNotificationsForAllWorkspaces() > 0 && <span className='-notificationsCount'>{appuser.howManyNewNotificationsForAllWorkspaces()}</span>}
                                </span>
                                <strong>{tr('front.menu.workspace_box.all')}</strong>
                            </div>
                        </DropdownItem>}
                {workspaces.map(_workspace => {
        if (!_workspace || _workspace.id === workspace?.id) return null;
        const newNotificationsCount = appuser.howManyNewNotificationsForWorkspace(_workspace.id);
        return <DropdownItem key={_workspace.id} data-workspace-id={_workspace.id} onClick={event => handleWorkspaceClick(event, _workspace.id)} className='-item'>
                            <div className='-shell'>
                                <span className='-logo' style={get(_workspace, 'logo.src') ? {
              backgroundPosition: 'center center',
              backgroundSize: 'cover',
              backgroundRepeat: 'no-repeat',
              backgroundImage: `url(${cors(get(_workspace, 'logo.src'), 'css')})`
            } : {
              backgroundColor: stringToColour(_workspace.id, 0xb4)
            }}>
                                    {!get(_workspace, 'logo.src') && deburr(_workspace.name || get(appuser, 'profile.names.both') || 'Anonymous').replace(/\W+/, '').substring(0, 2)}
                                    {newNotificationsCount > 0 && <span className='-notificationsCount'>{newNotificationsCount}</span>}
                                </span>{' '}
                                {_workspace.name || get(appuser, 'profile.names.both') || 'Anonymous'}
                            </div>
                        </DropdownItem>;
      })}
            </DropdownMenu>
        </Dropdown>;
};
export default WorkspaceSelector;