import __Base from '@components/Base';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import { get, defaultTo } from 'lodash';
class WaitingAnimation extends __Base {
  render = () => {
    const {
      color,
      customAttributes,
      discreet
    } = this.props;
    return <span data-attr='root' {...get(customAttributes, 'root', {})} className={clsx('animation', discreet && '/discreet', get(customAttributes, 'root.className'))}>
                {[1, 2, 3].map(n => <span key={n} className='-dot' style={{
        backgroundColor: defaultTo(color, 'hsl(var(--color-1-h), var(--color-1-s), calc(var(--color-1-dark) * 100% + var(--color-1-light) * 20%))')
      }} />)}
            </span>;
  };
}
WaitingAnimation.defaultProps = {
  color: null,
  customAttributes: {},
  discreet: false
};
WaitingAnimation.propTypes = {
  // optional
  color: PropTypes.string,
  customAttributes: PropTypes.object,
  discreet: PropTypes.bool // more little dots
};
export default WaitingAnimation;