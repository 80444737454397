import uniqid from '@utils/lodash/uniqid';

if (typeof window !== 'undefined') {
    _.set(window, 'gWatch.input', instance =>
        getScript(
            `https://maps.googleapis.com/maps/api/js?key=${
                process.env.NEXT_PUBLIC_APP_ENV !== 'prod' && process.env.NEXT_PUBLIC_APP_ENV !== 'stage'
                    ? process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY_DEV
                    : process.env.NEXT_PUBLIC_GOOGLE_PLACES_API_KEY
            }&libraries=places`,
            () => {
                const id = `google_check_up_interval:${uniqid()}`;
                window[id] = setInterval(() => {
                    if (window.google) {
                        clearInterval(window[id]);
                        instance.setup();
                    }
                }, 200);
            }
        )
    );
}
