export default function FormControlLabel(theme) {
    return {
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    userSelect: 'none'
                },
                labelPlacementStart: {
                    marginLeft: 0,
                    justifyContent: 'flex-end'
                }
            }
        }
    };
}
