import React, { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { CssBaseline } from '@mui/material';
import palette from '@theme/palette';
import typography from '@theme/typography';
import shadows from '@theme/shadows';
import customShadows from '@theme/custom-shadows';
import ComponentsOverrides from '@theme/overrides';
import { createTheme } from '@mui/material/styles';
import MUIThemeProvider from '@mui/system/ThemeProvider/ThemeProvider';
import GlobalStyles from './GlobalStyles';
const ThemeContext = createContext({});
export const useMyTheme = () => useContext(ThemeContext);
export default function ThemeProvider({
  children
}) {
  const defaultTheme = useMemo(() => {
    const initTheme = createTheme({
      palette: palette(),
      typography,
      shape: {
        borderRadius: 6
      },
      shadows: shadows(),
      customShadows: customShadows(),
      zIndex: {
        absoluteDiv: 800
      }
    });
    initTheme.components = ComponentsOverrides(initTheme);
    return initTheme;
  }, []);
  const [myTheme, setMyTheme] = useState<any>();
  const [theme, setTheme] = useState(defaultTheme);
  useEffect(() => {
    if (!myTheme) return;
    myTheme.components = ComponentsOverrides(myTheme);
    setTheme(myTheme);
  }, [myTheme]);
  const contextTheme = useMemo(() => ({
    theme,
    setMyTheme
  }), [theme, setMyTheme]);
  return <ThemeContext.Provider value={contextTheme}>
            <MUIThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalStyles />
                {children}
            </MUIThemeProvider>
        </ThemeContext.Provider>;
}