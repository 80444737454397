export default function Typography(theme) {
    return {
        MuiTypography: {
            styleOverrides: {
                root: {
                    a: {
                        color: theme.palette.primary.main
                    },
                    'a:hover': {
                        color: theme.palette.primary.main,
                        textDecoration: 'none'
                    }
                },
                paragraph: {
                    marginBottom: theme.spacing(2)
                },
                gutterBottom: {
                    marginBottom: theme.spacing(1)
                }
            }
        }
    };
}
