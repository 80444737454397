import * as _ from 'lodash';

if (typeof window !== 'undefined') {
    window.getScript = (uri, successCallback = () => null, errorCallback = () => null) => {
        if (!uri) throw new Error('Missing URI');
        else if (_.get(window, `gottenScripts.${uri}`, null)) {
            successCallback();
            return;
        } // if already loaded, do the callback and stop there
        const head = document.head || document.head.getElementsByTagName('head')[0];
        const element = document.createElement('script');
        element.type = 'text/javascript';
        element.onerror = errorCallback;
        element.onload = successCallback;
        element.strategy = 'worker';
        head.appendChild(element);
        element.src = uri;
        _.set(window, `gottenScripts.${uri}`, true);
    };
}
