import moment from 'moment/moment';
import isInterval from '@utils/lodash/isInterval';

/**
 * @param interval
 * @param format May be: "table" (detailed array of objects), or "timestamp", or "stringified"
 *               If format === 'timestamp', it returns the number of seconds in the interval, that's all.
 * @returns {[{unit: string, value: number},{unit: string, value: number},{unit: string, value: number},{unit: string, value: number},{unit: string, value: number},null]|number|null|moment.Moment}
 */
export default function parseInterval(interval, format = 'table') {
    if (!isInterval(interval)) return null;

    const matches = interval?.match(/P(\d+)Y(\d+)M(\d+)DT(\d+)H(\d+)M(\d+)S/);
    const [, years, months, days, hours, minutes, seconds] = matches;
    if (format === 'table') {
        return [
            { value: parseInt(years, 10), unit: 'year' },
            { value: parseInt(months, 10), unit: 'month' },
            { value: parseInt(days, 10), unit: 'day' },
            { value: parseInt(hours, 10), unit: 'hour' },
            { value: parseInt(minutes, 10), unit: 'minute' },
            { value: parseInt(seconds, 10), unit: 'second' }
        ];
    }

    // Convert it to date with moment
    const date = moment('1970-01-01 00:00:00') // ts=0
        .add(years, 'years')
        .add(months, 'months')
        .add(days, 'days')
        .add(hours, 'hours')
        .add(minutes, 'minutes')
        .add(seconds, 'seconds');

    if (format === 'timestamp') return date.unix();

    return date.local().locale('fr'); // format === NULL or 'stringified'
}
