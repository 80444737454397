import * as _ from 'lodash';
import isJSON from 'validator/lib/isJSON';

export default function getInStorage(key, target = null, preset = undefined, forcePreset = false) {
    if (target === null) target = window.sessionStorage;
    if (_.isArray(target)) {
        let ans;

        _.each(target, t => {
            const tmp = getInStorage(key, t, preset, forcePreset);
            if (tmp !== undefined && ans === undefined) ans = tmp;
        });

        return ans;
    }

    let res = target[key];
    if ((res === undefined || forcePreset) && preset !== undefined) {
        target[key] = JSON.stringify(preset);
        return preset;
    }

    res = res !== undefined ? (isJSON(res) ? JSON.parse(res) : res.replace(/^"|"$/g, '')) : undefined;

    if (res === 'false') return false;
    if (res === 'true') return true;
    if (res === 'null') return null;

    return res;
}
