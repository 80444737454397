import isBasicObject from '@utils/lodash/isBasicObject';
import * as _ from 'lodash';
import moment from 'moment';
import computeDateFromInterval from '@utils/lodash/computeDateFromInterval';
import isInterval from '@utils/lodash/isInterval';
import Immutable from './immutable';

export default class User extends Immutable {
    /**
     * @param badge (either a badge object for highest accuracy: 'Is she manager on workspace xyz' - or a badge string: 'Is he sharer on any workspace')
     * @param count (if badge is a string, count is the minimum number of badges to have)
     * @returns {boolean}
     */
    hasOrganizationBadge(badge, count) {
        if (!badge || (isBasicObject(badge) && !badge.badge) || !_.get(this, 'profile.organizationBadges')) return false;

        return (
            _.filter(this.profile.organizationBadges, b => {
                if (!_.isString(badge))
                    return (
                        (b.badge === badge.badge &&
                            _.get(b, 'workspaceId', _.get(b, 'workspace.id', null)) === _.get(badge, 'workspaceId', null)) ||
                        _.get(badge, 'workspace_id', null)
                    );
                return b.badge === badge;
            }).length > count
        );
    }

    /**
     * @param role
     * @returns {boolean}
     */
    hasRole(role) {
        return _.indexOf(_.get(this, 'account.roles') || [], role) !== -1;
    }

    /**
     * @param badge (either a badge object for highest accuracy: 'Is she manager on workspace xyz' - or a badge string: 'Is he sharer on any workspace')
     * @returns {boolean}
     */
    isGrantedOrganizationBadge(badge) {
        if (this.isOrganizationDirector()) return true;

        let node = badge;
        if (!_.isString(badge)) node = badge.badge;

        for (; node; node = constants.organization.badge.tree[node]) {
            if (
                this.hasOrganizationBadge(
                    !_.isString(badge)
                        ? {
                              workspaceId: _.get(badge, 'workspaceId', null) || _.get(badge, 'workspace_id', null),
                              badge: node
                          }
                        : node,
                    0
                )
            )
                return true;
        }

        return false;
    }

    /**
     * @param role
     * @returns {boolean}
     */
    isGrantedRole(role) {
        for (let node = role; node; node = constants.user.role.tree[node]) if (this.hasRole(node)) return true;

        return false;
    }

    isSuperAdmin() {
        return this.isGrantedRole('SUPER_ADMIN');
    }

    isAdmin() {
        return this.isGrantedRole('ADMIN');
    }

    /**
     * @returns {boolean}
     */
    isAdvancedDirector() {
        return this.isOrganizationDirector() && _.get(this, 'organization.advancedMode') === true;
    }

    /**
     * @returns {boolean}
     */
    isOrganizationDirector() {
        if (!_.get(this, 'profile.organizationBadges')) return false;

        for (const userOrganizationBadge of this.profile.organizationBadges) if (userOrganizationBadge.badge === 'DIRECTOR') return true;

        return false;
    }

    /**
     * @param workspaceId
     * @returns {boolean}
     */
    isOrganizationManagerOfWorkspace(workspaceId) {
        if (!_.get(this, 'profile.organizationBadges')) return false;

        for (const userOrganizationBadge of this.profile.organizationBadges) {
            if (
                userOrganizationBadge.badge === 'MANAGER' &&
                _.get(userOrganizationBadge, 'workspaceId', _.get(userOrganizationBadge, 'workspace.id', null)) === workspaceId
            )
                return true;
        }

        return false;
    }

    doesOrganizationStillHaveUnassignedLicensesLeft() {
        const limit = _.get(this, 'organization.plan.traits.maxLicensedWithPeriodicCloudyMembersCount.value');
        const used = _.get(this, 'organization.plan.usedPeriodicCloudyLicensesCount');
        return limit === null || used < limit;
    }

    howManyDaysLeftInTrial(float = false) {
        let startAt = _.get(this, 'organization.plan.trialPeriod.startAt');
        if (_.isString(startAt)) startAt = moment(startAt)?.unix();

        let endAt = _.get(this, 'organization.plan.trialPeriod.endAt');
        if (isInterval(endAt)) {
            if (startAt) endAt = computeDateFromInterval(endAt, startAt).unix();
            else endAt = null;
        } else {
            if (_.isString(endAt)) endAt = moment(endAt)?.unix();
        }

        if (!startAt && !endAt) return 0;

        let daysLeft = 0;

        if (startAt && !endAt) {
            daysLeft = Infinity;
        } else {
            const now = moment().unix();
            daysLeft = (endAt - now) / (24 * 60 * 60);
            if (daysLeft < 0) daysLeft = 0;
            else if (!float) daysLeft = _.round(daysLeft);
        }

        return daysLeft;
    }

    isUnderTrial() {
        const haveLicense = _.get(this, 'account.license') !== null;
        if (haveLicense) return false;
        const startAt = _.get(this, 'organization.plan.trialPeriod.startAt');

        if (startAt) {
            const trialHasStarted = moment().unix() >= moment(startAt)?.unix();
            if (!trialHasStarted) return false;
        }

        return !!this.howManyDaysLeftInTrial(true);
    }

    isUnderExternalBilling() {
        return !!_.get(this, 'organization.plan.externalBilling');
    }

    canMakePayments() {
        return this.isOrganizationDirector();
    }

    /**
     * That does NOT necessarily mean that the first subscription payment has succeeded on the payment service.
     * That only means that the payment has been initiated, and it may still be pending (e.g. waiting for 3DS verification).
     * Nevertheless, that also means that the subscription has structurally been created, with expiry and billing period: this is not changeable on the payment service.
     * That's what we call "third initiation" ("third" means "not the client, not us, but the service")
     *
     * See hasOrganizationPlanThirdlyActiveDeal() for more guarantee.
     *
     * @returns {boolean}
     */
    hasOrganizationPlanThirdlyInitiatedDeal() {
        return !!_.get(this, 'organization.plan.deals.0.thirdSubscriptionId');
    }

    /**
     * Almost the same as hasOrganizationPlanThirdlyInitiatedDeal(), but with more guarantee:
     * At least one payment has been made successfully, and the payment cycle is launched.
     *
     * @returns {boolean}
     */
    hasOrganizationPlanThirdlyActiveDeal() {
        return (
            this.hasOrganizationPlanThirdlyInitiatedDeal() &&
            !!_.get(this, 'organization.plan.deals.0.thirdSubscriptionLastSuccessfulPaymentAt')
        );
    }
}
