if (typeof window !== 'undefined') {
    window.cookiesManager = {
        /**
         * @param cname
         * @param cvalue
         * @param exdays
         * @param whitelabel
         */
        setCookie: (cname, cvalue, exdays, whitelabel) => {
            const domain = cookiesManager.domain(whitelabel);
            const d = new Date();
            d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
            const expires = `expires=${d.toUTCString()}`;
            document.cookie = `${cname}=${cvalue};${expires};path=/;${domain ? `domain=${domain};` : ''}`;
        },
        getCookie: (cname, del = false) => {
            const name = `${cname}=`;
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1);
                if (c.indexOf(name) === 0) {
                    if (del) cookiesManager.delCookie(cname);
                    return c.substring(name.length, c.length);
                }
            }
            return '';
        },
        getCookies: () => document.cookie,
        delCookie: cname => {
            if (window.areWeUnderDanimHost())
                document.cookie = `${cname}=; Path=/; Domain=.${process.env.NEXT_PUBLIC_DNS_LEVEL_2}${process.env.NEXT_PUBLIC_DNS_LEVEL_1}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
            else document.cookie = `${cname}=; Path=/; Domain=.${window.location.host}; Expires=Thu, 01 Jan 1970 00:00:01 GMT;`;
        },
        domain: whitelabel => {
            return window.areWeUnderDanimHost()
                ? `${process.env.NEXT_PUBLIC_DNS_LEVEL_2}${process.env.NEXT_PUBLIC_DNS_LEVEL_1}`
                : whitelabel?.domain?.name;
        }
    };
}
