import * as _ from 'lodash';

export default class Immutable {
    constructor(data) {
        _.merge(this, data);

        this._data = data;
        return new Proxy(this, {
            set: name => {
                throw Error(`Cannot set ${name}, this is an Immutable.`);
            }
        });
    }

    clone() {
        return new (Object.getPrototypeOf(this).constructor)(_.cloneDeep(this._data));
    }

    set(path, value) {
        return new (Object.getPrototypeOf(this).constructor)(_.set(_.cloneDeep(this._data), path, value));
    }

    get(path) {
        return _.get(this._data, path);
    }

    decrement(path, amount = 1) {
        return this.set(path, _.get(this, path) - amount);
    }

    increment(path, amount = 1) {
        return this.set(path, _.get(this, path) + amount);
    }

    push(path, value) {
        const collection = _.get(this, path);
        collection.push(value);
        return this.set(path, collection);
    }
}
