function queryWorkspace(id: string, privacyType: string = 'NOT_BROADLY', needCategories: boolean = false, inLibrary: string[] = null) {
    return id
        ? `{
            bridgeWorkspace: workspace (id: "${id}") {
                id
                name
                badgedUserIds
                logo {
                  src
                }
                ${
                    needCategories
                        ? `
                accessibleCategories (first: -1 viaLibraryToo: true sort: "displayRank:asc") {
                    totalCount
                        edges {
                            node {
                                id
                                publicName
                                description
                                displayRank
                                templatesOrganization: templates (
                                    private: ${privacyType}
                                    inWorkspace: "${id}"
                                ) { totalCount }
                                templatesLibrary: templates (
                                    private: ${privacyType}
                                    ${inLibrary ? `inLibrary: "${inLibrary.join(' | ')}"` : ''}
                                ) { totalCount }
                            }
                        }
                    }
                `
                        : ''
                }
            }
        }
        `
        : null;
}

const queryWorkspaceMock = () => 'Workspace';

const queryWorkspaceApi = process.env.NEXT_PUBLIC_MOCKS === 'true' ? queryWorkspaceMock : queryWorkspace;
export default queryWorkspaceApi;
