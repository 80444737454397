import * as _ from 'lodash';

export default function sortByKey(obj) {
    return _.zipObject(
        Object.keys(obj).sort(),
        Object.keys(obj)
            .sort()
            .map(key => obj[key])
    );
}
