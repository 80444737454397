import { faRobot, faBuilding, faDotCircle, faCodeBranch, faUser, faVideo, faThLarge, faGlasses, faFolderTree } from '@fortawesome/free-solid-svg-icons';
type ConfigItem = {
  caption: string;
  route: string;
  className: string;
  icon: any;
};
type Config = {
  [key: string]: ConfigItem;
};
const getBackOfficeMenuConfig = (): Config => {
  return {
    bot: {
      caption: '§Bots & licenses',
      route: '/bot/list',
      className: '',
      icon: faRobot
    },
    organization: {
      caption: '§Organizations',
      route: '/organization/list',
      className: '',
      icon: faBuilding
    },
    template_source: {
      caption: '§Template sources',
      route: '/template-source/list',
      className: '',
      icon: faDotCircle
    },
    template: {
      caption: '§Templates',
      route: '/template/list',
      className: '',
      icon: faCodeBranch
    },
    user: {
      caption: '§Users',
      route: '/user/list',
      className: '',
      icon: faUser
    },
    video: {
      caption: '§Videos',
      route: '/video/list',
      className: '',
      icon: faVideo
    },
    workspace: {
      caption: '§Workspaces',
      route: '/workspace/list',
      className: '',
      icon: faThLarge
    },
    library: {
      caption: '§Libraries',
      route: '/library/list',
      className: '',
      icon: faGlasses
    },
    category: {
      caption: '§Categories',
      route: '/category/list',
      className: '',
      icon: faFolderTree
    }
  };
};
export default getBackOfficeMenuConfig;