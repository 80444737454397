import * as _ from 'lodash';

export default function camelCase(data) {
    if (_.isString(data)) return _.camelCase(data);

    if (_.isArray(data)) return _.map(data, e => {});
    if (!_.isObject(data)) return data;

    const ans = {};

    _.forOwn(data, (value, key) => {
        const camelCaseKey = _.camelCase(key);
        ans[camelCaseKey] = _.isArray(value) || _.isObject(value) ? camelCase(value) : value;
    });

    return ans;
}
