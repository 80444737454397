import Button from './Button';
import Typography from './Typography';
import Tooltip from './Tooltip';
import IconButton from './IconButton';
import SvgIcon from './SvgIcon';
import FormControlLabel from './FormControlLabel';
import InputLabel from './InputLabel';
import Fab from './Fab';

export default function ComponentsOverrides(theme) {
    return Object.assign(
        Button(theme),
        Typography(theme),
        Tooltip(theme),
        IconButton(theme),
        SvgIcon(theme),
        FormControlLabel(theme),
        InputLabel(theme),
        // Input(theme),
        Fab(theme)
    );
}
