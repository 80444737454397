import * as _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import xxh from 'xxhashjs';

if (typeof window !== 'undefined') {
    // Returns total pretax amounts of a transaction based on the quantity of licences and the client deal, without coupon and with coupon
    window.computeLicensePriceBasedOnThirdInfo = (infoOnPrices, infoOnPacks, deal) => {
        let preTaxAmountWithoutCoupon = 0;
        let preTaxAmountWithCoupon = 0;
        let currentPrice = 0;

        if (infoOnPrices && infoOnPrices.length > 0) {
            currentPrice = infoOnPrices.find(p => p.id === _.get(deal, 'thirdPriceId'))?.unitAmount || 0;
        }

        preTaxAmountWithoutCoupon = currentPrice / 100;

        let coupon = _.get(deal, 'coupon');
        if (!coupon)
            coupon = {
                amount: 0,
                percentage: true
            };
        preTaxAmountWithCoupon = preTaxAmountWithoutCoupon;
        if (coupon.amount) {
            if (coupon.percentage) preTaxAmountWithCoupon -= (coupon.amount * preTaxAmountWithCoupon) / 100;
            else preTaxAmountWithCoupon -= coupon.amount;
        }
        preTaxAmountWithCoupon = Math.max(0, _.round(preTaxAmountWithCoupon, 2));

        return { preTaxAmountWithoutCoupon, preTaxAmountWithCoupon };
    };

    // memo
    window.memo = (fn, deps = [], subId = null, id = null) => {
        let func;

        if (!_.isFunction(fn)) func = () => fn;
        else func = fn;

        id = id || `memo.fn.${xxh.h32(`${func.toString()}/${window.backtrace()}`, 0xabcd).toString(16)}/${subId}`;

        if (window._memoCache === undefined) window._memoCache = {};

        let reCompute = false;
        if (window._memoCache[id] !== undefined) {
            _.each(deps, (dep, idx) => {
                if (dep !== window._memoCache[id][1][idx]) {
                    reCompute = true;
                    return false;
                }
            });
        }

        if (reCompute || window._memoCache[id] === undefined) window._memoCache[id] = [func(...deps), deps];

        return window._memoCache[id][0];
    };

    // regex
    window.REGEX_PASSWORD = /^(?=.{8,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d).*$/; // at least one uppercase, one lowercase, one digit, and a minimum of 8-char long
    window.DATE_FORMAT = 'YYYY/MM/DD';
    window.DATETIME_FORMAT = 'YYYY/MM/DD HH:mm';
    window.GENESIS_DATE = '1970-01-01 00:00:00';

    window.sleep = sec => new Promise(resolve => setTimeout(resolve, sec * 1000));
    window.msleep = msec => new Promise(resolve => setTimeout(resolve, msec));

    // utils
    window.epsilonScroll = (element = null, x = false) => {
        const target = element || window;
        const scrollTop = target[element ? 'scrollTop' : 'scrollY'];
        const scrollLeft = target[element ? 'scrollLeft' : 'scrollX'];
        target.scrollTo(scrollLeft + (x ? 1 : 0), scrollTop + 1);
        target.scrollTo(scrollLeft - (x ? 1 : 0), scrollTop - 1);
    };

    /**
     * Returns true if succeeded, false otherwise
     *
     * @param str
     * @returns {boolean}
     */
    window.copyToClipboard = str => {
        try {
            const el = document.createElement('textarea');
            el.value = str;
            document.body.appendChild(el);
            el.select();
            document.execCommand('copy');
            document.body.removeChild(el);
            return true;
        } catch (e) {
            return false;
        }
    };

    window.onbeforeunload = e => {
        if (window.rememberScrollPosition) window.rememberScrollPosition();
    };

    window.swBoot = () => {
        window.swClear = () => {
            navigator.serviceWorker.getRegistrations().then(registrations => {
                for (const registration of registrations) registration.unregister();
            });
        };
        if ('serviceWorker' in navigator) {
            navigator.serviceWorker
                .register('/service-worker.js', { scope: '/' })
                .then(() => navigator.serviceWorker.ready)
                .then(registration => {
                    console.log('SW: %cregistered', 'font-weight: bold; color: limegreen;');

                    return registration;
                });
        }
    };

    console.log(`App version: ${(process.env.NEXT_PUBLIC_APP_VERSION || '?').substring(0, 9)}`);

    // speed guide: https://kenstechtips.com/index.php/download-speeds-2g-3g-and-4g-actual-meaning#2G_3G_4G_5G_Download_Speeds
    window.downloadSpeed = null;
    let startTime;
    let endTime;
    let fileSize;
    let xhr = new XMLHttpRequest();
    xhr.onreadystatechange = function () {
        if (xhr.readyState === 4 && xhr.status === 200) {
            endTime = window.performance.now();

            fileSize = xhr.responseText.length;

            window.downloadSpeed = (fileSize * 8) / (1000 * 1000) / ((endTime - startTime) / 1000); // Mbps
            console.log(`Connection download speed: %c${_.round(window.downloadSpeed, 2)} Mbps`, 'font-weight: bold;');
        }
    };
    startTime = window.performance.now();
    xhr.open('GET', `/bandwidth-test.jpg?_nc=${uuidv4()}`, true);
    xhr.send();

    // save last scroll position

    const scrollDebounce = _.debounce(() => {
        if (window.rememberScrollPosition) window.rememberScrollPosition();
    }, 500);

    window.addEventListener('scroll', scrollDebounce);

    window.tab = uuidv4();

    // Rendering optimizations. See https://web.dev/content-visibility/ and https://infrequently.org/2020/12/resize-resilient-deferred-rendering/
    window.wireContentVisibility = (cssSelector = '.wiredContentVisibilityGhost', force = false) => {
        if (!ResizeObserver) return;

        const prevArticles = window.lastWiredContentVisibilityArticles || [];
        const articles = document.querySelectorAll(cssSelector) || [];

        let performIt = true;

        if (!force) {
            if (
                // Compare articles treated during the last call to current ones
                prevArticles.length &&
                articles.length === prevArticles.length
            ) {
                let areArraysEqual = true;

                _.each(articles, nextArticle => {
                    let found = false;

                    _.each(prevArticles, prevArticle => {
                        if (nextArticle.isEqualNode(prevArticle)) {
                            found = true;
                            return false;
                        }
                    });

                    if (!found) {
                        // The arrays are not equal then...
                        areArraysEqual = false;
                        return false;
                    }
                });

                if (areArraysEqual) performIt = false;
            }
        }

        // That is nice to optimize and avoid useless wiring if possible, but why?
        // Because performing this wiring when not necessary can create little DOM scroll hops...
        if (performIt) {
            console.log(`${window.wiredContentVisibilityAtLeastOnce === true ? 'Rew' : 'W'}iring ResizeObserver for content visibility...`);

            if (window.contentVisibilityAnimationFrame1) {
                cancelAnimationFrame(window.contentVisibilityAnimationFrame1);
                window.contentVisibilityAnimationFrame1 = null;
            }
            if (window.contentVisibilityAnimationFrame2) {
                cancelAnimationFrame(window.contentVisibilityAnimationFrame2);
                window.contentVisibilityAnimationFrame2 = null;
            }
            if (window.contentVisibilityObserver1) {
                window.contentVisibilityObserver1.disconnect();
                window.contentVisibilityObserver1 = null;
            }
            if (window.contentVisibilityObserver2) {
                window.contentVisibilityObserver2.disconnect();
                window.contentVisibilityObserver2 = null;
            }

            let eqIsh = (a, b, fuzz = 2) => Math.abs(a - b) <= fuzz;

            let rectNotEQ = (a, b) => !eqIsh(a.width, b.width) || !eqIsh(a.height, b.height);

            // Keep a map of elements and the dimensions of
            // their place-holders, re-setting the element's
            // intrinsic size when we get updated measurements
            // from observers.
            let spaced = new WeakMap();

            // Only call this when known cheap, post layout
            let reserveSpace = (el, rect = el.getClientBoundingRect()) => {
                let old = spaced.get(el);
                // Set intrinsic size to prevent jumping on un-painting:
                //    https://drafts.csswg.org/css-sizing-4/#intrinsic-size-override
                if (!old || (rectNotEQ(old, rect) && el && el.attributeStyleMap)) {
                    spaced.set(el, rect);
                    try {
                        el.attributeStyleMap.set('content-visibility', 'auto');
                        el.attributeStyleMap.set('contain-intrinsic-size', `${rect.width}px ${rect.height}px`);
                    } catch (e) {}
                }
            };

            window.contentVisibilityObserver1 = new IntersectionObserver(
                (entries, o) => {
                    entries.forEach(entry => {
                        // We don't care if the element is intersecting or
                        // has been laid out as our page structure ensures
                        // they'll get the right width.
                        reserveSpace(entry.target, entry.boundingClientRect);
                    });
                },
                { rootMargin: '500px 0px 500px 0px' }
            );

            window.contentVisibilityObserver2 = new ResizeObserver((entries, o) => {
                entries.forEach(entry => {
                    reserveSpace(entry.target, entry.contentRect);
                });
            });

            if (articles.length && articles[0] && articles[0].attributeStyleMap) {
                articles.forEach(el => {
                    window.contentVisibilityObserver1.observe(el);
                    window.contentVisibilityObserver2.observe(el);
                });

                // Workaround for Chrome bug, part 2.
                //
                // Re-enable browser management of rendering for the
                // first article after the first paint. Double-rAF
                // to ensure we get called after a layout.
                window.contentVisibilityAnimationFrame1 = requestAnimationFrame(() => {
                    window.contentVisibilityAnimationFrame2 = requestAnimationFrame(() => {
                        articles[0].attributeStyleMap.set('content-visibility', 'auto');
                    });
                });
            }
        }

        window.wiredContentVisibilityAtLeastOnce = true;
        window.lastWiredContentVisibilityArticles = articles;
    };

    window.unwireContentVisibility = () => {
        if (
            !window.contentVisibilityAnimationFrame1 &&
            !window.contentVisibilityAnimationFrame2 &&
            !window.contentVisibilityObserver1 &&
            !window.contentVisibilityObserver2
        )
            return;

        console.log('Unwiring ResizeObserver for content visibility...');

        if (window.contentVisibilityAnimationFrame1) {
            cancelAnimationFrame(window.contentVisibilityAnimationFrame1);
            delete window.contentVisibilityAnimationFrame1;
        }
        if (window.contentVisibilityAnimationFrame2) {
            cancelAnimationFrame(window.contentVisibilityAnimationFrame2);
            delete window.contentVisibilityAnimationFrame2;
        }
        if (window.contentVisibilityObserver1) {
            window.contentVisibilityObserver1.disconnect();
            delete window.contentVisibilityObserver1;
        }
        if (window.contentVisibilityObserver2) {
            window.contentVisibilityObserver2.disconnect();
            delete window.contentVisibilityObserver2;
        }
    };

    if (process.env.NEXT_PUBLIC_APP_ENV !== 'test') {
        window.offlineSig = {
            mute: false,
            exec: {
                offline: {
                    always: {},
                    once: {}
                },
                online: {
                    always: {},
                    once: {}
                }
            }
        };

        window.addEventListener('offline', () => {
            console.log('Offline');

            if (window.offlineSig.exec.offline.mute) return;

            if (window.addFlash) {
                window.addFlash({
                    id: 'offlineWarning',
                    type: 'warning',
                    message: tr('front.lost_connection' || 'Lost internet connection'),
                    closable: false,
                    autoClose: false
                });
            }

            _.forOwn(window.offlineSig.exec.offline.always, cb => cb());
            _.forOwn(window.offlineSig.exec.offline.once, cb => cb());
            window.offlineSig.exec.offline.once = {};
        });

        window.addEventListener('online', () => {
            console.log('Online');

            // if (window.dismissFlash) window.dismissFlash();

            if (window.offlineSig.exec.online.mute) return;

            if (window.dismissFlash) window.dismissFlash('offlineWarning');

            _.forOwn(window.offlineSig.exec.online.always, cb => cb());
            _.forOwn(window.offlineSig.exec.online.once, cb => cb());
            window.offlineSig.exec.online.once = {};
        });

        if (window.navigator) {
            setInterval(() => {
                if (!window.navigator.onLine) {
                    if (window.offlineSig.exec.offline.mute) return;

                    window.addFlash &&
                        addFlash({
                            id: 'offlineWarning',
                            type: 'warning',
                            message: tr('front.lost_connection', {}, 'bridge-bridge') || 'Lost internet connection',
                            closable: false,
                            autoClose: false
                        });

                    _.forOwn(window.offlineSig.exec.offline.always, cb => cb());
                    _.forOwn(window.offlineSig.exec.offline.once, cb => cb());
                    window.offlineSig.exec.offline.once = {};
                } else {
                    if (window.offlineSig.exec.online.mute) return;

                    // if (window.dismissFlash) window.dismissFlash();
                    if (window.dismissFlash) window.dismissFlash('offlineWarning');

                    _.forOwn(window.offlineSig.exec.online.always, cb => cb());
                    _.forOwn(window.offlineSig.exec.online.once, cb => cb());
                    window.offlineSig.exec.online.once = {};
                }
            }, 10 * 1000);
        }
    }

    window.hexToHSL = function hexToHSL(H) {
        // Convert hex to RGB first
        let r = 0;
        let g = 0;
        let b = 0;
        if (H.length === 4) {
            r = `0x${H[1]}${H[1]}`;
            g = `0x${H[2]}${H[2]}`;
            b = `0x${H[3]}${H[3]}`;
        } else if (H.length === 7) {
            r = `0x${H[1]}${H[2]}`;
            g = `0x${H[3]}${H[4]}`;
            b = `0x${H[5]}${H[6]}`;
        }
        // Then to HSL
        r /= 255;
        g /= 255;
        b /= 255;
        let cmin = Math.min(r, g, b);
        let cmax = Math.max(r, g, b);
        let delta = cmax - cmin;
        let h = 0;
        let s = 0;
        let l = 0;

        if (delta === 0) h = 0;
        else if (cmax === r) h = ((g - b) / delta) % 6;
        else if (cmax === g) h = (b - r) / delta + 2;
        else h = (r - g) / delta + 4;

        h = Math.round(h * 60);

        if (h < 0) h += 360;

        l = (cmax + cmin) / 2;
        s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1));
        s = +(s * 100).toFixed(1);
        l = +(l * 100).toFixed(1);

        return [h, s, l];
    };

    window.hexToRgb = function hexToRgb(hex) {
        const bigint = parseInt(hex.slice(1), 16);
        const r = (bigint >> 16) & 255;
        const g = (bigint >> 8) & 255;
        const b = bigint & 255;
        return [r, g, b];
    };

    window.rgbToHsl = function rgbToHsl(r, g, b) {
        r /= 255;
        g /= 255;
        b /= 255;

        const max = Math.max(r, g, b);
        const min = Math.min(r, g, b);
        let h;
        let s;
        let l = (max + min) / 2;

        if (max === min) {
            h = s = 0; // achromatic
        } else {
            const d = max - min;
            s = l > 0.5 ? d / (2 - max - min) : d / (max + min);
            switch (max) {
                case r:
                    h = (g - b) / d + (g < b ? 6 : 0);
                    break;
                case g:
                    h = (b - r) / d + 2;
                    break;
                case b:
                    h = (r - g) / d + 4;
                    break;
            }
            h /= 6;
        }

        return [h, s, l];
    };

    window.hslToHex = function hslToHex(h, s, l) {
        l /= 100;
        const a = (s * Math.min(l, 1 - l)) / 100;
        const f = n => {
            const k = (n + h / 30) % 12;
            const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
            return Math.round(255 * color)
                .toString(16)
                .padStart(2, '0'); // convert to Hex and prefix "0" if needed
        };
        return `#${f(0)}${f(8)}${f(4)}`;
    };

    window.rgbToHex = function rgbToHex(r, g, b) {
        return `#${[r, g, b]
            .map(x => {
                const hex = x.toString(16);
                return hex.length === 1 ? `0${hex}` : hex;
            })
            .join('')}`;
    };

    window.isLightOrDark = function isLightOrDark(color) {
        let r;
        let g;
        let b;
        let hsp;
        if (!color) return null;

        if (color.match(/^rgb/)) {
            // If HEX --> store the red, green, blue values in separate variables
            color = color.match(/^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/);

            r = color[1];
            g = color[2];
            b = color[3];
        } else {
            // If RGB --> Convert it to HEX: http://gist.github.com/983661
            color = +`0x${color.slice(1).replace(color.length < 5 && /./g, '$&$&')}`;

            r = color >> 16;
            g = (color >> 8) & 255;
            b = color & 255;
        }

        // HSP equation from http://alienryderflex.com/hsp.html
        hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

        // Using the HSP value, determine whether the color is light or dark
        if (hsp > 186) {
            return 'light';
        }

        return 'dark';
    };

    window.hslToRgb = function hslToRgb(h, s, l) {
        let r;
        let g;
        let b;

        if (s === 0) {
            r = g = b = l; // achromatic
        } else {
            const hue2rgb = (p, q, t) => {
                if (t < 0) t += 1;
                if (t > 1) t -= 1;
                if (t < 1 / 6) return p + (q - p) * 6 * t;
                if (t < 1 / 2) return q;
                if (t < 2 / 3) return p + (q - p) * (2 / 3 - t) * 6;
                return p;
            };

            const q = l < 0.5 ? l * (1 + s) : l + s - l * s;
            const p = 2 * l - q;
            r = hue2rgb(p, q, h + 1 / 3);
            g = hue2rgb(p, q, h);
            b = hue2rgb(p, q, h - 1 / 3);
        }

        return [Math.round(r * 255), Math.round(g * 255), Math.round(b * 255)];
    };

    window.isColorTooDarkOrLight = function isColorTooDarkOrLight(color, threshold) {
        // Convert color from hexadecimal to RGB
        const red = parseInt(color.slice(1, 3), 16);
        const green = parseInt(color.slice(3, 5), 16);
        const blue = parseInt(color.slice(5, 7), 16);

        // Calculate perceived luminance
        const luminance = (0.299 * red + 0.587 * green + 0.114 * blue) / 255;

        // Compare luminance with the threshold
        return luminance < threshold || luminance > 1 - threshold; // Returns true if color is too dark, false if it's too light
    };
}
