import clsx from 'clsx';
import React from 'react';
import __Base from '@components/Base';
import get from 'lodash/get';
class Logo extends __Base {
  render = () => {
    const {
      router,
      appuser,
      whitelabel
    } = this.props;
    const customLogo = router.route.includes('/back') ? null : absolute(router.route.includes('/connect') ? get(whitelabel, 'logo.src') : get(appuser, 'organization.whitelabel.logo.src'));
    return <img alt='' className={clsx('logo /custom')} src={cors(customLogo || asset('short-logo.png'))} />;
  };
}
export default Logo;