import __Base from '@components/Base';
import _ from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { useRouter } from 'next/router';
import useCustomTranslation from '@hooks/useCustomTranslation';
import { isEnvTest } from '@utils/getEnv';
function withMyHook(Component) {
  return function WrappedComponent(props) {
    const router = useRouter();
    const {
      tr
    } = useCustomTranslation();
    if (router) {
      return <Component {...props} router={router} tr={tr} />;
    }
  };
}
class Error extends __Base {
  constructor(props) {
    super(props);
    this.REMAINING_TIME_AT_START = 8;
    this.state = {
      remainingTime: this.REMAINING_TIME_AT_START
    };
  }
  clock = () => {
    if (this.onHome()) return;
    let ended = false;
    this.updateState(draft => {
      draft.remainingTime--;
      if (draft.remainingTime <= 0) ended = true;
    }, () => {
      if (ended && !this.shouldExitBeExceptionallyAvoided()) {
        clearInterval(this.clockInterval);
        this.exit();
      }
    });
  };
  componentDidMount = () => {
    // For 403 errors, we choose not to redirect, because the user could in fact be in another tab (impersonation), and could want to retrieve the page later
    // if (!this.onHome() && this.props.statusCode !== 403) this.clockInterval = setInterval(this.clock, 1000);
  };
  componentWillUnmount = () => {
    if (this.clockInterval) clearInterval(this.clockInterval);
  };
  exit = () => window.open('/', '_self');
  handleExitClick = ev => {
    if (ev) ev.preventDefault();
    this.exit();
  };

  /**
   * @returns {boolean}
   */
  onHome = () => _.includes(['/', ''], this.props.router.route);
  shouldExitBeExceptionallyAvoided = () => !!(isEnvTest || root?.state?.appuser && root?.state?.appuser?.isAdmin() && (!this.props.statusCode || this.props.statusCode >= 500));
  render = () => {
    const {
      statusCode,
      tr
    } = this.props;
    return <main className='error'>
                {!!statusCode && <div className='-statusCode'>{statusCode !== 500 ? statusCode : 503}</div>}
                <div className='-message'>
                    <strong>{tr(`front.error.message.${_.includes([401, 403, 404], statusCode) ? statusCode : 'default'}.main`)}</strong>
                    <br />
                    <small>{tr(`front.error.message.${_.includes([401, 403, 404], statusCode) ? statusCode : 'default'}.sub`)}</small>
                </div>
                {(!this.onHome() && isEnvTest && statusCode !== 403) === true && <React.Fragment>
                        <div className='-redirect'>
                            {tr('front.error.counter', {
            amount: this.state.remainingTime || '0'
          })}
                            {this.shouldExitBeExceptionallyAvoided() && <small>
                                    <u>
                                        <strong>(§won't be done because you're admin)</strong>
                                    </u>
                                </small>}
                        </div>
                        <a className='-exit' href='' onClick={this.handleExitClick}>
                            {tr('front.error.exit')}
                        </a>
                    </React.Fragment>}
            </main>;
  };
}
Error.defaultProps = {
  statusCode: null
};
Error.propTypes = {
  // optional
  statusCode: PropTypes.number
};
export default withMyHook(Error);