// import Me from './store/bridge/me.json';
// import AppUser from './store/bridge/appuser.json';
// import Whitelabel from './store/bridge/whitelabel.json';
// import WhitelabelOrpi from './store/bridge/whitelabel_orpi.json';
// import Meta from './store/bridge/meta.json';
import Constants from './store/bridge/constants.json';
import ConstantsProd from './store/bridge/constants_prod.json';

// import Workspace from './store/bridge/workspace.json';
// import Flashes from './store/bridge/flashes.json';
//
// import User from './store/payload/user.json';
//
// import Home from './store/payload/office/front-office/bridge/home.json';
//
// import OrganizationTeam from './store/payload/office/front-office/organization/team.json';
// import OrganizationApi from './store/payload/office/front-office/organization/api.json';
// import OrganizationWhitelabel from './store/payload/office/front-office/organization/whitelabel.json';
// import OrganizationWorkspaces from './store/payload/office/front-office/organization/workspaces.json';
//
// import UserProfile from './store/payload/office/front-office/user/profile.json';
//
// import VideoLibrary from './store/payload/office/front-office/video/library.json';
// import VideoVideoNew from './store/payload/office/front-office/video/video_new.json';
// import VideoVideoShow from './store/payload/office/front-office/video/video_show.json';
//
// import VideoEditPreviewDone from './store/payload/office/front-office/video/video_edit_preview_done.json';
// import VideoEditPreviewNull from './store/payload/office/front-office/video/video_edit_preview_null.json';
// import VideoEditPreviewPending from './store/payload/office/front-office/video/video_edit_preview_pending.json';
// import VideoEditPreviewUpload from './store/payload/office/front-office/video/video_edit_preview_upload.json';
//
// import ConnectActivate from './store/payload/office/connect/activate.json';
//
// import BotEdit from './store/payload/office/back-office/bot/bot_edit.json';
// import BotList from './store/payload/office/back-office/bot/bot_list.json';
// import BotBridge from './store/payload/office/back-office/bot/bot_bridge.json';
//
// import LibraryEdit from './store/payload/office/back-office/library/library_edit.json';
// import LibraryList from './store/payload/office/back-office/library/library_list.json';
//
// import PexelsArbre from './store/lib/pexels/arbre.json';

export {
    // Me,
    // AppUser,
    // Whitelabel,
    // WhitelabelOrpi,
    // Meta,
    Constants,
    ConstantsProd
    // Workspace,
    // Flashes,
    // User,
    // OrganizationTeam,
    // OrganizationApi,
    // OrganizationWhitelabel,
    // OrganizationWorkspaces,
    // Home,
    // UserProfile,
    // VideoLibrary,
    // VideoVideoNew,
    // VideoVideoShow,
    // VideoEditPreviewDone,
    // VideoEditPreviewNull,
    // VideoEditPreviewPending,
    // VideoEditPreviewUpload,
    // PexelsArbre,
    // ConnectActivate,
    // BotEdit,
    // BotList,
    // BotBridge,
    // LibraryEdit,
    // LibraryList
};
