const _ = require('lodash');

/* eslint-disable import/no-dynamic-require */
function getRequireLanguage(locale) {
    return _.merge(
        require(`./${locale}/bridge/bridge-bot.json`),
        require(`./${locale}/bridge/bridge-bridge.json`),
        require(`./${locale}/bridge/bridge-license.json`),
        require(`./${locale}/bridge/bridge-organization.json`),
        require(`./${locale}/bridge/bridge-template.json`),
        require(`./${locale}/bridge/bridge-template_source.json`),
        require(`./${locale}/bridge/bridge-user.json`),
        require(`./${locale}/bridge/bridge-video.json`),
        require(`./${locale}/office/back-office/back_office-bot.json`),
        require(`./${locale}/office/back-office/back_office-bridge.json`),
        require(`./${locale}/office/back-office/back_office-library.json`),
        require(`./${locale}/office/back-office/back_office-license.json`),
        require(`./${locale}/office/back-office/back_office-organization.json`),
        require(`./${locale}/office/back-office/back_office-template.json`),
        require(`./${locale}/office/back-office/back_office-template_source.json`),
        require(`./${locale}/office/back-office/back_office-user.json`),
        require(`./${locale}/office/back-office/back_office-video.json`),
        require(`./${locale}/office/back-office/back_office-workspace.json`),
        require(`./${locale}/office/back-office/back_office-category.json`),
        require(`./${locale}/office/connect/connect-bridge.json`),
        require(`./${locale}/office/connect/connect-user.json`),
        require(`./${locale}/office/front-office/front_office-bridge.json`),
        require(`./${locale}/office/front-office/front_office-home.json`),
        require(`./${locale}/office/front-office/front_office-organization.json`),
        require(`./${locale}/office/front-office/front_office-template.json`),
        require(`./${locale}/office/front-office/front_office-user.json`),
        require(`./${locale}/office/front-office/front_office-video.json`),
        require(`./${locale}/office/front-office/front_office-workspace.json`),
        require(`./${locale}/office/front-office/front_office-category.json`),
        require(`./${locale}/home-staging.json`),
        require(`./${locale}/music-library.json`),
        require(`./${locale}/image-to-video.json`)
    );
}

const i18n = {
    translations: {
        fr: getRequireLanguage('fr'),
        en: getRequireLanguage('en'),
        de: getRequireLanguage('de'),
        es: getRequireLanguage('es'),
        it: getRequireLanguage('it'),
        pt: getRequireLanguage('pt')
    },
    defaultLang: 'fr',
    available: ['fr', 'en', 'de', 'es', 'it', 'pt']
};
module.exports = i18n;
