import * as _ from 'lodash';
import isBasicObject from './isBasicObject';

export default function mergeDeep(target, ...sources) {
    if (!sources.length) return target;
    const source = sources.shift();

    if ((isBasicObject(target) || _.isArray(target)) && (isBasicObject(source) || _.isArray(source))) {
        for (const key in source) {
            if (isBasicObject(source[key]) || _.isArray(source[key])) {
                if (!target[key]) Object.assign(target, { [key]: {} });
                mergeDeep(target[key], source[key]);
            } else Object.assign(target, { [key]: source[key] });
        }
    }

    return mergeDeep(target, ...sources);
}
