import { alpha, PaletteOptions } from '@mui/material/styles';

const GREY = {
    0: '#FFFFFF',
    100: '#F9FAFB',
    200: '#F4F6F8',
    300: '#DFE3E8',
    400: '#C4CDD5',
    500: '#919EAB',
    600: '#637381',
    700: '#454F5B',
    800: '#212B36',
    900: '#161C24'
};

const PRIMARY = {
    lighter: '#84bec9',
    light: '#5aa9b7',
    main: '#3294A5',
    dark: '#287684',
    darker: '#1e5863',
    contrastText: '#fff'
};

const SECONDARY = {
    lighter: '#f4f4f4',
    light: '#f1f1f1',
    main: '#EEEEEE',
    dark: '#bebebe',
    darker: '#8e8e8e',
    contrastText: '#fff'
};

const INFO = {
    lighter: '#CAFDF5',
    light: '#61F3F3',
    main: '#00B8D9',
    dark: '#006C9C',
    darker: '#003768',
    contrastText: '#fff'
};

const SUCCESS = {
    lighter: '#D8FBDE',
    light: '#86E8AB',
    main: '#36B37E',
    dark: '#1B806A',
    darker: '#0A5554',
    contrastText: '#fff'
};

const WARNING = {
    lighter: '#FFF5CC',
    light: '#FFD666',
    main: '#FFAB00',
    dark: '#B76E00',
    darker: '#7A4100',
    contrastText: GREY[800]
};

const ERROR = {
    lighter: '#fce4e4',
    light: '#f5adac',
    main: '#cc3633',
    dark: '#ac2c29',
    darker: '#772725',
    contrastText: '#fff'
};

const COMMON = {
    common: { black: '#000', white: '#fff' },
    primary: PRIMARY,
    secondary: SECONDARY,
    info: INFO,
    success: SUCCESS,
    warning: WARNING,
    error: ERROR,
    grey: GREY,
    divider: alpha(GREY[500], 0.24),
    action: {
        hover: alpha(GREY[500], 0.08),
        selected: alpha(GREY[500], 0.16),
        disabled: alpha(GREY[500], 0.8),
        disabledBackground: alpha(GREY[500], 0.24),
        focus: alpha(GREY[500], 0.24),
        hoverOpacity: 0.08,
        disabledOpacity: 0.48
    }
};

export default function palette(themeMode = 'light') {
    const light: PaletteOptions = {
        ...COMMON,
        mode: 'light',
        text: {
            primary: GREY[800],
            secondary: GREY[600],
            disabled: GREY[500]
        },
        background: { paper: '#fff', default: '#fff' },
        action: {
            ...COMMON.action,
            active: GREY[600]
        }
    };

    const dark: PaletteOptions = {
        ...COMMON,
        mode: 'dark',
        text: {
            primary: '#fff',
            secondary: GREY[500],
            disabled: GREY[600]
        },
        background: {
            paper: GREY[800],
            default: GREY[900]
            // neutral: alpha(GREY[500], 0.16)
        },
        action: {
            ...COMMON.action,
            active: GREY[500]
        }
    };

    return themeMode === 'light' ? light : dark;
}
