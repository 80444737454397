import useSWR from 'swr';
import { apiSwrRequest, logout } from '@services/http/client';
import queryAppUserApi from '@queries/api/bridge/queryAppUser';
import queryMeApi from '../queries/api/bridge/queryMe';

const useLoadMe = () => {
    const { data: me, isLoading: isLoadingMe } = useSWR(queryMeApi(), data => apiSwrRequest(data, 'bridgeMe'));
    if (!isLoadingMe && me && !me.id) {
        logout().catch(console.error);
        return null;
    }
    return { data: me, isLoading: isLoadingMe };
};

const useLoadAppUser = me => {
    return useSWR(
        () => queryAppUserApi(me?.id),
        data => apiSwrRequest(data, me?.__typename === 'User' ? 'bridgeUser' : 'bridgeApiApp')
    );
};

const User = { useLoadMe, useLoadAppUser };

export default User;
