import __Base from '@components/Base';
import PropTypes from 'prop-types';
import React from 'react';
import Menu from './menu/Menu';
class Header extends __Base {
  constructor(props) {
    super(props);
    window.header = this;
  }
  render = () => {
    const {
      menuDisplayed,
      menuOpen,
      onToggle,
      appuser,
      workspace,
      whitelabel,
      router
    } = this.props;
    const iFrameDetection = window && window.self !== window.parent;
    return <header className='header' ref={obj => this.root = obj}>
                {(!router.route.includes('connect') && menuDisplayed) === true && <Menu workspace={workspace} whitelabel={whitelabel} appuser={appuser} open={menuOpen} onToggle={onToggle} router={router} isIframe={iFrameDetection} />}
            </header>;
  };
}
Header.defaultProps = {
  menuDisplayed: true,
  menuOpen: false
};
Header.propTypes = {
  // optional
  menuDisplayed: PropTypes.bool,
  menuOpen: PropTypes.bool
};
export default Header;