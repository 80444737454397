import __Base from '@components/Base';
import clsx from 'clsx';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import React from 'react';
import Link from 'next/link';
import WaitingAnimation from './WaitingAnimation';
class WaitingButton extends __Base {
  handleClick = ev => {
    const {
      onClick,
      stopPropagationOnClick,
      preventDefaultOnClick
    } = this.props;
    if (stopPropagationOnClick) ev.stopPropagation();
    if (preventDefaultOnClick) ev.preventDefault();
    if (onClick) onClick(ev);
  };
  render = () => {
    const {
      customAttributes,
      disabled,
      href,
      mood,
      text,
      type,
      waiting
    } = this.props;
    const Type = href ? Link : 'div';
    return <button ref={obj => this.root = obj} data-attr='root' {...get(customAttributes, 'root', {})} className={clsx('btn', 'button', mood && `/${mood}`, (disabled || waiting) && '/disabled', get(customAttributes, 'root.className'))} disabled={disabled || waiting} type={type} onClick={this.handleClick} data-test-id='submit-button'>
                <Type {...href ? {
        href
      } : null}>
                    <div className='-shell'>
                        <span className={clsx('-text', waiting && 'tlHidden')}>{text}</span>
                        <WaitingAnimation customAttributes={{
            root: {
              className: clsx('-animation', !waiting && 'tlHidden')
            }
          }} />
                    </div>
                </Type>
            </button>;
  };
}
WaitingButton.defaultProps = {
  customAttributes: {},
  disabled: false,
  href: null,
  mood: null,
  onClick: ev => null,
  preventDefaultOnClick: false,
  stopPropagationOnClick: false,
  text: '',
  type: 'submit',
  waiting: false
};
WaitingButton.propTypes = {
  // optional
  customAttributes: PropTypes.object,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  mood: PropTypes.oneOf(['danger', 'info', 'warning']),
  onClick: PropTypes.func,
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  type: PropTypes.oneOf(['button', 'submit']),
  waiting: PropTypes.bool,
  stopPropagationOnClick: PropTypes.bool,
  preventDefaultOnClick: PropTypes.bool
};
export default WaitingButton;