if (typeof window !== 'undefined') {
    /**
     * Source of inspiration: https://stackoverflow.com/questions/34604339/vanilla-javascript-check-if-element-or-ancestor-has-class/34605882#34605882#answer-34605882
     */
    window.elementOrAncestorHasClass = (element, className) => {
        if (!element || element.length === 0) return false;
        let parent = element;
        do {
            if (!parent || parent === document) break;
            if (parent?.classList?.contains(className)) return true;
            parent = parent.parentNode;
        } while (parent);
        return false;
    };

    /**
     * Retrieve selection position when changing an input value
     * Source: https://stackoverflow.com/questions/3286595/update-textarea-value-but-keep-cursor-position#answer-3288215
     * @param el
     * @returns {{start: number, end: number}}
     */
    window.getInputSelection = el => {
        let start = 0;
        let end = 0;
        let normalizedValue;
        let range;
        let textInputRange;
        let len;
        let endRange;

        if (typeof el.selectionStart == 'number' && typeof el.selectionEnd == 'number') {
            start = el.selectionStart;
            end = el.selectionEnd;
        } else {
            range = document.selection.createRange();

            if (range && range.parentElement() === el) {
                len = el.value.length;
                normalizedValue = el.value.replace(/\r\n/g, '\n');

                // Create a working TextRange that lives only in the input
                textInputRange = el.createTextRange();
                textInputRange.moveToBookmark(range.getBookmark());

                // Check if the start and end of the selection are at the very end
                // of the input, since moveStart/moveEnd doesn't return what we want
                // in those cases
                endRange = el.createTextRange();
                endRange.collapse(false);

                if (textInputRange.compareEndPoints('StartToEnd', endRange) > -1) {
                    start = end = len;
                } else {
                    start = -textInputRange.moveStart('character', -len);
                    start += normalizedValue.slice(0, start).split('\n').length - 1;

                    if (textInputRange.compareEndPoints('EndToEnd', endRange) > -1) {
                        end = len;
                    } else {
                        end = -textInputRange.moveEnd('character', -len);
                        end += normalizedValue.slice(0, end).split('\n').length - 1;
                    }
                }
            }
        }

        return { start, end };
    };
    //      see above
    window.offsetToRangeCharacterMove = (el, offset) => {
        return offset - (el.value.slice(0, offset).split('\r\n').length - 1);
    };
    //      see above
    window.setInputSelection = (el, startOffset, endOffset) => {
        if (typeof el.selectionStart == 'number' && typeof el.selectionEnd == 'number') {
            el.selectionStart = startOffset;
            el.selectionEnd = endOffset;
        } else {
            const range = el.createTextRange();
            const startCharMove = offsetToRangeCharacterMove(el, startOffset);
            range.collapse(true);
            if (startOffset === endOffset) {
                range.move('character', startCharMove);
            } else {
                range.moveEnd('character', offsetToRangeCharacterMove(el, endOffset));
                range.moveStart('character', startCharMove);
            }
            range.select();
        }
    };

    window.isInWindow = element => {
        const rect = element?.getBoundingClientRect();
        if (!rect) return false;
        return (
            rect.top >= 0 &&
            rect.left >= 0 &&
            rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
            rect.right <= (window.innerWidth || document.documentElement.clientWidth)
        );
    };

    window.areWeUnderDanimHost = () => {
        const whitelist = [/^https?:\/\/iadsocialcreator\.[a-z]{2}\.danim\.com$/];

        if (whitelist.some(regex => regex.test(window.origin))) {
            return false;
        }
        return window.origin?.includes('danim.com');
    };
}
