import getInStorage from '@utils/lodash/getInStorage';
import setInStorage from '@utils/lodash/setInStorage';

const getSelectedWorkspaceId = () => {
    return getInStorage(`WORKING_WORKSPACE_${process.env.NEXT_PUBLIC_APP_ENV}`, window.localStorage) || null;
};

const setSelectedWorkspaceId = (selectedWorkspaceId: string) => {
    setInStorage(`WORKING_WORKSPACE_${process.env.NEXT_PUBLIC_APP_ENV}`, selectedWorkspaceId, window.localStorage);
};

const Workspace = { getSelectedWorkspaceId, setSelectedWorkspaceId };

export default Workspace;
