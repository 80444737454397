import React from 'react';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
import clsx from 'clsx';
import TooltipBox from '@components/bridge/bridge/TooltipBox';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faComment, faGraduationCap, faHashtag, faInfo, faQuestion } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
type Props = {
  isOpen: boolean;
  handleHelpCenterToggle: () => void;
  handleHelpCenterProductTourClick: () => void;
};
const HelperCenterButton = (props: Props) => {
  const {
    isOpen,
    handleHelpCenterToggle,
    handleHelpCenterProductTourClick
  } = props;
  const menuItemTooltipPositionStyle = {
    right: '0',
    top: 'calc(100% + 1rem)'
  };
  const handleHelpCenterChatClick = ev => {
    if (ev) ev.preventDefault() & ev.stopPropagation();
    window.openChat();
  };
  const handleHelpCenterClick = ev => {
    if (ev) ev.preventDefault() & ev.stopPropagation();
    window.open('https://help.danim.com/', '_blank');
  };
  return <Dropdown nav inNavbar isOpen={isOpen} toggle={handleHelpCenterToggle}>
            <DropdownToggle className={clsx('-item', '/level1', '/helpCenter')}>
                <TooltipBox caption={tr('front.menu.help_center_box.tooltip')} style={menuItemTooltipPositionStyle}>
                    <span className='-inner'>
                        <FontAwesomeIcon icon={faQuestion} width={16} />
                        <span className='-caption'>{tr('front.menu.help_center_box.tooltip')}</span>
                    </span>
                </TooltipBox>
            </DropdownToggle>
            <DropdownMenu id='help' right className='-menu'>
                <Link href='https://help.danim.com/fr/collections/9795264-danim-academy' passHref>
                    <a target='_blank' rel='noopener noreferrer'>
                        <DropdownItem className='-item'>
                            <FontAwesomeIcon className='-icon' icon={faGraduationCap} width={8} />
                            <span>{tr('front.menu.help_center_box.danim_academy')}</span>
                        </DropdownItem>
                    </a>
                </Link>
                <DropdownItem className='-item' onClick={handleHelpCenterProductTourClick}>
                    <FontAwesomeIcon className='-icon' icon={faInfo} width={8} />
                    <span>{tr('front.menu.help_center_box.product_tour')}</span>
                </DropdownItem>
                <DropdownItem className='-item' onClick={handleHelpCenterClick}>
                    <FontAwesomeIcon className='-icon' icon={faBook} width={16} />
                    <span>{tr('front.menu.help_center_box.help_center', {}, 'bridge-bridge')}</span>
                </DropdownItem>
                <DropdownItem className='-item' onClick={handleHelpCenterChatClick}>
                    <FontAwesomeIcon className='-icon' icon={faComment} width={16} />
                    <span>{tr('front.menu.help_center_box.intercom_chat', {}, 'bridge-bridge')}</span>
                </DropdownItem>
                <DropdownItem className='-item'>
                    <FontAwesomeIcon className='-icon' icon={faHashtag} width={16} />
                    <span>
                        {tr('front.menu.help_center_box.version', {}, 'bridge-bridge')} {process.env.NEXT_PUBLIC_APP_VERSION || '?'}
                    </span>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>;
};
export default HelperCenterButton;