import __Base from '@components/Base';
import uniqid from '@utils/lodash/uniqid';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import React from 'react';
import NewTermsAgreement from '@components/office/front-office/user/modal/content/NewTermsAgreement';
import PropTypes from 'prop-types';
import { Close } from '@components/bridge/bridge/svg';
import WaitingAnimation from '@components/bridge/bridge/WaitingAnimation';
import { v4 as uuidv4 } from 'uuid';
class ModalRoot extends __Base {
  constructor(props) {
    super(props);
    this.PRINT = `data-print-${uniqid()}`; // clickOut management system
    this.MODAL_LOADING_NONE = 0;
    this.MODAL_LOADING_ONCE = 1;
    this.MODAL_LOADING_ALWAYS = 2;
    this.state = {
      content: null,
      open: false
    };
  }
  beLoading = (to = true, callback = () => null) => {
    updateRoot(draft => {
      draft.modal.loading = to;
    }, callback);
  };
  block = () => {
    updateRoot(draft => {
      draft.modal.closable = false;
    });
  };

  /**
   * Non-null offset means setting offset to previously saved one, otherwise it means saving body offset + going to top
   * <body> scrollTop is not available as other blocks are
   *
   * @param offset (may be undefined - if so, we ignore it)
   */
  bodyOffset = (offset = null) => {
    if (offset !== null) {
      document.body.scrollTop = offset;
      document.documentElement.scrollTop = offset;
    } else {
      window.modalBodyOffset = document.body.scrollTop || document.documentElement.scrollTop || 0;
      if (probe.mobile) document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
  };
  close = (id = null) => {
    if (!modalRoot.open ||
    // already closed
    id && modalRoot.id !== id // id=NULL means "whatever the ID is"
    ) return;
    let closeCallback = null;
    updateRoot(draft => {
      draft.modal.open = false;
      draft.modal.closable = true;
      draft.modal.component = null;
      closeCallback = draft.modal.closeCallback || null;
      draft.modal.closeCallback = null;
    }, () => {
      document.body.style.overflow = null;
      document.documentElement.style.paddingTop = '';
      document.removeEventListener('pointerdown', this.handleClickOut);
      document.documentElement.classList.remove('/modalOpen');

      // recover previous body offset
      this.bodyOffset(window.modalBodyOffset || 0);

      // remove related url query parameters if they exist (modal, modalProps)
      /* if (this.props.router.query.includes('modal') || this.props.router.query.includes('modalProps')) {
          updateRoot(
              draft => {
                  // routing
                  if (_.get(draft, 'router.query.modal')) delete draft.router.query.modal;
                  if (_.get(draft, 'router.query.modalProps')) delete draft.router.query.modalProps;
                  _.each(['uri', 'uriRequestUri', 'uriPathWithQuery'], key => {
                      const element = draft.router[key];
                      if (element.indexOf('?') !== -1) {
                          const split = element.split('?');
                          let query = qs.parse(split[1]);
                          delete query.modal;
                          delete query.modalProps;
                          query = qs.stringify(query);
                          draft.routing[key] = split[0] + (query ? `?${query}` : '');
                      }
                  });
              },
              () => hist.replace(routing.uriPathWithQuery)
          ); // then update the url visible to user
      } */

      if (closeCallback) closeCallback();

      // JS responsiveness should be done
      window.dispatchEvent(new Event('resize'));
    });
  };
  componentDidMount = () => {
    window.modal = this;
    this.intervalPrint = setInterval(this.printOnContent, 500);
    if (!window.origin.includes('connect') && this.props.appuser && (!this.props.whitelabel || this.props.appuser.isOrganizationDirector()) && _.get(this.props.appuser, 'account.lastTermsAgreementAt') && moment(this.props.appuser.account.lastTermsAgreementAt).unix() < parseInt(process.env.NEXT_PUBLIC_LAST_TERMS_AND_CONDITIONS_TIMESTAMP)) this.set(NewTermsAgreement, null, {
      appuser: this.props.appuser
    }, false, () => this.open(true));
  };
  componentDidUpdate = () => this.printOnContent();
  componentWillUnmount = () => {
    if (this.intervalPrint) clearInterval(this.intervalPrint);
  };
  handleClickOut = ev => {
    const {
      blockClickOutside
    } = this.props;
    const dialog = _.get(document.getElementsByClassName('-dialog'), '0');
    let insideRect = false;
    if (dialog && ev.target) {
      const dialogRect = dialog.getBoundingClientRect();
      const targetRect = ev.target.getBoundingClientRect();
      if (targetRect.left >= dialogRect.left && targetRect.right <= dialogRect.right && targetRect.top >= dialogRect.top && targetRect.bottom <= dialogRect.bottom) insideRect = true;
    }
    if (!(ev && ev.target && ev.target.hasAttribute && ev.target.hasAttribute(this.PRINT)) && modalRoot.closable && modalRoot.open && !elementOrAncestorHasClass(ev.target, '-flash') &&
    // except flashes (and their content)
    !insideRect && !blockClickOutside) this.close();
  };
  open = (closable = true) => {
    this.bodyOffset();
    updateRoot(draft => {
      draft.modal.open = true;
      draft.modal.closable = closable;
    }, () => {
      if (!probe.mobile) document.body.style.overflow = 'hidden';else document.documentElement.style.paddingTop = 0;
      document.documentElement.style.paddingTop = 0;
      document.addEventListener('pointerdown', this.handleClickOut);
      document.documentElement.classList.add('/modalOpen');
      document.getElementsByClassName('modal')[0].scrollTo(0, 0);
    });
  };
  printOnContent = () => {
    if (this.dialog) {
      _.each(this.dialog.getElementsByTagName('*'), element => element.setAttribute(this.PRINT, ''));
      this.dialog.setAttribute(this.PRINT, '');
    }
    // think about any google autocompletion input
    const googleStuff = _.get(document.getElementsByClassName('pac-container'), '0');
    if (googleStuff) {
      _.each(googleStuff.getElementsByTagName('*'), element => element.setAttribute(this.PRINT, ''));
      googleStuff.setAttribute(this.PRINT, '');
    }
  };
  reset = (callback = () => null) => {
    updateRoot(draft => {
      draft.modal.component = null;
      draft.modal.propsFactory = state => null;
      draft.modal.title = state => null;
      draft.modal.open = false;
      draft.modal.closable = true;
      draft.modal.loading = false;
      draft.modal.key = uuidv4(); // This will force a rerender
    }, callback);
  };

  /**
   * @param component
   * @param title (string|function|null)
   * @param propsFactory (string|function|null)
   * @param reset
   * @param callback
   * @param closeCallback
   * @param id
   */
  set = (component, title = null, propsFactory = null, reset = false, callback = () => this.open(), closeCallback = null, id = null) => {
    const call = () => {
      const blockClickOutside = _.get(component, 'propsFactory.blockClickOutside', false);
      updateRoot(draft => {
        const defaultTitle = component.defaultTitle();
        const defaultPropsFactory = component.defaultPropsFactory();
        draft.modal.component = component;
        draft.modal.id = id || null;
        draft.modal.title = title ? _.isFunction(title) ? title : state => title : _.isFunction(defaultTitle) ? defaultTitle : state => defaultTitle;
        draft.modal.propsFactory = propsFactory ? _.isFunction(propsFactory) ? propsFactory : state => propsFactory : _.isFunction(defaultPropsFactory) ? defaultPropsFactory : state => defaultPropsFactory;
        draft.modal.closeCallback = closeCallback;
        const loading = component.loading(draft.modal.propsFactory(draft));
        draft.modal.loading = loading === this.MODAL_LOADING_NONE ? false : loading === this.MODAL_LOADING_ALWAYS ? true : component !== draft.modal.component;
        draft.modal.blockClickOutside = blockClickOutside;
      }, callback);
    };
    if (reset) this.reset(call);else call();
  };
  unblock = () => {
    updateRoot(draft => {
      draft.modal.closable = true;
    });
  };
  render = () => {
    const {
      children,
      closable,
      customAttributes,
      loading,
      open,
      title
    } = this.props;
    return <div ref={obj => this.root = obj} data-attr='root' {..._.get(customAttributes, 'root', {})} className={clsx('modal', !open && '/closed', loading && '/loading', _.get(customAttributes, 'root.className'))}>
                {loading === true && <WaitingAnimation color='white' />}
                <div ref={obj => this.dialog = obj} className='-dialog'>
                    <div className='-header'>
                        <h3 className='-title'>{title || ' '}</h3>
                        {closable === true && children !== null && <Close className='-close' onClick={() => this.close(null)} />}
                    </div>
                    <div ref={obj => this.contentShell = obj} className='-contentShell'>
                        {children}
                    </div>
                </div>
            </div>;
  };
}
ModalRoot.defaultProps = {
  children: null,
  closable: true,
  customAttributes: {},
  loading: false,
  open: false,
  blockClickOutside: false,
  title: null
};
ModalRoot.propTypes = {
  // optional
  //      children = modal content
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  closable: PropTypes.bool,
  customAttributes: PropTypes.object,
  loading: PropTypes.bool,
  open: PropTypes.bool,
  blockClickOutside: PropTypes.bool,
  title: PropTypes.string
};
export default ModalRoot;