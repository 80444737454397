import { darken } from '@mui/system/colorManipulator';

const COLORS = ['yellow'];

export default function Fab(theme) {
    const rootStyle = ownerState => {
        const inheritSmall = ownerState.size === 'small';

        const defaultStyle = {
            ...(inheritSmall && {
                width: '30px',
                height: '30px',
                minHeight: '30px',
                '& .MuiSvgIcon-root': {
                    width: '0.8em',
                    height: '0.8em'
                }
            })
        };

        const colorStyle = COLORS.map(color => ({
            ...(ownerState.color === color &&
                color === 'yellow' && {
                    color: '#151503 !important',
                    backgroundColor: '#FFAB00',
                    '&:hover': {
                        backgroundColor: darken('#FFAB00', 0.08),
                        color: '#FFAB00'
                    }
                })
        }));

        return [defaultStyle, ...colorStyle];
    };

    return {
        MuiFab: {
            styleOverrides: {
                root: ({ ownerState }) => rootStyle(ownerState)
            }
        }
    };
}
