import memoizeOne from 'memoize-one';
import { v4 as uuidv4 } from 'uuid';
import AppUser from './app-user';
import Immutable from './immutable';
import User from './user';

export default class Factory {
    static _create = memoizeOne((name, data, _callId) => {
        if (!data) return null;

        switch (name) {
            case 'AppUser':
                return new AppUser(data);
            case 'User':
                return new User(data);
            default:
                return new Immutable(data);
        }
    });

    static create = (name, data, memoized = false) => {
        if (!data) return null;
        return Factory._create(name, data, memoized ? '_' : uuidv4());
    };
}
