import { useTranslation } from 'next-export-i18n';
import i18n from 'i18n';
import * as localeFns from 'date-fns/locale';

export const getFnsLocaleFromI18nLocale = (i18nLocale: string) => {
    if (i18nLocale === 'en') return localeFns.enUS;
    return localeFns[i18nLocale];
};

export default function useCustomTranslation() {
    const { t } = useTranslation();

    function getNestedValue(obj: Record<string, any>, path: string): string | undefined {
        return path.split('.').reduce((acc, key) => (acc && acc[key] !== undefined ? acc[key] : undefined), obj);
    }

    return {
        tr: (id, parameters) => {
            if (!id) return null;

            if (/(?:[a-z])(?:[A-Z])/.test(id)) console.warn(`The translation key "${id}" is in camelCase. Please use snake_case instead.`);

            // Checking typeof and not the variable, in case it's an 0.
            if (typeof parameters !== 'undefined' && !_.isObject(parameters)) return transChoice(t(id), parameters);

            // This will return undefined if the path cannot be found in our translation catalogue
            // const res = t(id, parameters);
            let res = getNestedValue(i18n.translations[i18n.defaultLang], id) || t(id, parameters);

            if (res.includes('front.') || res.includes('back.')) return undefined;

            if (typeof parameters !== 'undefined') {
                Object.entries(parameters).forEach(([key, value]) => {
                    res = res.replace(`{{${key}}}`, value);
                });
            }
            return res;
        },
        getFnsLocaleFromI18nLocale
    };
}
