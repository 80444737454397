import __Base from '@components/Base';
import React from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBoxOpen, faBug, faFilm } from '@fortawesome/free-solid-svg-icons';
import Link from 'next/link';
import clsx from 'clsx';
import _ from 'lodash';
import moment from 'moment';
import stringToColour from '@utils/lodash/stringToColour';
import Play from '@components/bridge/video/modal/content/Play';
import { apiRequest } from '@services/http/client';

// Need appuser && workspace
class Notification extends __Base {
  /**
   * @returns {*} (title, content, clickCallback)
   */
  assets = () => {
    const {
      notification
    } = this.props;
    const data = JSON.parse(notification.data);
    switch (notification.subject) {
      // TemplateSource
      case 'TEMPLATE_SOURCE_TESTED':
        return {
          icon: <FontAwesomeIcon icon={faBoxOpen} width={16} />,
          content: <span dangerouslySetInnerHTML={{
            __html: tr('front.notification.template_source_tested.content', {
              name: data.template_source.name
            }, 'front_office-user')
          }} />,
          link: null
        };
      case 'TEMPLATE_SOURCE_TEST_FAILED':
        return {
          icon: <FontAwesomeIcon icon={faBug} width={16} />,
          content: <React.Fragment>
                            <span dangerouslySetInnerHTML={{
              __html: tr('front.notification.template_source_test_failed.content.part_1', {
                name: data.template_source.name
              }, 'front_office-user')
            }} />
                            <br />
                            <span dangerouslySetInnerHTML={{
              __html: tr(`front.notification.template_source_test_failed.content.part_2.${data.error.code === constants.video.generationErrorLogItem.code.TOO_SLOW.name || data.error.code === constants.video.generationErrorLogItem.code.AE_EXPORT_TOO_SLOW.name ? 'slow' : 'default'}`, {}, 'front_office-user')
            }} />
                        </React.Fragment>,
          link: null
        };

      // video
      case 'VIDEO_GENERATION_DONE':
        return {
          icon: <FontAwesomeIcon icon={faFilm} width={16} />,
          content: <span>
                            <strong>{data.video.name} </strong>
                            {tr('front.notification.video_generation_done.content.part_1')}
                            &nbsp;
                            {probe.mobile === false && <React.Fragment>
                                    <br />
                                    <span className='link' style={{
                display: 'inline-block',
                fontSize: '80%'
              }} onClick={ev => {
                if (ev) ev.preventDefault();
                if (ev) ev.stopPropagation();
                apiRequest(`
                                                query {
                                                    video (id: "${data.video.id}") {
                                                        id
                                                        name
                                                        shortId
                                                        ratio
                                                        template {
                                                            config
                                                        }
                                                   }
                                               }
                                            `).then(data => {
                  if (!data.data.video) addFlash({
                    message: tr('front.notification.video_generation_done.error.video_not_found', {}, 'front_office-user'),
                    type: 'warning'
                  });else modal.set(Play, data.data.video.name, {
                    video: data.data.video
                  });
                }).catch(() => {
                  addFlash({
                    type: 'warning',
                    message: tr('front.notification.video_generation_done.error.default', {}, 'front_office-user')
                  });
                });
              }}>
                                        {tr('front.notification.video_generation_done.content.part_2')}
                                    </span>
                                </React.Fragment>}
                        </span>,
          link: `/${data.video.short_id}`
        };
      default:
        return {
          icon: null,
          content: null,
          link: null
        };
    }
  };
  render = () => {
    const {
      notification,
      workspace,
      appuser
    } = this.props;
    const clickCallback = this.assets().clickCallback;
    return <div>
                <Link href={this.assets().link || null}>
                    <a className={clsx('user:notification', `/${(_.get(notification, 'type') || '').toLowerCase()}`, _.get(notification, 'new') && '/new', clickCallback && 'cursorPointer')}>
                        <div className='-content'>
                            <span className='-icon'>{this.assets().icon}</span> {this.assets().content}
                        </div>
                        <div className={clsx('-meta', (workspace || _.get(appuser, 'organization.workspaces.edges.length') === 1 || !_.get(notification, 'workspace')) && '/onlyDate')}>
                            {(!workspace && _.get(appuser, 'organization.workspaces.edges.length') > 1 && !!_.get(notification, 'workspace.id')) === true && <span className='workspace:tag' style={{
              backgroundColor: stringToColour(_.get(notification, 'workspace.id'), 0xb4)
            }}>
                                    {_.get(notification, 'workspace.name') || 'Anonymous'}
                                </span>}
                            <div className='-date'>{moment(_.get(notification, 'createdAt')).local().locale('fr').format('lll')}</div>
                        </div>
                    </a>
                </Link>
            </div>;
  };
}
Notification.propTypes = {
  // required
  notification: PropTypes.object.isRequired
};
export default Notification;