export default function stringToColour(str, maxHex = 0xff) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) hash = str.charCodeAt(i) + ((hash << 5) - hash);
    let colour = '#';
    for (let i = 0; i < 3; i++) {
        const value = (hash >> (i * 8)) & maxHex;
        colour += `00${value.toString(16)}`.substr(-2);
    }
    return colour;
}
