import { alpha } from '@mui/material/styles';

const COLORS = ['yellow', 'red', 'white'];

export default function IconButton(theme) {
    const rootStyle = ownerState => {
        const defaultStyle = {
            backgroundColor: 'unset !important',
            '&:hover': {
                backgroundColor: alpha(theme.palette.primary.main, 0.08),
                color: theme.palette.primary.main
            }
        };

        const colorStyle = COLORS.map(color => ({
            ...(ownerState.color === color &&
                color === 'yellow' && {
                    color: '#FFAB00 !important',
                    '&:hover': {
                        backgroundColor: alpha('#FFAB00', 0.08),
                        color: '#FFAB00'
                    }
                }),
            ...(ownerState.color === color &&
                color === 'red' && {
                    color: '#f33c13 !important',
                    '&:hover': {
                        backgroundColor: alpha('#f33c13', 0.08),
                        color: '#f33c13'
                    }
                }),
            ...(ownerState.color === color &&
                color === 'white' && {
                    color: '#ffffff !important',
                    '&:hover': {
                        backgroundColor: alpha('#ffffff', 0.08),
                        color: '#ffffff'
                    }
                })
        }));

        return [defaultStyle, ...colorStyle];
    };

    return {
        MuiIconButton: {
            styleOverrides: {
                root: ({ ownerState }) => rootStyle(ownerState)
            }
        }
    };
}
