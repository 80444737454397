if (typeof window !== 'undefined') {
    window.getDefaultLanguage = translations => {
        let browserLang = '';
        if (typeof window !== 'undefined' && window && window.navigator && (window.navigator.languages || window.navigator.language)) {
            browserLang = ((window.navigator.languages && window.navigator.languages[0]) || window.navigator.language)
                .split('-')[0]
                .toLowerCase();
        }
        if (browserLang && translations[browserLang]) {
            return browserLang;
        }
        return 'fr';
    };
}
