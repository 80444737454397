import produce from 'immer';
import _, { isEqual } from 'lodash';
import React, { Component } from 'react';
import { v4 as uuidv4 } from 'uuid';
export default class Base extends Component {
  _id = uuidv4();
  shouldComponentUpdate(nextProps, nextState) {
    const debug = log => {
      const {
        renderDebugId
      } = this.props;
      if (renderDebugId) console.log(`${renderDebugId} is rendering because: ${log}`);
    };
    const deepEqualNeeded = el => !!(typeof el === 'object' || React.isValidElement(el));
    const deepEqualCheck = {
      props: {},
      state: {}
    };
    const {
      state
    } = this.props;
    if (nextProps && this.props) {
      for (const key in nextProps) {
        if (key !== nextProps[key]) {
          if (deepEqualNeeded(nextProps[key])) deepEqualCheck.props[key] = [key, nextProps[key]];else {
            debug(`this.props[${key}] !== nextProps[${key}]`);
            return true;
          }
        }
      }
    }
    if (nextState && this.state) {
      for (const key in nextState) {
        if (state !== nextState[key]) {
          if (deepEqualNeeded(nextState[key])) deepEqualCheck.state[key] = [state, nextState[key]];else {
            debug(`this.state[${key}] !== nextState[${key}]`);
            return true;
          }
        }
      }
    }
    for (const key in deepEqualCheck.props) {
      const check = deepEqualCheck.props[key];
      if (!isEqual(check[0], check[1])) {
        debug(`!deepEqual(nextProps[${key}], this.props[${key}])`);
        return true;
      }
    }
    for (const key in deepEqualCheck.state) {
      const check = deepEqualCheck.state[key];
      if (!isEqual(check[0], check[1])) {
        debug(`!deepEqual(nextState[${key}], this.state[${key}])`);
        return true;
      }
    }
    return false;
  }
  updateState = (updater, callback = () => null, original = false) => {
    if (callback === null) callback = () => null;
    if (!original && _.isFunction(updater)) {
      const func = updater;
      updater = produce(draft => func(draft));
    }
    this.setState(updater, callback);
  };
}