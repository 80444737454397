import * as _ from 'lodash';

if (typeof window !== 'undefined') {
    // avoid browser drop management
    window.addEventListener('dragover', ev => ev && ev.preventDefault(), false);
    window.addEventListener('drop', ev => ev && ev.preventDefault(), false);

    /**
     * From Base64 dataURL to MIME Type
     * Returns null when input is invalid
     */
    window.base64MimeType = encoded => {
        let result = null;

        if (typeof encoded !== 'string') return result;

        let mime = encoded.match(/data:([a-zA-Z0-9]+\/[a-zA-Z0-9-.+]+).*,.*/);

        if (mime && mime.length) result = mime[1];

        return result;
    };

    // Check if string is Base64
    window.isBase64 = input => base64MimeType(input) !== null;

    // Convert from Base64 to string (if not already)
    window.fromBase64 = input => Buffer.from(input, 'base64').toString();

    // Convert from string to Base64 (if not already)
    window.toBase64 = input => Buffer.from(input).toString('base64');

    /**
     * E.g. From '100000000' to '100 MB'
     *
     * @param size
     * @param round May be a boolean or an integer
     * @param unit May be NULL
     * @param unitInfix
     * @param unitFactor
     * @returns {string}
     */
    window.humanSize = (size, round = true, unit = 'B', unitInfix = 'i', unitFactor = 1024) => {
        let units = ['B', `K${unitInfix}B`, `M${unitInfix}B`, `G${unitInfix}B`, `T${unitInfix}B`];
        for (let power = units.length - 1; power >= 0; power--) {
            let tmpRes = (size * 1.0) / unitFactor ** power;
            if (tmpRes >= 1) {
                if (round) tmpRes = _.round(tmpRes, round === true ? 0 : round);
                return unit ? `${tmpRes} ${units[power]}` : tmpRes;
            }
        }
    };

    window.dl = (data, filename) => {
        let a = document.createElement('a');
        a.download = filename;
        a.href = data;
        a.click();
    };
}
