import moment from 'moment';
import React from 'react';
import __Base from '@components/bridge/bridge/modal/abs/Content';
import WaitingButton from '@components/bridge/bridge/WaitingButton';
import { axios } from '@services/http/client';
import i18n from 'i18n';

// Need appuser
class NewTermsAgreement extends __Base {
  static domain = 'user';
  static type = 'newTermsAgreement';
  static defaultPropsFactory = () => null;
  static defaultTitle = () => {
    return state => tr('front.modal.new_terms_agreement.title');
  };
  static loading = () => modal.MODAL_LOADING_NONE;
  handleSubmit = async () => {
    const body = {
      userId: this.props.appuser.id,
      patch: {
        lastTermsAgreementAt: moment().utc().format(DATETIME_FORMAT)
      }
    };
    const underWhitelabel = !window.areWeUnderDanimHost();
    const options = {
      method: 'POST',
      url: gen('write/user/change-account'),
      data: body,
      underWhitelabel
    };
    await axios(options, {
      finally: () => modal.close()
    });
  };
  render = () => {
    const {
      appuser
    } = this.props;
    return <div className='-content'>
                <iframe src={`https://danim.com/${i18n.defaultLang}/terms${appuser.isAdvancedDirector() && !appuser.isGrantedRole('EDITOR') ? '/creators' : ''}`} />
                <WaitingButton type='submit' text={tr('front.modal.new_terms_agreement.submit')} onClick={this.handleSubmit} />
            </div>;
  };
}
export default NewTermsAgreement;