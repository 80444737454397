import { replace } from 'lodash';

export default function uniqid(prefix = '', moreEntropy = false) {
    let retId;
    const _formatSeed = (seed, reqWidth) => {
        seed = parseInt(seed, 10).toString(16); // to hex str
        if (reqWidth < seed.length) {
            // so long we split
            return seed.slice(seed.length - reqWidth);
        }
        if (reqWidth > seed.length) {
            // so short we pad
            return Array(1 + (reqWidth - seed.length)).join('0') + seed;
        }
        return seed;
    };

    const $global = typeof window !== 'undefined' ? window : global;
    $global.$locutus = $global.$locutus || {};
    const $locutus = $global.$locutus;
    $locutus.php = $locutus.php || {};

    if (!$locutus.php.uniqidSeed) {
        // init seed with big random int
        $locutus.php.uniqidSeed = Math.floor(Math.random() * 0x75bcd15);
    }
    $locutus.php.uniqidSeed++;

    // start with prefix, add current milliseconds hex string
    retId = prefix;
    retId += _formatSeed(new Date().getTime() / 1000, 8);
    // add seed hex string
    retId += _formatSeed($locutus.php.uniqidSeed, 5);
    if (moreEntropy) {
        // for more entropy we add a float lower to 10
        retId += replace((Math.random() * 10).toFixed(8).toString(), '.', ''); // remove float dot
    }

    return retId;
}
